import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './contactanos.module.css';

/**
 * Contactanos component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Contactanos component.
 */
const Contactanos = () =>{
    
    document.title = "Vai | Contáctanos";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Contactanos}>
                
<section custom-agua-item="layer" className={styles.contactanosheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-552df41578ee414587350088b907fe16']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-b5719e90a24545a6867a38f35a7676f3']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-d1d5661ee45846448b5ee0960fbec677']}   >
<section custom-agua-item="layer" className={styles['layer-2122-80abb98464a24815be2e4003832182e1']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-a357d1c18c594058870de3a80ca222da']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-cc1310ecaec2490694cde2d3d6c8e642']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-27d08e2020d3438d8cdbe8f964f38e13']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-6420082806764b96ad0ff43cf56668db']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-5ca9df47b5a24eae8fd84257e7de0ef6']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-905983e4ecc94eb4abccf68c416a7b2f']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-f36d17c403544f7b9b6e62be7ecc4bce']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-d334a676dde14cd8b5f2cc09ab1ac858']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-3eb9c8b91c3b4d35a971fb79cf0e64c3']}   >
<section custom-agua-item="layer" className={styles['layer-2112-42ddbfe962b141ef9ec2eb653da26d32']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-12cd8338e1e44a0f9a3a6640047ac84a']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-00f81d14b61f450e9de5c8c7fd531b7a']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-d8a796a11af2415f90d39bb235f6c15f']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-330c82f88d3a44ba88497a739be6f154']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-5a3b838976234586bc887e85f66b9d51']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-85b32e4610844eedaedb6e36d68a0aee']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.contactanosbody}   ></section>
<section custom-agua-item="layer" className={styles.contactanosfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-f94b18a12d574f32923a34b73499cbe9']}   >
<section custom-agua-item="layer" className={styles['layer-13022-7311cb814b784938bd8130cc65006d32']}   >
<section custom-agua-item="layer" className={styles['layer-13522-873afcc92747457790531881ef65c94a']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-4bb73d9dab394805a52ea3158a48a9ba']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-1cbd0b47a9144bc29606be425c26fcc7']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-8cee1d4ee2844cc38bd6742344f438c7']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-95888a7b6daa4fcdbe43af53a9be2ef2']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-ed29895ac0574bb6bbe50a91e3261349']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-4f97f40a28584849af37c4943984a3df']}   >
<p custom-agua-item="text" className={styles['text-62022-e66ecb086dcc42ff8d3ade0055482fef']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-b5c1c960438542bfa1f0baafe5bb41a4']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-267cc3d1ce0542ba8a33b9945f9bed5e']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-e5a838898e70457e9e7ec3ca61744f99']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-bd47298e8ad945448c820499f272b690-orientation']} ${styles['layer-13222-bd47298e8ad945448c820499f272b690']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-0ba14ade93494a71bc86dfa235c0fa6f']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-a41775bc48da4d999cb9de635ef11a95']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-35d0677f764243ee8427c2692fe2a732']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-0158718accb14a41a022ccbd4acf466f']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-b25c6457c36144b7a5a98db9be7e5a13']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-cac23313fa0142d4855ecea20caa4bf8']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-59c86e62dcaa41e0bb2106057136015b']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-daa4f5df5e9e41f79416aa83713a48d5']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-b23ddca1093543e18e3d0550e0a19f15-orientation']} ${styles['layer-13422-b23ddca1093543e18e3d0550e0a19f15']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-2f143591812b4bbd908dba28dce1ba0e']}   >
<section custom-agua-item="layer" className={styles['layer-14222-8cb5ce65531a42b39012684fc1fa141f']}   >
<p custom-agua-item="text" className={styles['text-62522-bce4a2aef05c4c63afce0e5a7987572a']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-7c011157801244edb58f911bdffd5267']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-c214fbdeda8b4905bfa6fbfbfe2f8f72']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-c9261aebd8154a36af2a81b953062d2d']}   >
<p custom-agua-item="text" className={styles['text-62722-c80272ddeddd4d3c8433d1d66ccb57ed']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Contactanos;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './ayuda.module.css';

/**
 * Ayuda component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Ayuda component.
 */
const Ayuda = () =>{
    
    document.title = "Vai | Ayuda";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Ayuda}>
                
<section custom-agua-item="layer" className={styles.ayudaheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-000f22d6d84d4a3e9e8a3848932b8de5']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-997f2043a0a34898b1448ebd5aea3f5f']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-fa69c79a213948b6b3e665fba0930a1d']}   >
<section custom-agua-item="layer" className={styles['layer-2122-be5901db5e7d4385b52781409289b060']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-921c4fb3ad5142b4bad2bccfbce58e89']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-472631c27f684f569de2ffb2ca87c0a7']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-e73f0b00830145019f014b457fa019f9']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-97200dfb21a74df7969817fc030d4291']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-7d58d642635640c38c0ab9af8ff32045']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-da1fce318ab94093a40516b05230e1ff']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-02844019a5ed4ec0bb0b06d9080821e4']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-6275d04bb2564f449e128bf5f1ddc170']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-991cc784e24d4885b524c6b61e6b339e']}   >
<section custom-agua-item="layer" className={styles['layer-2112-898d9c84f9824df39789735bdac1d0b7']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-e2849adfcf3c4e8d97bb678781c296a8']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-7d3d2180d19c4d7796db2d3f55ba9e43']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-ade0c6d83d754b8bb85bd5e085aadd19']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-358886fa3d1d40a7b48ae63774679039']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-850454143b534cd1ad4df19dea70c19f']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-6ba086f6e4eb46a1be50d19d3541c6dc']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudabody}   >
<section custom-agua-item="layer" className={styles.ayudasection1}   >
<section custom-agua-item="layer" className={styles['layer-1532-3c589fe33be24cfcae90627dbbf37e92']}   >
<p custom-agua-item="text" className={styles['text-8912-3db48daa7c08443993d9f0000e06f3c7']}   >Aquí las respuestas a todas tus dudas...</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection2}   >
<section custom-agua-item="layer" className={styles['layer-6122-b62e1cb40924408aa684ed1196ec39ab']}   ></section>
<section custom-agua-item="layer" className={styles['layer-6132-0a9d8b3922b34f39af787beb96e955fa']}   ></section>
<section custom-agua-item="layer" className={styles['layer-1497-e8bb90bd51434a93b32d652bf9cc55c1']}   >
<p custom-agua-item="text" className={styles['text-2049-04fe33a526b64415a74ccc4c5f3dc978']}   >¿Puedo pedir un viaje para mi y otro compañero?</p>
<p custom-agua-item="text" className={styles['text-2050-2e06affa3ae446b3a35925c668e56789']}   >En Vai puedes programar un viaje para ti y tus acompañantes, te damos la opción de reservar los cupos que necesites a la hora de programar tu viaje.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection3}   >
<section custom-agua-item="layer" className={styles['layer-6142-eaec44b7bd4447cdbe0aabe157d0b60c']}   ></section>
<section custom-agua-item="layer" className={styles['layer-6152-ee9aa3944e4547ee9b2cd4fb97417029']}   ></section>
<section custom-agua-item="layer" className={styles['layer-14972-23cae618cb0e4ba49095cce4aab22f1f']}   >
<p custom-agua-item="text" className={styles['text-20492-dd78ed90bd3446a29fc3701029a61e57']}   >¿Cómo se calculan los precios del viaje?</p>
<p custom-agua-item="text" className={styles['text-20502-28de0ff6bede41ec929e9de89b5a473f']}   >Nuestros precios son estándar y asequibles, éstos se calculan según la distancia y oscilan entre los $4.000 y $6.000 COP.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection14}   >
<section custom-agua-item="layer" className={styles['layer-14973-875fec7dc4cc4409b42bed224565534e']}   >
<p custom-agua-item="text" className={styles['text-20493-d06be1365f564b38b00d651695bad36c']}   >¿Qué métodos de pago tienen?</p>
<p custom-agua-item="text" className={styles['text-20503-0863408765b342f08e40cf147b9865e3']}   >Pensando en facilitar la experiencia de nuestros usuarios, contamos con diferentes métodos de pago: efectivo, Nequi o VaiCash.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection5}   >
<section custom-agua-item="layer" className={styles['layer-6162-8a8b973f162a40dfbb80f8e7985db8aa']}   ></section>
<section custom-agua-item="layer" className={styles['layer-6172-9091051d021543ee93b8155efe604107']}   ></section>
<section custom-agua-item="layer" className={styles['layer-14974-d8da180f24fb4d48ac4d147e637e9afd']}   >
<p custom-agua-item="text" className={styles['text-20494-1aa4fb07a1444216beb0fe5720efd9af']}   >¿Cómo garantiza Vai la seguridad de los usuarios?</p>
<p custom-agua-item="text" className={styles['text-20504-f3ee01e414104dc9a36d82c72f988e51']}   >La seguridad de nuestros usuarios es uno de los ejes más importantes de nuestra propuesta. Los usuarios solo se pueden registrar en la aplicación por medio de su correo institucional, previamente verificado por Vai, garantizando así viajes seguros y en comunidad.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection6}   >
<section custom-agua-item="layer" className={styles['layer-6182-7a32902b264e43efb2281057198b1237']}   ></section>
<section custom-agua-item="layer" className={styles['layer-6192-7470f7103e204f18b7e422bef8d3b39c']}   ></section>
<section custom-agua-item="layer" className={styles['layer-14975-0716475b0f8e4ef8802013cb2ba1c445']}   >
<p custom-agua-item="text" className={styles['text-20495-cfc8cc6908584d7b91a5af4ee5c1a1bd']}   >¿Cómo se calculan las rutas y los destinos?</p>
<p custom-agua-item="text" className={styles['text-20505-ccd4407c46364d8aa53684d7927a4610']}   >Contamos con un algoritmo de optimización que conecta en segundos a nuestros usuarios de manera eficiente teniendo en cuenta sus horarios, destinos y rutas. Éste está diseñado específicamente para recibir las peticiones de conductores y de pasajeros, configurando una malla de transporte fácil, rápida y confiable.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudasection7}   >
<section custom-agua-item="layer" className={styles['layer-1400-dfee13464d8141ac983efc14f614f512']}   >
<section custom-agua-item="layer" className={styles['layer-1401-e21e4eafc0084a4ab7db97ecac33e53f']}   >
<p custom-agua-item="text" className={styles['text-1094-5b7cd0137a9143de82567b9facfeb711']}   >¿Tienes otra duda?</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1402-081df030d9ba4983bffb0b7a70e8c262']}   >
<section custom-agua-item="layer" className={styles.layer15022}   >
<section custom-agua-item="layer" className={styles['layer-5592-9fa9aca3d7df424a800b868efe47750f']}   onClick={() => openInNewTab("https://wa.me/573128339854")}></section>
<p custom-agua-item="text" className={styles['text-63122-2399cc85703f48bbbd35ea397b170cdc']}   >¡Escribenos!</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.ayudafooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-f71bf399a6394384abe5d9a7845c29f9']}   >
<section custom-agua-item="layer" className={styles['layer-13022-3f96ae6da7f749ab91a24c42caceec76']}   >
<section custom-agua-item="layer" className={styles['layer-13522-a7fef912536e4288a2003ec5d4f767a9']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-104d4a6ab4c04e139489890ef0b0e982']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-54bb0c0584734d0191ddee4b3ee1086c']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-23533f718a2d480aaa476bb5b6ccd81d']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-d8ffb43034aa475ab030d752a586948b']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-43887b410f364882a27d810ce3cbf5d4']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-58e5193eca374c8daac04c4c338d9c0d']}   >
<p custom-agua-item="text" className={styles['text-62022-8248815884eb4d1a825fc9fbea2b1d8b']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-2bbb08cc1bab49e0a7b054e62ce930e6']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-a24436d60a764446927fde93ec94aa50']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-8b05d84d79f648c7ba06f235f93be833']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-a85b069253e3407dbc64e629e9099df8-orientation']} ${styles['layer-13222-a85b069253e3407dbc64e629e9099df8']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-3739e1521d2c4059902c7e671a779ead']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-aa49508aed9148619ddf8410d23da2ad']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-94a62932efc840bdbe600683f019478a']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-44d684fbb9e941a6987c1d261e577487']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-7f88feb6ac0244cfb2118ac4b231b222']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-ba1db395a84b4d84a8d00d4103447e3d']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-7297b95d8ba54d49b9295ae70f9cbfd2']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-e798ff26ee9a48baae7ff92d12023a77']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-c3f3d48dfc434b2ebc3802ee98e50c92-orientation']} ${styles['layer-13422-c3f3d48dfc434b2ebc3802ee98e50c92']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-3aabcef0e538441a81610d86d8f3a0ea']}   >
<section custom-agua-item="layer" className={styles['layer-14222-885c431b5ba44ec89baec999b754d4cc']}   >
<p custom-agua-item="text" className={styles['text-62522-d42ecad467df42f19a3efa821cb038c6']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-5fd4990ac8e341c4b69d678f823434f3']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-4828f15e925a4ada981dd9e7f786713a']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-4e954fe7e23d43cd964502b7f1713dc8']}   >
<p custom-agua-item="text" className={styles['text-62722-44cbf17c44a64cf3b4ad8f5f4b9a9eb4']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Ayuda;
            
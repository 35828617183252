import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './blog.module.css';

/**
 * Blog component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Blog component.
 */
const Blog = () =>{
    
    document.title = "Vai | Blog";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Blog}>
                
<section custom-agua-item="layer" className={styles.blogheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-b2554db1a0cb4c3eb793afc009caa314']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-3d86b32bc1f645758c91fd3a3cef86d0']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-e28337d8fb87449abac4a22a9b8ebfa2']}   >
<section custom-agua-item="layer" className={styles['layer-2122-9b7f70a56f5c4913aec7ad106b7b3e49']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-233bbcf992014d0d86220a91ec4de0e3']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-6151af94b91c48c38223066885946b17']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-137d230a084e49a4b66cd8461e38240f']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-b1a11f1c57dc4edb9a6e795c6033873c']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-9d263438238b4dd9a3d776aff01233e3']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-507defa698e34e7fb394890ebbab0df7']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-3b31b2bf83814d72a762498a08081182']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-cf2e2b059a79417092e3cbaa3734b50e']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-e43ac119494b44abb86e915b0bd0e922']}   >
<section custom-agua-item="layer" className={styles['layer-2112-16de536bdb17432788580c4d82164d90']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-6c0dd582d5d548d58fa6e96ddcb8fae6']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-78092db9925d453ba3239587575adbd2']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-fbe135f6fcbb456c9021e72e61118e90']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-6e6d694abbc84dc5bd4a392d1aa38590']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-5285811c086a4d07b36e28aa82d6cd63']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-00ab5b12527e473da25f7c1148f77e4d']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1691-a3f947daec504037b9f225f90e0c4fb3']}   >
<section custom-agua-item="layer" className={styles['layer-2155-ecd00ac905b7429c9c1515f663d09ff3']}   >
<section custom-agua-item="layer" className={styles['layer-2157-f9653ad6a5364c1eb8ce8d31fcc1d06e']}   >
<p custom-agua-item="text" className={styles['text-2369-07fef75e917d4a40be5013d5a57f4c41']}   >¿Quieres saber más de nosotros?</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2156-1cfe6c7f008343c5a845ef4aa7013a38']}   >
<section custom-agua-item="layer" className={styles['layer-4310222-7c7a2805ab804bc29060dfd4f4568ccc']}   >
<Link to="/Blog1"><section custom-agua-item="layer" className={styles['layer-2981-6bff385abfd8415089c102ac8080b32a']}   ></section></Link>
<section custom-agua-item="layer" className={styles['layer-4311222-c1ea9f63ad6549d4a63903b184e27f36']}   ></section>
<p custom-agua-item="text" className={styles['text-3815222-8f33e86f7b5f48c39f75e6055468dc32']}   >¿Qué es el carpooling y cómo funciona en Bogotá?</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.blogfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-9e7c883cf5834148a1b4c8a6ebe897d0']}   >
<section custom-agua-item="layer" className={styles['layer-13022-f1e2ec61ff064e49912be977f08506ba']}   >
<section custom-agua-item="layer" className={styles['layer-13522-9e7d3fd68ed04974936adf03b018adc9']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-f473eb699e844e57a2853d5aace4af16']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-45cf46949d8f41b882c6f2e2b2aa815a']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-c34024361a2e4d51a74ca21288fd0b92']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-883109b4fb47418b9f1ea2ea58559851']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-73ce31743b414eabbe02ab8af1f5c25e']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-b57babfa1fd54588a31221ec6352965b']}   >
<p custom-agua-item="text" className={styles['text-62022-60252e00d4644111b04508fba4c78d28']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-9dcfcc6f98ea4a88989ed387cdf87b6c']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-403be4ffc0c04361b71d9c60cf9f0a35']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-953907c756d94b78b4d3063997f631ff']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-0368242f349b417a9be7408ed8da4e0c-orientation']} ${styles['layer-13222-0368242f349b417a9be7408ed8da4e0c']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-a1bb29c203494a978a432b335655ae5e']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-70418b0d33dc4745a429022ffcc112f1']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-bea6c390600a4f09bb626b6b65c8fcd3']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-a81b99670b604736bd8fffa9053a9049']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-d4adb751c17d4f50abb761e99f89d58b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-1b225ec101894e3eb95ba2c4cdd1c752']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-345fc41607964b239a9ef13d13dfdb0a']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-ba59252c152f4048a36366a716ca624e']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-0b811cac05194c4fb2b687ad2f7d42cd-orientation']} ${styles['layer-13422-0b811cac05194c4fb2b687ad2f7d42cd']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-44adf1b165ec4ba8ba57e89b494264d8']}   >
<section custom-agua-item="layer" className={styles['layer-14222-752fff2393bd4858a461041fbb0f1e1c']}   >
<p custom-agua-item="text" className={styles['text-62522-2cf9f6617572432da3f582491a8afbe1']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-17eea395eb5f4791b88e1b22d57bd6fd']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-ee9d2dc9f6d24f5da94b474e58a45895']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-45bd748ce77d445b82fdce91678d1f28']}   >
<p custom-agua-item="text" className={styles['text-62722-a0b336dc5ab94447a2269a98eaaa824d']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Blog;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_92 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_102 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_112 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './terminosycondiciones.module.css';

/**
 * Terminosycondiciones component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Terminosycondiciones component.
 */
const Terminosycondiciones = () =>{
    
    document.title = " Vai | Términos y Condiciones";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Terminosycondiciones}>
                
<section custom-agua-item="layer" className={styles.homeheader2}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-a0522c81454342f6aaad5cea04fd9a87']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-01174ffa0b9d4fd2bf598afda2b08324']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-9ddd63ba5b6e40a0b6a4f1a5f8e5d9b9']}   >
<section custom-agua-item="layer" className={styles['layer-2122-9189715022f940e394fbf7189d741ed2']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-fce1102116b14f5ab869d0f93c4fc618']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-ed56d5f324b84776b03132f0bcf7b5c9']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-f0c03b8129d646eda0138b9ae9219337']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-a1e41953646949ae8d91e86d30f09f1a']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-f5ca4944b2b74ec3ab3432ec4f726b96']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-137b3e3ff9ec42d09ebd752748affeed']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-1273f20f28f445c380cba1009107538e']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-688e31c98a9e4dd7b4c1b4471ef3928f']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-2be3c82a5a024c5e965f6a3ae7523c45']}   >
<section custom-agua-item="layer" className={styles['layer-2112-8e7c38a1c3aa492fa9a21f0b6a8f9894']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-46ac441ce4fb4198a49f28586154132a']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-7741762f8f0d45a2990d10b40d5f5ed3']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-930892f848ff40eab61478f4cb6a857d']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-b3128557a960428ba241429fbd6b7299']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-ce79e75193724afa8037ede2a4157feb']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-009caa89379f429a94c3c85c0fb93f38']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.terminosycondicionesbody}   >
<p custom-agua-item="text" className={styles['text-102-ba919e402e5f446690db518160cfd8cd']}   >TÉRMINOS Y CONDICIONES – VAI COLOMBIA S.A.S.</p>
<section custom-agua-item="layer" className={styles.leerycomprender}   >
<p custom-agua-item="text" className={styles['text-103-7f0f18bc224e4171a5c5393aac407e3f']}   >Por favor, dedique parte de su tiempo para leer y comprender estos TÉRMINOS Y CONDICIONES atentamente antes de acceder o usar los servicios de VAI COLOMBIA S.A.S.</p>
<p custom-agua-item="text" className={styles['text-104-6a0f812aba5943cfa99c8f8f2aacba27']}   >El presente documento describe los TÉRMINOS Y CONDICIONES (en adelante, los “Términos y Condiciones”) que regulan el uso que tú (en adelante, “Tú” o el “Usuario”) hagas desde Colombia de las aplicaciones, páginas web, portales de contacto, productos y servicios (en adelante los “Servicios”) puestos a disposición por VAI Colombia S.A.S., sociedad por acciones simplificada, identificada con NIT 901.586.077 – 1 (“VAI”).VAI tiene por objeto social conectar a personas que pertenezcan a diferentes comunidades estudiantiles, laborales, empresariales o de cualquier naturaleza, por medio de una aplicación, para que estas puedan celebrar contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling (en adelante el “Contrato”), con otras personas que vayan al mismo destino o se movilicen por la misma ruta.</p>
<p custom-agua-item="text" className={styles['text-105-876a2236187b45a3aaad0403b9ef222a']}   >Los Términos y Condiciones también regulan los contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, celebrados entre los Usuarios, sin perjuicio de las condiciones particulares indicadas en la Aplicación o los acuerdos particulares entre el Usuario Conductor y los Usuarios Pasajeros.</p>
<p custom-agua-item="text" className={styles['text-106-cd37de620e114f438535375a367a5ed3']}   >En virtud de lo anterior, cualquier persona que haga uso de los Servicios VAI deberá sujetarse a los presentes Términos y Condiciones y a la Política de Privacidad, la cual hace parte integral de estas Condiciones. Por lo tanto, siempre que se haga en este texto referencia a los Términos y Condiciones, se estará haciendo también referencia a la Política de Privacidad de VAI.</p>
<p custom-agua-item="text" className={styles['text-107-9cc0451d57144a7bb11123d07be8757f']}   >Es necesario que Tú LEAS y ENTIENDAS todas las Condiciones establecidas en este documento, previo a tu registro como Usuario o a la contratación de algún Servicio ofrecido a través de la Aplicación, habida consideración que, mediante tu acceso y uso de los Servicios ACEPTAS y por ende, acuerdas vincularte a estos Términos y Condiciones, los cuales crean una relación contractual con VAI y con otros Usuarios. En consecuencia, podrás acceder a nuestros Servicios mediante la aceptación de los Términos y Condiciones, obligándote a cumplir con todo lo aquí establecido. En caso de no estar de acuerdo con los Términos y Condiciones deberás abstenerte de utilizar los Servicios.</p>
</section>
<section custom-agua-item="layer" className={styles.aclaracionpreliminar}   >
<p custom-agua-item="text" className={styles['text-108-589b6d75ef58491fbb7fc5ca80608f02']}   >Aclaración Preliminar</p>
<p custom-agua-item="text" className={styles['text-109-1ed7539280a74572aaa1e23d01fc513b']}   >VAI presta a los Usuarios de sus aplicaciones móviles, páginas web o plataformas de contacto (la “Aplicación”) el servicio de interconexión bajo demanda por medio de los cuales pone en contacto a los miembros de una comunidad estudiantil o profesional que quieran celebrar contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, de acuerdo con lo establecido en los Términos y Condiciones y en las condiciones particulares de tiempo, modo y lugar señaladas en la Aplicación en cada oportunidad.</p>
<p custom-agua-item="text" className={styles['text-110-80b7d3953ee144babfc93b995563d49f']}   >Los miembros de la comunidad que celebren el contrato civil de arrendamiento de transporte son independientes con respecto de VAI, quien únicamente sirve como plataforma de contacto entre oferta y demanda. Además, al aceptar los Términos y Condiciones garantizas que no te dedicas profesionalmente a realizar actividades de transporte, sino que únicamente buscas obtener los siguientes beneficios (los “Beneficios”):</p>
<p custom-agua-item="text" className={styles['text-111-59e0fbacd1a743b49b2ef54391c9f2b8']}   >Generar mayores lazos de comunidad: conocer nuevos amigos y afianzar la relación con otros miembros de la comunidad;</p>
<p custom-agua-item="text" className={styles['text-112-f5e6de0651c142c188c56d85b067ee40']}   >Generar ingresos adicionales: generar ingresos adicionales y ocasionales mediante los servicios de carro compartido car pooling</p>
<p custom-agua-item="text" className={styles['text-113-5f15a6b204ce43b490a6e840e4bc4f93']}   >Ahorrar dinero: ahorrar dinero en costos de desplazamiento;</p>
<p custom-agua-item="text" className={styles['text-114-63a6d5695a6e4a608a8c8c13ac96ecfc']}   >Seguridad: la seguridad de transportarse en compañía segura de miembros de tu comunidad;</p>
<p custom-agua-item="text" className={styles['text-115-a6470d3b38ff4193ad04774c6d34e698']}   >Proteger el medio ambiente: proteger el medio ambiente, compartiendo vehículos para tus movilizaciones relacionadas con la comunidad, reduciendo tus emisiones y tu huella de carbono;</p>
<p custom-agua-item="text" className={styles['text-116-93eb76c2e9684ce5979117c2f82448f6']}   >Ahorrar tiempo en tus desplazamientos, mediante car pooling con otros miembros de tu comunidad que deban transportarse al mismo lugar en horarios similares.</p>
<p custom-agua-item="text" className={styles['text-117-387f4a6467ca436aa621ad16df519ed6']}   >VAI presta los siguientes Servicios:</p>
<p custom-agua-item="text" className={styles['text-118-8cae3003019c48f2930e8bb3a50a3a62']}   >Poner en contacto a los Usuarios que quieran celebrar contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling;</p>
<p custom-agua-item="text" className={styles['text-119-c3dd57b9a7984ddb8998e320c36eef4f']}   >Poner a disposición de los Usuarios las solicitudes de otros Usuarios para celebrar los contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling;</p>
<p custom-agua-item="text" className={styles['text-120-8723270cbc3e423b9a3dcfd86752baed']}   >Poner a disposición de los Usuarios la información de los otros Usuarios interesados, de los vehículos, rutas, cantidad de asientos disponibles y valor estimado del contrato, según la información suministrada por los Usuarios y la extensión o duración de la ruta;</p>
<p custom-agua-item="text" className={styles['text-121-82489dc48cf84bf4a27acaaef178952a']}   >Poner a disposición de los Usuarios mecanismos de pago del valor de los contratos;</p>
<p custom-agua-item="text" className={styles['text-122-198fb4d3abec4bb09ace14fbdab6e832']}   >Poner a disposición de los Usuarios canales de comunicación con los Usuarios con los que celebren contratos;</p>
<p custom-agua-item="text" className={styles['text-123-70730f51704545a7a816834458340dbd']}   >Poner a disposición de los Usuarios encuestas de satisfacción y buzones de sugerencias para mejorar los Servicios;</p>
<p custom-agua-item="text" className={styles['text-124-421f5638bc954a18a8b4fc19f7ac0fbb']}   >Los demás que se detallan en los presentes Términos y Condiciones.</p>
<p custom-agua-item="text" className={styles['text-125-37a2f266beb94677a3b7e61a130898c6']}   >Al aceptar los presentes Términos y Condiciones aceptas que VAI no presta servicios de transporte, ni de arrendamiento de transporte, ni de arrendamiento de vehículos, ni de logística, ni funciona como una empresa que preste dichos servicios, además aceptas que las partes del contrato civil de arrendamiento de transporte son independientes de VAI y no son sus empleados ni contrastas. También entiendes y aceptas que los Usuarios de la aplicación no se dedican profesional ni habitualmente a realizar actividades de transporte de personas, sino que buscan realizar actividades de car pooling de manera ocasional para lograr los Beneficios antes mencionados.

</p>
</section>
<section custom-agua-item="layer" className={styles.contratodearrendamiento}   >
<p custom-agua-item="text" className={styles['text-126-2a178cc3ed9348fe8b17dd71b23ff26d']}   >Contrato civil de arrendamiento de transporte</p>
<p custom-agua-item="text" className={styles['text-127-df7c628ccd1d4a1db37e1f837d1284d0']}   >Entre el Usuario conductor y el Usuario pasajero se celebra un contrato civil de arrendamiento de transporte regulado por los artículos 2070 y siguientes del Código Civil, conforme a lo que se establece en la Sección Tercera de estos Términos y Condiciones. Las Partes del Contrato, son la siguientes:

</p>
<p custom-agua-item="text" className={styles['text-128-488eeb4baf5044008e8d95af9f95d9a0']}   >El Usuario conductor es un tercero independiente, miembro de una comunidad educativa o profesional que utiliza un vehículo privado para satisfacer sus necesidades personales de transporte y busca obtener Beneficios mediante el servicio de carro compartido o car pooling (el “Usuario Conductor”); y
</p>
<p custom-agua-item="text" className={styles['text-130-b8ccf2dcaa6f4ea89e9461550686c1d4']}   >El Usuario pasajero es un tercero independiente, miembro de una comunidad educativa o profesional que utiliza los Servicios de VAI para poder satisfacer sus necesidades personales de transporte en su comunidad, mediante el servicio de carro compartido o car pooling, y busca obtener los Beneficios (el “Usuario Pasajero”).</p>
<p custom-agua-item="text" className={styles['text-131-afc28b30005944c1bd4509ff59232345']}   >Como Usuario de la plataforma Tú podrás tener tanto la calidad de Usuario Conductor como de Usuario Pasajero, dependiendo de si estás poniendo a disposición tu vehículo para la realización de actividades de carro compartido o si estás solicitando el servicio de arrendamiento de transporte bajo la modalidad de carro compartido. Por lo tanto, cada vez que utilices los Servicios deberás verificar la calidad en la que actúas y entiendes que te aplicarán los Términos y Condiciones que correspondan, de acuerdo con la condición en la que actúes.</p>
</section>
<section custom-agua-item="layer" className={styles.capacidadlegal}   >
<p custom-agua-item="text" className={styles['text-132-34cfb74d180e4534b4764e7fa5082ce2']}   >Capacidad Legal</p>
<p custom-agua-item="text" className={styles['text-133-4ab2e1f0f7ae4019b5d225bff56ea6af']}   >Los Servicios puestos a disposición de VAI están únicamente disponibles para personas que tengan capacidad legal para contratar. Los actos que los menores de edad realicen mediante la aplicación, plataforma tecnológica o los sitios que disponga VAI para prestar sus Servicios serán responsabilidad de sus padres, representantes o encargados y, por lo tanto, se considerarán realizados por ellos y la representación legal con la que cuenten.</p>
</section>
<section custom-agua-item="layer" className={styles.servicios}   >
<p custom-agua-item="text" className={styles['text-134-cc01f1780474424e872e99b02a62678a']}   >De los Servicios</p>
<section custom-agua-item="layer" className={styles.alcancedelosservicios}   >
<p custom-agua-item="text" className={styles['text-659-e9f311eb51ff4d8cb3777f285681d15a']}   >Alcance de los servicios de VAI</p>
<p custom-agua-item="text" className={styles['text-660-190e326078374b5996dbd0c37a1b08ef']}   >VAI ofrece el uso de una plataforma tecnológica (la “Aplicación”) de interconexión bajo demanda para la solicitud de arrendamiento de vehículos con conductor, en la cual Usuario Conductor y el Usuario Pasajero puedan celebrar un contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling. Los Servicios se ponen a Tu disposición únicamente para Tu uso personal, no comercial.</p>
<p custom-agua-item="text" className={styles['text-661-5b392106a1924ede9568039627e8810d']}   >Para evitar confusiones, VAI no provee servicios de transporte ni servicios diferentes a los de intermediación entre los Usuarios Conductores y los Usuarios Pasajeros que buscan obtener los Beneficios mediante el uso de carro compartido o car pooling. Los servicios de arrendamiento de transporte se prestan por los Usuarios Conductores mediante la celebración de un contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling directamente con los Usuarios Pasajeros. Los Usuarios Conductores prestan los servicios de arrendamiento de transporte de forma independiente, ocasional y no profesional. VAI no es responsable de ningún modo del cumplimiento del Contrato entre el Usuario Conductor y el Usuario Pasajero. Cualquier disputa en materia de los Contratos civiles de arrendamiento de transporte serán resueltas directamente entre los Usuarios.</p>
<p custom-agua-item="text" className={styles['text-662-d008855e1bde44b5acdd31ded7a3d406']}   >Los Usuarios serán responsables del cumplimiento de todas las leyes aplicables, incluidas las normas de tránsito, sin que VAI sea responsable del incumplimiento de dichas leyes y normativas.</p>
<p custom-agua-item="text" className={styles['text-663-b280ca87a2854bc3b2134861ab12997c']}   >Del mismo modo, Tú reconoces que VAI:</p>
<p custom-agua-item="text" className={styles['text-664-dab48c0a269a475d8b7c4b9f83914bad']}   >1. No presta servicios de arrendamiento de transporte o logística;</p>
<p custom-agua-item="text" className={styles['text-665-9aa803f01be84ec690f718e833209cdb']}   >2. No funciona como una empresa de arrendamiento de transporte o logística;</p>
<p custom-agua-item="text" className={styles['text-666-685565a31aa04c02ab354f0aa66b06fd']}   >3. Los Usuarios Conductores son terceros independientes, que no están empleados por VAI ni por ninguna de sus afiliadas.</p>
<p custom-agua-item="text" className={styles['text-667-9904a428143744d6822dc02ca695d2b2']}   >4. No será responsable por el incumplimiento de los contratos civiles de arrendamiento de transporte ni por los daños o perjuicios que ocasionen los Usuarios o terceros.</p>
</section>
<section custom-agua-item="layer" className={styles.licencia}   >
<p custom-agua-item="text" className={styles['text-668-04c78375bd5642e9865d52e788966cff']}   >Licencia</p>
<p custom-agua-item="text" className={styles['text-669-e969755e34d64791a1021907e08558f5']}   >Sujeto al cumplimiento de los Términos y Condiciones, VAI te otorga una licencia para que uses la Aplicación a través de un dispositivo personal y puedas acceder a los Servicios así como a cualquier información o material relacionado con estos.  Esta licencia es limitada, no exclusiva, no sublicenciable, revocable, de uso personal y no transferible. VAI se reserva el manejo de todos su derechos sobre la aplicación, información, material o Servicios.</p>
<p custom-agua-item="text" className={styles['text-670-ad91bdb92c7346db8323bc235bfbbafe']}   >VAI podrá poner fin a estos Términos y Condiciones o cualquiera de los Servicios en cualquier momento o dejará de ofrecer el acceso a los Servicios, en cualquier momento y por cualquier motivo. En caso de que tu derecho de uso de la Aplicación o los Servicios puestos a tu disposición por parte de VAI sean cancelados, la correspondiente licencia no exclusiva también será cancelada.</p>
</section>
<section custom-agua-item="layer" className={styles.restricciones}   >
<p custom-agua-item="text" className={styles.text671}   >Restricciones
</p>
<p custom-agua-item="text" className={styles['text-672-d211ba58b17d47f29fef8ee7d11d4e46']}   >Tú no podrás:</p>
<p custom-agua-item="text" className={styles['text-673-1e597664eede407ba0672a7fa13a8f93']}   >1. Retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-674-04e5ac8357394bec94395a9a0e1833d7']}   >2. Reproducir, modificar o comunicar obras derivadas de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-675-758093f8930048f19c670ade40f41934']}   >3. Distribuir, reproducir, licenciar, arrendar, revender, transferir, comunicar, transmitir o explotar los Servicios;</p>
<p custom-agua-item="text" className={styles['text-676-8fce0751aa1142599510d92af2d7ff19']}   >4. Descompilar, reproducir, realizar ingeniería inversa o desmontar los Servicios;</p>
<p custom-agua-item="text" className={styles['text-677-5c1d5992d97e4e668b55a9eaf6ef7fa5']}   >5. Enlazar, reflejar o enmarcar cualquier parte de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-678-3363731b800e4085b297ccc024d042f0']}   >6. Causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de los Servicios o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-679-4563f19e4ef8405e87f4dc8e43aaaaa5']}   >7. Intentar obtener un acceso no autorizado o dañar los Servicios o sus sistemas o redes relacionadas;</p>
<p custom-agua-item="text" className={styles['text-680-b520e3a6d6e54e9aae17f25be50a2c6e']}   >8. Transmitir a terceros o de cualquier manera publicar información que es falsa, dañina, hostil, abusiva, irritante, problemática, amenazante, tortuosa, difamatoria, vulgar, obscena, pornográfica, infundada, odiosa o perjudicial, o respecto de la cual no se cuenta con las debidas autorizaciones legales o contractuales;</p>
<p custom-agua-item="text" className={styles['text-681-c93a93a4c6044bd08dc79ed08b000c18']}   >9. Ocasionar daño a menores de edad o promover o efectuar daños físicos o materiales a cualquier persona o grupo de personas naturales y jurídicas, o a animales que hagan uso de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-682-016764a5a41440ec9a54fca4f76fb8f5']}   >10. Utilizar la identidad o la información personal de personas (naturales o jurídicas) mencionadas en la aplicación, para cualquier propósito o finalidad;</p>
<p custom-agua-item="text" className={styles['text-683-6fead4ebb905413983892dabcf99ba88']}   >11. Transmitir o emitir material que contenga virus informáticos o cualquier otro código, programa de computador o aplicación destinada a interrumpir, destruir, restringir o perjudicar la funcionalidad de computadores, programas de computador, sistemas de información, redes de telecomunicaciones o infraestructura y servicios de terceros;</p>
<p custom-agua-item="text" className={styles['text-684-ce303673bb6f44669c51ced2a7452902']}   >12. De manera intencionada o sin intención violar o incumplir cualquier norma aplicable, incluyendo, pero sin limitarse, a las normas de privacidad y protección de datos;</p>
<p custom-agua-item="text" className={styles['text-685-6adfbad1b39149e2a7754a764dda70c2']}   >13. Recolectar, guardar y administrar datos personales sobre personas naturales y/o jurídicas a las que tenga acceso en uso de los Servicios ofrecidos por VAI sin la correspondiente autorización y en incumplimiento de las normas aplicables;</p>
<p custom-agua-item="text" className={styles['text-686-cf6efba878554cb69db73317b12ff65a']}   >14. Ejecutar, planear, armar, estructurar o realizar prácticas o actividades de carácter criminal a través de la Aplicación o en uso de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-687-4be83ecbf2e24675a34a328bd2db7d0c']}   >15. Infringir los derechos de propiedad intelectual de VAI o de terceros;</p>
<p custom-agua-item="text" className={styles['text-688-3f6a4fd57ff743e7a3522b4dfdd2195e']}   >16. Realizar cualquier acto o conducta de suplantación de identidad;</p>
<p custom-agua-item="text" className={styles['text-689-126804fd414f4b36b3fa2f5622eb9da9']}   >17. Realizar cualquier acto, incluyendo el uso de hardware y software, que tenga por objeto o como efecto el daño, la interferencia, la afectación de la integridad, o la interceptación de los sistemas que soportan la plataforma tecnológica o Aplicación, su funcionamiento o los contenidos.</p>
</section>
<section custom-agua-item="layer" className={styles.peticionycancelacion}   >
<p custom-agua-item="text" className={styles['text-691-e2eb4a6059c840faafc80c7d2e8d9e3a']}   >Petición y cancelación de los Servicios.</p>
<p custom-agua-item="text" className={styles['text-690-23f5115c68b24ec7b0f2ceb96c7c4a1b']}   >Si Tú como Usuario Pasajero solicitas un Servicio y el Usuario Conductor acepta llevar a cabo el servicio, el Servicio se considerará solicitado desde el momento en el que Tú recibes noticia a través de la aplicación VAI de que el Servicio se ha aceptado por el Usuario Conductor.</p>
<p custom-agua-item="text" className={styles['text-692-c483ad04cbb342c9909f91c0273a163c']}   >Cuando el Usuario Conductor confirme que completará el servicio de carro compartido que Tú solicitaste, celebras un contrato separado con el Usuario Conductor para la realización del servicio en los términos aceptados entre Tú y el Usuario Conductor. VAI no ofrece servicios más allá del uso de una plataforma tecnológica de interconexión entre los Usuarios y no es parte del contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling.</p>
<p custom-agua-item="text" className={styles['text-693-9fbbe3a26baa4e7398b03d8120e75f25']}   >Por cancelación de un Servicio ya solicitado se entiende una situación donde el Usuario Conductor ha contestado la petición del Usuario Pasajero, subsecuentemente, rechaza, cancela o se niega a continuar con el contrato. Cuando un Servicio se cancela transcurrido un tiempo determinado se podrá realizar el cobro de una tarifa de cancelación como penalidad.</p>
<p custom-agua-item="text" className={styles['text-694-55959e26b27b4e739b3fa36243b2eaee']}   >Si cancelar múltiples Servicios solicitados de forma sucesiva, podremos temporalmente bloquear tu cuenta a modo de aviso. También podemos suspender tu cuenta durante un periodo de mayor duración, (v.gr. 6 meses) si el comportamiento continúa. Transcurrido dicho periodo podrás volver a activar tu cuenta y la solicitud será revisada por VAI.</p>
<p custom-agua-item="text" className={styles['text-695-4424464f263349f5a498cf24eeea61e9']}   >Cuando un Usuario Conductor te notifica de su llegada al destino y Tú o las personas para las que fue solicitado el Servicio no llegan o informan su llegada, transcurrido un periodo de tiempo establecido en la aplicación, la solicitud se entenderá cancelada. En algunas circunstancias, el Usuario Conductor puede decidir cancelar tu petición. Por favor, ten en cuenta que VAI no es responsable de dichas situaciones.</p>
</section>
<section custom-agua-item="layer" className={styles.terceros}   >
<p custom-agua-item="text" className={styles['text-696-f5dbbde228fe432193967b817fdd4fa0']}   >Servicios y Contenido de Terceros</p>
<p custom-agua-item="text" className={styles['text-697-a1130726a40a4731a4c41a86eafdc74e']}   >Los Servicios podrán ponerse a disposición o ser accesibles con servicios y contenido de terceros (incluida la publicidad) que VAI no controle. Tú reconoces que los servicios y el contenido de terceros podrán contar con Políticas de Privacidad y de Tratamiento de Datos Personales y condiciones de uso diferentes.</p>
</section>
<section custom-agua-item="layer" className={styles.titularidad}   >
<p custom-agua-item="text" className={styles['text-698-6f30acc2db9249f7b3b933bc48e3c2d0']}   >Titularidad de los Servicios</p>
<p custom-agua-item="text" className={styles['text-699-fb6b89ce17944c4fa74f42469ff328c5']}   >Los Servicios son y permanecerán siendo propiedad de VAI o de sus licenciantes.</p>
<p custom-agua-item="text" className={styles['text-700-00cadc6c23384e9e8aae6357d961d0b9']}   >Ninguna de estas Condiciones ni Tu uso de los Servicios te transfieren u otorgan derechos:</p>
<p custom-agua-item="text" className={styles['text-701-ec1153983084445a8102ea2ab9059610']}   >1. Sobre o en relación con los Servicios;</p>
<p custom-agua-item="text" className={styles['text-702-5a6b0477b4734c73a1f6ea27d9021529']}   >2. Explotar los derechos sobre la propiedad intelectual de VAI, por fuera de los términos de la licencia; o</p>
<p custom-agua-item="text" className={styles['text-703-c2dffabc114444958c35a518901f251b']}   >3. A utilizar o mencionar en cualquier modo a los nombres comerciales, enseñas comerciales, productos y servicios, o marcas o lemas de VAI, salvo autorización previa, escrita y expresa de VAI.</p>
</section>
<section custom-agua-item="layer" className={styles.uso}   >
<p custom-agua-item="text" className={styles['text-704-c12f18ac3e1d4a24beca1d0a5b473176']}   >Uso de los Servicios</p>
<p custom-agua-item="text" className={styles['text-705-f0cf14c3e87247ba9fd1ea416e0744c0']}   >Cuentas de Usuario: para utilizar los Servicios debes registrarte y mantener activa una cuenta de uso personal de VAI como Usuario (la “Cuenta”), para ello tienes que:</p>
<p custom-agua-item="text" className={styles['text-706-1c9a701260fd40ff822a39d7955db639']}   >1. Tener mínimo 18 años;</p>
<p custom-agua-item="text" className={styles['text-707-02c5ccaf4bd742c7be58a6e1ab607d60']}   >2. Comunicar a VAI cierta información personal como:</p>
<p custom-agua-item="text" className={styles['text-708-35d0d4ffe8f3433c84bdfafa95ca63b1']}   >2.1. El número de tu celular;</p>
<p custom-agua-item="text" className={styles['text-709-dac0f8539f6e47ca98da47804fb19165']}   >2.2 Tu nombre;</p>
<p custom-agua-item="text" className={styles['text-710-13243a3d303d46f980dbefe0bf594f3f']}   >2.3 Tu dirección;</p>
<p custom-agua-item="text" className={styles['text-711-ba49ed5ae792414cbb1e7e130d09bbf3']}   >2.4. Mínimo un método de pago, ya sea una tarjeta de crédito o pago en efectivo al Usuario Conductor.</p>
<p custom-agua-item="text" className={styles['text-712-c971e946961c4f1ba4e8252a3734810f']}   >2.5 Información de tu vehículo como modelo, marca, placa, color y línea.</p>
<p custom-agua-item="text" className={styles['text-713-d4f86e1218074e949712194ee750be42']}   >3. Mantener la información de Tu Cuenta actualizada;</p>
<p custom-agua-item="text" className={styles['text-714-70429c7a75a845fb9a54275755c4f056']}   >4. Mantener de forma segura y secreta el usuario y la contraseña de Tu Cuenta. Por lo tanto, eres responsable de toda la actividad de Tu Cuenta y solo podrás tener una sola Cuenta. En consecuencia, VAI no es ni se hace responsable del acceso no autorizado por terceros a Tu Cuenta;</p>
</section>
<section custom-agua-item="layer" className={styles.requisitosyconductocuenta}   >
<p custom-agua-item="text" className={styles['text-3720-29b6c245abeb49a4ad5a683988c1b451']}   >Requisitos y Conducta respecto de la Cuenta de Usuario</p>
<p custom-agua-item="text" className={styles['text-715-d2c94a6f47ff4d5e88d7e8d7063ce3f7']}   >1. Requisitos y Conducta respecto de la Cuenta de Usuario</p>
<p custom-agua-item="text" className={styles['text-716-03d098605f36408e960cd65dfd51cde0']}   >2. No puedes permitir que terceros utilicen tu Cuenta;</p>
<p custom-agua-item="text" className={styles['text-717-efdf843f8a7a4bd79e91b2bf766fbb9f']}   >3. No puedes ceder tu Cuenta;</p>
<p custom-agua-item="text" className={styles['text-718-c52d0d894d5a40d7a4bf98609b88d694']}   >4. Debes cumplir con todas las leyes al utilizar los Servicios;</p>
<p custom-agua-item="text" className={styles['text-719-c8f68bc774fa4804a319d70b2e8c8469']}   >5. No causarás molestia, incomodidades o daños a terceros;</p>
<p custom-agua-item="text" className={styles['text-720-7432c9a7286e4b888f51e800715a4871']}   >6. Aceptas presentar Tu documento de identidad en caso de que sea requerido, la no presentación de este podrá resultar en la denegación de los Servicios.</p>
</section>
<section custom-agua-item="layer" className={styles.mensajesdetexto}   >
<p custom-agua-item="text" className={styles['text-721-3665639ea9db42e9833ce5ca5950030a']}   >Mensajes de texto: Al crear Tu Cuenta, aceptas que VAI envíe mensajes de texto informativos (SMS) y correos electrónicos como parte de la actividad normal del uso de los Servicios.</p>
<p custom-agua-item="text" className={styles['text-722-a7920e6c33fc4d0ba6fd74a28b4b1293']}   >Podrás solicitar la no recepción de mensajes de texto informativos (SMS) o correos electrónicos de VAI en cualquier momento en el siguiente enlace https://www.vai.com.co/ indicando que no deseas recibir más dichos mensajes, junto con el número de teléfono que recibe los mensajes. Aceptas que solicitar la no recepción de mensajes de texto informativos (SMS) podrá afectar el uso de los Servicios.</p>
</section>
<section custom-agua-item="layer" className={styles.condicionesparticulares}   >
<p custom-agua-item="text" className={styles['text-724-d6550c22da2a4fbf8fc29272ede8a1c3']}   >Condiciones particulares de los Servicios</p>
<p custom-agua-item="text" className={styles['text-723-4bcda8c4e5f64909a8c56ebce395a219']}   >Te podrán aplicar condiciones particulares aplicables a determinados Servicios, como políticas para un evento o promociones especiales, las cuales te serán comunicadas con los Servicios correspondientes. Las condiciones particulares son parte de los Términos y Condiciones y, en caso de conflicto, las condiciones particulares prevalecerán sobre estos Términos y Condiciones.</p>
<p custom-agua-item="text" className={styles['text-725-5a69e7636ddc423e8f49c587aa402306']}   >La recopilación y el uso que hacemos de tus datos personales serán realizados conforme a las Políticas de Tratamiento de Datos Personales de VAI, disponible en https://www.vai.com.co/.</p>
<p custom-agua-item="text" className={styles['text-726-97e73ff648424cc4b2c913be21dd9a18']}   >VAI podrá entregar a un procesador de reclamaciones o a una aseguradora cualquier información necesaria (incluida Tu información de contacto) si hubiera quejas o disputas que puedan incluir un accidente en el cual Tú estés implicado junto a terceros y otros Usuarios.</p>
</section>
<section custom-agua-item="layer" className={styles.codigospromocionales}   >
<p custom-agua-item="text" className={styles['text-727-6ea45629fca145cb8c78304612e8e2c8']}   >Códigos Promocionales</p>
<p custom-agua-item="text" className={styles['text-728-726adba3df3b4c0eb04716898906e701']}   >VAI podrá crear códigos promocionales que podrán ser canjeados por crédito a Tu Cuenta u otros beneficios relacionados con los Servicios que ofrece VAI, sujeto a cualquier condición particular que VAI establezca para cada código promocional (en adelante, los “Códigos Promocionales”).</p>
<p custom-agua-item="text" className={styles['text-729-3be40409bd634a61aff8331486d4aaf1']}   >Tú aceptas que los Códigos Promocionales:</p>
<p custom-agua-item="text" className={styles['text-730-b1158fee6cb54b508892948a1f05b4a4']}   >1. Serán utilizados en el propósito y bajo las condiciones particulares previstas para estos y de manera lícita;</p>
<p custom-agua-item="text" className={styles['text-731-031b5ec28fc14a808959f3088d1020cf']}   >2.No serán duplicados, vendidos o transferidos de ninguna manera, salvo cuando VAI lo permita;</p>
<p custom-agua-item="text" className={styles['text-732-fa1ea1bcebe4442b9260951ff58c0775']}   >3. No podrán ser acumulables con otras promociones, salvo cuando VAI lo permita;</p>
<p custom-agua-item="text" className={styles['text-733-3e40f57cc1244888a93b2645e2f85446']}   >4. Podrán ser invalidados por VAI; y</p>
<p custom-agua-item="text" className={styles['text-734-7b1463d1296444218cd6044481f480cc']}   >5. Podrán caducar antes de que Tú hagas usos de estos, de manera que, las fechas de caducidad de los Códigos Promocionales se verán reflejadas en la aplicación una vez hayas aplicado el código a tu cuenta o hasta agotar existencias de los Códigos Promocionales.</p>
<p custom-agua-item="text" className={styles['text-735-ee03cb759e5b4eb0b733ab787564f038']}   >VAI se reserva el derecho de retener o deducir el crédito u otros elementos o beneficios obtenidos a través de los Códigos Promocionales en el caso de que el uso de estos sea erróneo, ilegal o infringiendo las condiciones particulares del Código Promocional.</p>
<p custom-agua-item="text" className={styles['text-736-606e1e6dc7ae4393988685284de52382']}   >En contexto con lo anterior, si tu servicio excede el crédito asociado al trayecto, la diferencia será automáticamente deducida a través del sistema de pago establecido en tu cuenta. De igual modo, el crédito del código promocional sólo aplica en un único Servicio y, por tanto, no puede extenderse a otros Servicios en caso de que haya un excedente. Sólo se puede aplicar un código promocional por Servicio contratado, salvo cuando VAI lo autorice expresamente.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.usuarios}   >
<p custom-agua-item="text" className={styles['text-135-c6722540fe664901bbadebd97a4fc4e9']}   >De los Usuarios
</p>
<section custom-agua-item="layer" className={styles.garantiadelosconductores}   >
<p custom-agua-item="text" className={styles['text-642-5588699a25cb4fca831839838479066d']}   >Garantías de los Usuarios Conductores</p>
<p custom-agua-item="text" className={styles['text-643-f3c5dee905de49f48c36a2d96375867e']}   >Como Usuario Conductor garantizas en todo momento lo siguiente:</p>
<p custom-agua-item="text" className={styles['text-644-4917db5addc74f7e94a5546b56d4b8cd']}   >1. Que eres el propietario del vehículo que utilices o cuentas con derechos suficientes para utilizarlo y celebrar el contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling.</p>
<p custom-agua-item="text" className={styles['text-645-7f43d04cddd14497aed2d20b004cd278']}   >2. Que cuentas con licencia de conducción vigente y que el vehículo se encuentra en óptimo estado y tiene al día todos los documentos, permisos y seguros necesarios para transitar;</p>
<p custom-agua-item="text" className={styles['text-646-0faa0b78f6d54b3e99277f31a7ebb52b']}   >3. Que conoces y acatarás en todo momento las normas y señales de tránsito;</p>
<p custom-agua-item="text" className={styles['text-647-c638e7fec143481a90c9a5049519af09']}   >4. Que cuentas con los conocimientos y experiencia necesarios para conducir el vehículo y lo conducirás con toda la pericia y diligencia necesarias para un viaje seguro y adecuado; y</p>
<p custom-agua-item="text" className={styles['text-648-1ffced9df3be4c8e89f744491d4d3f3b']}   >5. Que no te dedicas profesional ni habitualmente a realizar servicios de transporte, ni eres un empresario de transporte, ni tienes una actividad económica organizada de transportes, sino que utilizas los Servicios de VAI de manera ocasional para obtener los Beneficios del carro compartido o car pooling.</p>
</section>
<section custom-agua-item="layer" className={styles.contenidodeusuarios}   >
<p custom-agua-item="text" className={styles['text-649-fc918c97f8db43a4ada54ad1699bc8ba']}   >Contenido proporcionado por los Usuarios</p>
<p custom-agua-item="text" className={styles['text-650-9c2e5cde6e5447e5a4b06d9500a8f675']}   >VAI podrá permitirte que publiques, envíes o que pongas a disposición de VAI a través de los Servicios o cualquier otro canal, contenido textual o audiovisual, incluyendo opiniones relativas a los Servicios, solicitudes de ayuda, así como la posibilidad de presentar admisiones para promociones (en adelante, el “Contenido de Usuario”).</p>
<p custom-agua-item="text" className={styles['text-651-2def2b331afc41e29150b8944dee76f2']}   >El Contenido de Usuario es propiedad del Usuario. Sin embargo, debes tener en cuenta que, aunque no le cederás la propiedad a VAI, al momento en que proporciones el Contenido del Usuario a VAI, otorgas una licencia y autorización de carácter global, perpetua, irrevocable, transferible, gratuita, con derecho a sublicenciar, usar, copiar, modificar, distribuir, exhibir públicamente, o de otro modo explotar de cualquier manera dicho Contenido de Usuario en cualquier formato de distribución conocido o por conocerse, sin necesidad de aviso previo o posterior o solicitud consentimiento adicional tuyo.</p>
<p custom-agua-item="text" className={styles['text-652-2909f1b9f3634e1fbc8b4571d564fdf4']}   >Con la remisión o comunicación del Contenido de Usuario, declaras y garantizas que:</p>
<p custom-agua-item="text" className={styles['text-653-a716048469c1489facdfa59d09f2f061']}   >1. Eres el único propietario de todo el Contenido de Usuario o que cuentas con las licencias o el consentimiento de terceros necesarios para otorgar a VAI la licencia y/o autorización sobre el Contenido de Usuario; y</p>
<p custom-agua-item="text" className={styles['text-654-66d1d16b5aa040a49d8521b717d32a90']}   >2. Ningún uso que realice VAI del Contenido de Usuario se entenderá como infracción de la propiedad intelectual o los derechos de autor o conexos o los derechos de privacidad de un tercero, ni resultarán en la violación de cualquier ley aplicable, porque Tú revisaste estos Términos y Condiciones antes de compartir el Contenido de Usuario.</p>
</section>
<section custom-agua-item="layer" className={styles.accesoalared}   >
<p custom-agua-item="text" className={styles['text-655-38a49e63cb3045ef9ed6c0dfbe4899b1']}   >Acceso a la Red y Dispositivos</p>
<p custom-agua-item="text" className={styles['text-656-1c5b00a477bc49d9ac3f71217415b3ae']}   >Tú eres responsable de acceder a las redes de datos necesarias para usar los Servicios. Podrán aplicarse las tarifas y tasas de datos y mensajes de Tu red móvil si accedes a los Servicios desde un celular y serás responsable de dichas tarifas.</p>
<p custom-agua-item="text" className={styles['text-657-f82067c204294210b259bdfa5b926d21']}   >Eres responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios y la Aplicación y cualquier actualización de estos.</p>
<p custom-agua-item="text" className={styles['text-658-5e82b880841e440db74bda869d0751e7']}   >VAI no garantiza que los Servicios funcionen en cualquier hardware o dispositivo particular. Los Servicios podrán ser objeto de disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.pagos}   >
<p custom-agua-item="text" className={styles['text-136-162b62f26268410dad1f1da752a28746']}   >
Pagos</p>
<section custom-agua-item="layer" className={styles.pagodelusuario}   >
<p custom-agua-item="text" className={styles['text-3721-d21d0ec194f6412faca2bf17a77b95ca']}   >Pago del Usuario Pasajero en virtud del Contrato</p>
<p custom-agua-item="text" className={styles['text-619-fae98d49dd8445bbaf6f98b7f96ca289']}   >Entiendes que el uso de los Servicios deriva en la obligación de pagar al Usuario Conductor por la celebración del contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, cuyo valor te será informado de manera previa a que solicites un Servicio (la “Remuneración”).</p>
<p custom-agua-item="text" className={styles['text-620-cba3938d5e9f48018eaa82060be72ab1']}   >Si quieres tomar el servicio de arrendamiento de transporte junto con un acompañante, y además el vehículo cuenta con espacio disponible para él y el Usuario Conductor lo acepta, el acompañante deberá pagará el mismo valor y le aplicarán las mismas condiciones señaladas en los presentes Términos y Condiciones.</p>
<p custom-agua-item="text" className={styles['text-621-4e8f12ea056b4279b734ade069ce6a57']}   >Dependiendo de las opciones de pago soportadas en cada ubicación del Servicio, podrás pagar por los Servicios con tarjeta, efectivo u otros métodos de pago que estén incorporados o que a futuro incorpore VAI, tal y como esté disponible a través de la Aplicación de VAI.</p>
<p custom-agua-item="text" className={styles['text-622-03e5b3b209a24b8e9d98895b9858d0a8']}   >Cada Usuario Conductor ha autorizado a VAI para la mediación de la conclusión de los contratos entre el usuario y el Usuario Conductor, incluyendo la capacidad de aceptar tus pagos y reenviárselos al Usuario Conductor, por lo que, tu obligación con el Usuario Conductor se completará cuando se dé orden de pago para transferir los fondos a la cuenta que VAI ha dispuesto. Tú, como beneficiario del servicio, eres responsable de asegurar que el pago tiene lugar y que cuentas con los fondos suficientes para contratar.</p>
<p custom-agua-item="text" className={styles['text-623-ad4eff532be44a8ca0687fcbec197fbb']}   >Entiendes que VAI informará un valor sugerido para los contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling. Tras la finalización de los contratos, VAI, como intermediario de cobro, facilitará Tu pago al Usuario Conductor. El pago de la Remuneración de dicha manera se considerará como el pago efectuado por el Usuario directamente al Usuario Conductor, salvo que se haya seleccionado la modalidad de pago en efectivo, en cuyo caso deberán realizar el pago directamente al Usuario Conductor.</p>
<p custom-agua-item="text" className={styles['text-624-427b07c5378741ab8e822f32c9c74205']}   >La Remuneración incluirá los impuestos aplicables. La Remuneración que Tú pagas es definitiva y no reembolsable, sujeto a lo previsto en la ley aplicable.</p>
<p custom-agua-item="text" className={styles['text-625-e0dbdd0252fd49dc96f8e7373f827334']}   >Tú conservas el derecho de acordar una Remuneración más baja o alta con el Usuario Conductor.</p>
<p custom-agua-item="text" className={styles['text-626-3e72343d1bea4b2dbf3a28e2393f17e6']}   >La Remuneración es pagadera inmediatamente y el pago será facilitado por VAI utilizando      el método de pago indicado en su Cuenta. Después de ello VAI podrá enviarle un recibo por correo electrónico.</p>
<p custom-agua-item="text" className={styles['text-627-6af1f0003c4847818439bb40b0bb82bb']}   >Si se determina que el método de pago de Tu Cuenta ha caducado, o no sirve para cobrarte, aceptas que VAI, como intermediario de cobro, utilice alguno de los métodos de pago secundarios en Tu Cuenta, si estuviera disponible.</p>
<p custom-agua-item="text" className={styles['text-628-b0df928ef32048f6aab58e149ade73d9']}   >VAI podrá proporcionar a determinados Usuarios ofertas y descuentos que pueden alterar la Remuneración.</p>
<p custom-agua-item="text" className={styles['text-629-7eae2d75a79d43f0900ee8367d430612']}   >Esta estructura de pago busca remunerar el uso de los Servicios y la facilitación para celebrar los contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, celebrados de manera independiente por el Usuario Conductor y el Usuario Pasajero.</p>
<p custom-agua-item="text" className={styles['text-630-027e3a57ff5746c8939ca41748203159']}   >Tú aceptas que la entrega de un pago adicional o propina al Usuario Conductor es libre, voluntaria y se encuentra sujeta a tu discreción. La propina puede ser pagada a través del sistema de pago integrado en la aplicación por medios autorizados por VAI a tales efectos o en efectivo. VAI no obtendrá una comisión por los servicios para dejar la propina y la propina será transferida al Usuario Conductor íntegramente, excluyendo los impuestos aplicables. VAI se reserva el derecho de suspender la propina, si el pago de la propina se sospecha fraudulento, ilegal, o por otros propósitos más allá de la gratuidad relativa con el servicio o es usada en conflicto con los Términos y Condiciones de VAI.</p>
<p custom-agua-item="text" className={styles['text-631-909d548271ab4e1394c066044441d153']}   >Adicionalmente, debes tener en cuenta que VAI te podrá solicitar información adicional para verificar el método de pago.</p>
<p custom-agua-item="text" className={styles['text-632-ce8c9283920c4f3286c665a9bf3ea562']}   >Cuando se realicen pagos de los Servicios a través del sistema de pago incorporado a la Aplicación, VAI no se responsabiliza por los posibles costes adicionales que sean cargados por terceras partes, a modo de ejemplo, comisiones bancarias u operadores de telefonía; estos proveedores de servicios pueden cargarte cuotas o cargos adicionales cuando procesan los pagos relativos al sistema de pago integrado en la aplicación de VAI. VAI no se hace responsable de tales cuotas o cargos y queda exento de responsabilidad en estos términos. Tu método de pago puede, adicionalmente, estar sujeto a términos y condiciones de terceras partes y a condiciones impuestas por estas partes proveedoras de otros servicios, por lo que, VAI no es responsable de los términos y condiciones establecidos por los terceros proveedores y te recomendamos revisar sus términos y condiciones antes de usar tu método de pago.</p>
<p custom-agua-item="text" className={styles['text-633-d9c9b2665f1e48d18767b057f0732c50']}   >Tras la finalización de un contratos civiles de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling podrás calificar tu experiencia y dejar comentarios adicionales sobre el Servicio, el vehículo o el Usuario Conductor.</p>
<p custom-agua-item="text" className={styles['text-634-3035105f3e484cb886689574631c21e4']}   >VAI será responsable de la operabilidad del sistema de pago integrado en la aplicación y prestará soporte en la resolución de sus problemas. La resolución de disputas relativas al sistema de pago integrado en la aplicación tendrá lugar a través de VAI o el área designada por nosotros. Para soporte del servicio de pago, por favor, contacta los medios dispuestos en la página web de VAI.</p>
</section>
<section custom-agua-item="layer" className={styles.tarifareparacion}   >
<p custom-agua-item="text" className={styles['text-635-4a2f4fca792b4413bb5f1ff377e6e784']}   >Tarifa de reparación, limpieza o cargos por objetos olvidados.</p>
<p custom-agua-item="text" className={styles['text-636-6b9310277a3c4ff89a70a4c932c2c383']}   >En Tu condición de Usuario Pasajero, serás responsable de los costos de reparación por daños que causes o de la limpieza necesaria del vehículo del Usuario Conductor, que se ocasionen por el uso de los Servicios y que sobrepasen los daños considerados normales por en el giro ordinario de los Servicios.</p>
<p custom-agua-item="text" className={styles['text-637-5f1eb463f5fc409dad40d385dadc54eb']}   >Si un Usuario Conductor informa de la necesidad de reparación o limpieza, VAI se reserva el derecho de revisar dicha información y facilitar el pago por el costo razonable de ésta en nombre del Usuario Pasajero utilizando Tu método de pago indicado en Tu Cuenta. Dichos costos, así como otros adicionales que puedan ser aplicables, se transferirán por VAI al Usuario Conductor, siempre y cuando se respete el debido proceso.</p>
<p custom-agua-item="text" className={styles['text-638-69a37dbd4dd545cc90395f3b51667340']}   >Los valores correspondientes a los cargos que te sean realizados podrán ser acordados con el Usuario Conductor y/o encontrados en la página web, los cuales son sugeridos por VAI, disponibles en https://www.vai.com.co/, las cuales podrán ser actualizadas por VAI cuando lo considere pertinente.</p>
</section>
<section custom-agua-item="layer" className={styles.comsisiondevai}   >
<p custom-agua-item="text" className={styles['text-639-5b23d10f369a4ba49d4afcf0368a6c66']}   >Comisión de VAI</p>
<p custom-agua-item="text" className={styles['text-640-6aef8fcc28bd47f5a91ac5526e9f3689']}   >Como contraprestación por los Servicios de intermediación proporcionados por VAI y por el uso de la licencia de uso de la Aplicación, el Usuario Conductor deberá pagar a VAI un valor correspondiente al diez por ciento (10%) del valor del contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, así como el diez por ciento (10%) de las penalidades por cancelación cobradas al Usuario Pasajero, cuando lugar a ella, de acuerdo con estos Términos y Condiciones.</p>
<p custom-agua-item="text" className={styles['text-641-081c6742677e45fe9e72a6dde80e45ee']}   >El Usuario Conductor acepta expresamente que VAI compense y descuente directamente los valores antes mencionados de cualquier suma de dinero del Usuario Conductor que VAI deba pagarle y que VAI haya recibido por parte del Usuario Pasajero por cualquiera de los canales de pago disponibles en la aplicación.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.disposicionesfinales}   >
<p custom-agua-item="text" className={styles['text-137-d6e906d16c0e437eaefdb284975e5bcb']}   >Disposiciones finales</p>
<section custom-agua-item="layer" className={styles.renuncia}   >
<p custom-agua-item="text" className={styles['text-1313-0ec732e2fcab4706b2670516f31bd778']}   >Renuncia:</p>
<p custom-agua-item="text" className={styles['text-1314-fee78e81600844c89a60234c9aca337f']}   >Sujeto a la ley aplicable:</p>
<p custom-agua-item="text" className={styles['text-1315-bc60084d9f4148c5b533d719517cadde']}   >1. VAI no hace ninguna declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los Servicios o que los Servicios estarán libres de errores;</p>
<p custom-agua-item="text" className={styles['text-1316-ff6f886b82f848e4b390ae52c1419bee']}   >2. VAI no garantiza la calidad, idoneidad, seguridad o habilidad de los Usuarios Conductores o sus vehículos; y</p>
<p custom-agua-item="text" className={styles['text-1317-08bfb30c14f441ef9faad019f527f4d9']}   >3. Aceptas que serás responsable por el riesgo derivado de la celebración de los contratos civiles de arrendamiento de transporte o el uso de los Servicios en la máxima medida permitida por la ley aplicable.</p>
</section>
<section custom-agua-item="layer" className={styles.limitacionderesponsabilidad}   >
<p custom-agua-item="text" className={styles['text-1318-d5b9ad50fa684807a9696766d15fa2be']}   >Limitación de Responsabilidad
</p>
<p custom-agua-item="text" className={styles['text-1319-b0a2e3cb7df84fb4b6df61850a591451']}   >VAI es un proveedor de servicios de interconexión de la sociedad (medio de comunicación) entre Usuarios Conductores y Usuarios Pasajeros, de manera que, no podemos garantizar o aceptar ninguna responsabilidad por la calidad o ausencia de defectos en la prestación del servicio de los Usuarios Conductores.</p>
<p custom-agua-item="text" className={styles['text-1320-f3e40f73c5c84256876452335348a00b']}   >En contexto con lo anterior, VAI no ofrece ni negocia servicios de transporte para sus usuarios; tampoco es una agencia de transporte.</p>
<p custom-agua-item="text" className={styles['text-1321-79ec4b01b0f244b69f732be46c3e4d33']}   >La aplicación dispuesta por VAI es entregada tal y como se encuentra con las condiciones de disponibilidad. VAI no garantiza, asegura o promete que el acceso a la aplicación sea ininterrumpido o libre de errores. En los eventos en que ocurran fallos en el software, VAI tratará de corregirlos tan pronto como sea posible, sin embargo, se debe tener en consideración que el uso de la aplicación puede restringirse por errores técnicos y que no permite garantizar a los Usuarios que la aplicación funcione en todo momento.</p>
<p custom-agua-item="text" className={styles['text-1322-e7377760bc814be5b1c4dee3b7a58817']}   >VAI no será responsable de cualquier daño, responsabilidad o pérdida que se deriven de:</p>
<p custom-agua-item="text" className={styles['text-1323-b6b9fffddd2349b88944cd2791c46d18']}   >1. Tu uso de los Servicios o Tu incapacidad para acceder o utilizar los Servicios;</p>
<p custom-agua-item="text" className={styles['text-1324-f0bba4c1dbe94fb4b9360d9457a02c6d']}   >2. La disponibilidad o precisión de la información y/o contenido de los terceros o de los Usuarios en relación con el servicio;</p>
<p custom-agua-item="text" className={styles['text-1325-ad4f26eac3c14b2fa2591e62a3fb2836']}   >3. Cualquier transacción o relación entre cualquier Usuario y Tu;</p>
<p custom-agua-item="text" className={styles['text-1326-58fc7caa5cd844fd940d8a940dae5c9e']}   >4. VAI no será responsable del retraso o de la falta de ejecución de los Servicios;</p>
<p custom-agua-item="text" className={styles['text-1327-e4d255bb2b9e4b7a952abc4e80697db0']}   >Tú reconoces y aceptas que los Usuarios serán responsables por el cumplimiento de la ley aplicable. La responsabilidad total de VAI hacia los Usuarios en relación con los Servicios se limitará a los perjuicios efectivamente causados por VAI, sin que la misma pueda exceder de COP$1.000.000. Sin embargo, tienes derecho a reclamar, siempre y cuando VAI haya infringido deliberadamente el contrato regulado en los presentes Términos y Condiciones. VAI no será responsable por acciones u omisiones de los Usuarios, y no será responsable por los perjuicios que los Usuarios o terceros puedan causar a otros Usuarios.</p>
<p custom-agua-item="text" className={styles['text-1328-803e8f1d6830447681aca611a5070aad']}   >Adicionalmente, VAI, sus representantes, directivos y empleados no son responsables por cualquier daño o pérdida que pueda ocurrir como resultado del uso de la aplicación o plataforma tecnológica o derivado del servicio contratado por medio de la aplicación, incluyendo, pero sin limitarse a:</p>
<p custom-agua-item="text" className={styles['text-1329-3b6c4a901f10415c845ad6aa53afac06']}   >1. Lucro Cesante o Daño emergente;</p>
<p custom-agua-item="text" className={styles['text-1330-a89f6261057f4a13b35ac2ae00987655']}   >2. Lucro Cesante o Daño emergente;</p>
<p custom-agua-item="text" className={styles['text-1331-4239f64386d6443f824ec51842730159']}   >3. Pérdida de negocios, contratos, contactos, buena fe, reputación y en general cualquier pérdida que surja de la interrupción de los servicios;</p>
<p custom-agua-item="text" className={styles['text-1332-dea7378077134ec9bcc9ca7ef545f94b']}   >4. Pérdida o imprecisión de los datos;</p>
<p custom-agua-item="text" className={styles['text-1333-46f3eb5672b74b899196db3f331439b0']}   >5.  Cualquier otro tipo de pérdida o daño.</p>
<p custom-agua-item="text" className={styles['text-1334-7dd965619c4b4bd2b2967cd7c61d70c2']}   >En consecuencia, la responsabilidad que VAI asume es única y exclusivamente en relación con su obligación como intermediario, de acuerdo con los presentes Términos y Condiciones.</p>
</section>
<section custom-agua-item="layer" className={styles.indemnidad}   >
<p custom-agua-item="text" className={styles['text-1335-c1edf464b2064deabba50e9dccee29d9']}   >Indemnidad</p>
<p custom-agua-item="text" className={styles['text-1336-f1c4c3eb60424746aee657c82e59df12']}   >Sujeto al principio de reparación integral, Tú aceptas mantener indemne a VAI de cualquier reclamación, demanda, daño, responsabilidad y gasto (incluidos los honorarios de abogados) que deriven de:</p>
<p custom-agua-item="text" className={styles['text-1337-d870db9fdee04db5a5e2c8a19c706dde']}   >1. Tu uso de los Servicios o de la ejecución de los Contratos;</p>
<p custom-agua-item="text" className={styles['text-1338-3aaebe274b1442ad87c3bb1dd6bbd599']}   >2. Tu incumplimiento o violación de estos Términos y Condiciones;</p>
<p custom-agua-item="text" className={styles['text-1339-aecdd1b8fabf47739f5465d3a4f6fbf4']}   >3. El uso por parte de VAI de Tu Contenido de Usuario; o</p>
<p custom-agua-item="text" className={styles['text-1340-3f99f4b1356f41818298817f7590cd8f']}   >4. Tu infracción de los derechos de cualquier tercero, incluidos los Usuarios Conductores.</p>
</section>
<section custom-agua-item="layer" className={styles.propiedadintelectual}   >
<p custom-agua-item="text" className={styles['text-1341-182c3daf6d2f4f2faa7a064a717d85c7']}   >Propiedad Intelectual</p>
<p custom-agua-item="text" className={styles['text-1342-0422afd96c78433b9c936bc629a19640']}   >Todo el contenido puesto a disposición por VAI para el uso de los Servicios, incluyendo la propiedad intelectual (que comprende la propiedad industrial y los derechos de autor y conexos), es propiedad exclusiva de VAI o de los terceros con los que VAI tiene alguna relación. Tú te comprometes a abstenerte de reutilizar el contenido por fuera lo que señalen estos Términos y Condiciones sin el consentimiento previo y escrito de VAI.</p>
</section>
<section custom-agua-item="layer" className={styles.reclamacionesporpropiedadintelectual}   >
<p custom-agua-item="text" className={styles['text-1343-5f1e45468c7945b4a316e41724b61410']}   >Reclamaciones por infracción de propiedad intelectual.</p>
<p custom-agua-item="text" className={styles['text-1344-ec2eed2659d142f4a0919de74c87fd96']}   >Las reclamaciones por infracción de derechos de propiedad intelectual deberán enviarse al agente designado por VAI. Visita la página web de VAI en [insertar enlace] para obtener las direcciones designadas e información adicional.</p>
</section>
<section custom-agua-item="layer" className={styles.firmaselectronicas}   >
<p custom-agua-item="text" className={styles.text1345}   >Firmas Electrónicas</p>
<p custom-agua-item="text" className={styles['text-1346-f9f53f847f8545759ff51d65d7e80dbf']}   >Tú reconoces que aceptas los Términos y Condiciones a través del “click o tap” y que dicha acción es equivalente a la firma de un documento en físico.</p>
<p custom-agua-item="text" className={styles['text-1347-2403c521cd2a4967a8ee942b9549bc5b']}   >En cumplimiento de ley aplicable sobre mensajes de datos, las Partes reconocen que, mediante el cruce de mensajes por medios electrónicos, pueden dar lugar al nacimiento, modificación y extinción de obligaciones, siendo de Tu responsabilidad exclusiva el contenido, consecuencias, responsabilidades y efectos de la información generada. Por lo tanto, con la sola solicitud de un servicio de arrendamiento de transporte por parte de un Usuario Pasajero y su aceptación por parte de un Usuario Conductor se celebra un contrato civil de arrendamiento de transporte bajo la modalidad de carro compartido o car pooling, de acuerdo con lo establecido en los presentes Términos y Condiciones y en las condiciones particulares indicadas en la Aplicación.</p>
<p custom-agua-item="text" className={styles['text-598-06659affb1104c61a4fb19c7db1a5318']}   >VAI podrá notificarte por medio de:</p>
<p custom-agua-item="text" className={styles['text-599-5b14cdffc6174954aae455348fde3fd8']}   >1. Una notificación a través de la Aplicación</p>
<p custom-agua-item="text" className={styles['text-600-bfe7896435f14dedba3e8c4e6513d95e']}   >2. Mediante un correo electrónico enviado a la dirección de correo electrónico prevista en Tu Cuenta; o</p>
<p custom-agua-item="text" className={styles['text-601-a02139c529104714a50235881dc9bb09']}   >3. Por comunicación escrita enviada a Tu dirección, según lo dispuesto en Tu Cuenta.</p>
<p custom-agua-item="text" className={styles['text-602-99df7df4a7be49aeb0a042b66facbfb5']}   >De otro lado, VAI recibe notificaciones en los canales dispuestos en la página web de VAI, disponibles en https://www.vai.com.co/</p>
</section>
<section custom-agua-item="layer" className={styles.disposicionesgenerales}   >
<p custom-agua-item="text" className={styles['text-603-0c78bb213d3f48f29d751319bac68550']}   >Disposiciones generales</p>
<p custom-agua-item="text" className={styles['text-604-e18bc4b4e23d48d2aff8e480b1f55762']}   >No podrás ceder el contrato celebrado mediante la aceptación de los presentes Términos y Condiciones, sin el consentimiento previo por escrito de VAI. Autorizas a VAI para ceder o transferir estas Condiciones a:</p>
<p custom-agua-item="text" className={styles['text-605-020437ad8333401d9ab0d9b60bf36650']}   >1. Una subsidiaria o un afiliado;</p>
<p custom-agua-item="text" className={styles['text-606-68be2ce87fee464f83c18f319352fc68']}   >2. Un adquirente del capital, del negocio o de los activos de VAI; o</p>
<p custom-agua-item="text" className={styles['text-607-f9ac6d0db9fc484d9457cd03a4f88b9e']}   >3. Un sucesor por fusión.</p>
<p custom-agua-item="text" className={styles['text-608-c647d5357b894f3eab87554b8744e707']}   >No existe entre los Usuarios y VAI una relación laboral, de prestación de servicios o agencia de ninguna naturaleza.
</p>
<p custom-agua-item="text" className={styles['text-609-5c1a3a77d76c497ab55926ad7f743395']}   >Sugerimos que, al finalizar el servicio, rellenes el cuestionario de calidad integrado en la aplicación, con la finalidad de ofrecer sugerencias a los Usuarios para mejorar la calidad de su servicio.</p>
<p custom-agua-item="text" className={styles['text-610-4e49b7a255e84347a68adbc56b38c4b7']}   >El uso de la aplicación y la plataforma tecnológica dispuesta por VAI deberá ser usada de buena fe y con respeto hacía los Usuarios y con los integrantes de VAI, de tal forma que VAI mantiene el derecho a cerrar Tu cuenta si has violado o infringido las condiciones aquí establecidas o en caso de que tus actuaciones o conductas son violatorias de normas o maliciosas, a modo de ejemplo, pero sin limitarse al fraude, tener un trato irrespetuoso con las personas de VAI u otros Usuarios, suplantación de identidad, entre otras. En estos casos, tu cuenta en la aplicación podrá ser revocada o suspendida sin previo aviso.</p>
<p custom-agua-item="text" className={styles['text-611-5a30cf21c7994d158ac1aa9ecc92b3f2']}   >VAI hará todo lo que está a su alcance para que solo los Usuarios que tengan una buena integridad en la sociedad sean aquellos que puedan hacer uso de la aplicación y la plataforma tecnológica dispuestas por VAI. No obstante, VAI no puede garantizar que cada Usuario de la aplicación satisfaga el referido criterio en todo momento. En virtud de lo anterior, le recomendamos que, en caso de experimentar una situación reprochable o cuestionable del servicio, se notifique a VAI y a la autoridad competente en los casos que lo ameriten y VAI tomará las medidas pertinentes.</p>
<p custom-agua-item="text" className={styles['text-612-c1a43445c65b4feeb252991909d54d30']}   >Si se determina que cualquiera de las disposiciones de estos Términos y Condiciones es nula, inválida o ineficaz, en todo o en parte, tal nulidad, invalidez o ineficacia afectará únicamente a tal disposición o parte suya, y el resto de tal disposición, y las demás disposiciones de este documento continuarán siendo vinculantes. En este caso, las Partes sustituirán la disposición afectada por otra con un espíritu y efecto legal y/o económico similar al de la disposición ineficaz o invalidada.</p>
</section>
<section custom-agua-item="layer" className={styles.legislacionaplicable}   >
<p custom-agua-item="text" className={styles['text-613-4f19e7f97c2f4e4d892cf88eeb02d112']}   >Legislación aplicable; Arbitraje</p>
<p custom-agua-item="text" className={styles['text-614-0270bc4c6fe54be5bcf8c75e21a36219']}   >Los presentes Términos y Condiciones se regirán en todos sus efectos por su contenido y por la Ley colombiana aplicable.</p>
<p custom-agua-item="text" className={styles['text-615-58a58a2e17354a6ea059cd79bdb03ead']}   >Cualquier diferencia que ocurra entre las Partes en relación con los Términos y Condiciones se resolverá directamente entre ellas mediante arreglo directo. En caso de que no sea posible resolverla directamente entre ellos, será resuelta por un tribunal de arbitramento que funcionará en el Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá. Los árbitros serán nombrados de común acuerdo por las partes o, a falta de acuerdo, serán nombrados por sorteo por el Centro de Arbitraje.</p>
</section>
<section custom-agua-item="layer" className={styles.modificaciones}   >
<p custom-agua-item="text" className={styles['text-616-94b023e36310456e9f368ffaa8151087']}   >Modificaciones y Vigencia de los Términos y Condiciones.</p>
<p custom-agua-item="text" className={styles['text-617-1f5867795e6f44d69d584768dee9350a']}   >Si se realizan modificaciones a estos Términos y Condiciones, te notificaremos vía e-mail o a través del canal de notificaciones autorizado o integrado en la aplicación o plataforma tecnológica dispuesta por VAI. De tal forma que, si continuas con el uso de la aplicación o la plataforma tecnológica de VAI, se entenderán aceptadas tácitamente las nuevas modificaciones de las Condiciones.</p>
<p custom-agua-item="text" className={styles['text-618-92ac279e8595446a9de5c61a1bab1aba']}   >Los presentes Términos y Condiciones rigen a partir del 29 de mayo de 2023 la cual reemplaza cualquier declaración, manifestación o comunicación anterior con referencia al tema aquí en materia que haya hecho VAI COLOMBIA S.A.S.</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homefooter2}   >
<section custom-agua-item="layer" className={styles['layer-1292-f03cda6db4324ca1830d87ee5c7b1c4c']}   >
<section custom-agua-item="layer" className={styles['layer-1302-058fcee2854f4427a0363253f0a7e618']}   >
<section custom-agua-item="layer" className={styles['layer-1352-eb6babbcbd30499fbf9b74b7256a0c7b']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-92-691591a6abc84393af9e30b3e5918fdb']}  alt="imageview-92" src={ image_imageview_92}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1362-1df4b564628a49899eb0fae917d37512']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-102-34db98a568fd474bb530c3ead480e5e8']}  alt="imageview-102" src={ image_imageview_102}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1372-0806824db77144588d691e5110e4753b']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-112-07ec60746ddb44cdae4981856fba2d00']}  alt="imageview-112" src={ image_imageview_112}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1312-a174b5f65a87495a98a131f01d26efc3']}   >
<p custom-agua-item="text" className={styles['text-6202-dfe869e95a564a5baa64e3b6278f912c']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1332-a4f9f59e5c9845c8a8777454415018be']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-4342-8bf8717d5fef46b6b4a4ff54713bdcbf']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6242-f2ab6fbc122c4495afff5169823ab5be']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1322-cfeabcc9eb9b4ca3b9adf5ae8dd0a49e-orientation']} ${styles['layer-1322-cfeabcc9eb9b4ca3b9adf5ae8dd0a49e']}`}   >
<section custom-agua-item="layer" className={styles['layer-1382-9c9a99ddbf3a42fcacdbee17a04f6f9b']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-4352-1cefe36a57d5413990f0eeca36ad7807']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6212-edc6982bc0fa4ac8858fab50cd76de25']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1392-8d852b7d426f4a7d99dac3bdc613eaca']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-5482-799a443dd5d24a20ba2ecb79e289869d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6222-5664210be1424295aca02d3fa38e5f73']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1402-386d2d48470941aab1b596bc21ff5642']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-6232-96eb39fa0ecc4fcba8092b35d170a2f3']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1342-9d899c37ad564268b6819c548846c03d-orientation']} ${styles['layer-1342-9d899c37ad564268b6819c548846c03d']}`}   >
<section custom-agua-item="layer" className={styles['layer-1412-63177a0795e64e128ed43fcacfdd97e4']}   >
<section custom-agua-item="layer" className={styles['layer-1422-d8df5ab7ff724adfae497d409f5459e5']}   >
<p custom-agua-item="text" className={styles['text-6252-c22092a4968f4998bf622ec22d4d78c1']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1432-e8d693a2f8c14badab47d586f510430e']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-6262-ac0955dacfcf48c09ba815ae6e33ec0f']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1442-33ed8995a73947a381c2cc10396df086']}   >
<p custom-agua-item="text" className={styles['text-6272-d790459db1204f17b0246b99c1652b0b']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Terminosycondiciones;
            
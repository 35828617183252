import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './carpooling.module.css';

/**
 * Carpooling component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Carpooling component.
 */
const Carpooling = () =>{
    
    document.title = "Vai | Carpooling";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Carpooling}>
                
<section custom-agua-item="layer" className={styles.carpoolingheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-8e20bb1cc13c4383bcf70ae95ca981d2']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-8d6fa4bf8cb74d2da790022750839199']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-c54741e1bd684014bcb7eb2534a757e0']}   >
<section custom-agua-item="layer" className={styles['layer-2122-b2232018477548e8b0b426302fb0b100']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-8132f897723941208ba60b3d0949c5b2']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-3ddf4054813a4a27b321887fcea3467d']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-4d617b69588b42a089a1a1b91dadac5f']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-1f38a64cbd394c4e917d48af4a2c3084']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-04b26b9191b042e5b39582159910eb02']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-e07ec715234a482b8450bc738ecb409f']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-c3b9611c86174e359fa8697f3e8fc8ee']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-ae157f2046dd4a1998c59da0ceb888bf']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-a9ffd33ed78a4cdeb9578c71324ae425']}   >
<section custom-agua-item="layer" className={styles['layer-2112-8be220f5e27f419e9c4d564f8a90df71']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-77f6e059873b4d268b8275bacf5523b6']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-e8683c4060bb4115a939c02d71b0cf83']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-9391531c94a2407f8f7517807db4a849']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-a4e2d082edd244a3b118ef815c2786e9']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-ec357775799d49768f6cb6d453b59aae']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-0d732e2f54f44322b9f0372490f67b68']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.carpoolingbody}   >
<section custom-agua-item="layer" className={styles.carpoolingsection1}   >
<section custom-agua-item="layer" className={styles['layer-1282-6909fb2aa90e4b18aa20618edab9ecf7']}   ></section>
<section custom-agua-item="layer" className={styles['layer-1283-8d6d750f8a1d4035960fd33c58f601c0']}   >
<section custom-agua-item="layer" className={styles['layer-1033-ce4c32614ef94b93a01a21817d531334']}   >
<section custom-agua-item="layer" className={styles['layer-135623-4d47d122eb294148a30b98fa85118e67']}   >
<Link to="/Ventajas"><section custom-agua-item="layer" className={styles['layer-2746-435fc8b1c33b4be7a79a8f63e4a79175']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-94323-9c8c4ffe35c84cdf8f595ada56629c94']}   >Ventajas</p>
<span custom-agua-item="icon" className={styles['icon-2923-b8e87de82d3e49639f337685ddcd0b87']}  >navigate_next</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.carpoolingsection2}   >
<section custom-agua-item="layer" className={styles['layer-2069-a111277fc2a44313b70845ef03a96c29']}   >
<section custom-agua-item="layer" className={styles['layer-10332-51ff160c005047978ba7b7aefa6fcdf5']}   >
<section custom-agua-item="layer" className={styles['layer-13562-fa0b3d7ae17740259c755aa4f02fe4f8']}   >
<Link to="/Sostenibilidad"><section custom-agua-item="layer" className={styles['layer-2747-51ff727f629340029ef9b84de8bfa9e1']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-9432-1115d58533c74aafbe1a538e66b5ca3a']}   >Sostenibilidad</p>
<span custom-agua-item="icon" className={styles['icon-292-83316844ff334b5282fa03f0b9952901']}  >navigate_next</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.carpoolingsection3}   >
<section custom-agua-item="layer" className={styles['layer-2154-0474502fbc3e4a3b9c83bbc33cb8e08d']}   >
<section custom-agua-item="layer" className={styles['layer-103322-1a00fee4f7664a7e89a2249a144a2ba6']}   >
<section custom-agua-item="layer" className={styles['layer-1498-7b860fd4adc3451493e1201119284069']}   >
<Link to="/Ayuda"><section custom-agua-item="layer" className={styles['layer-2748-93f3927b3e204ea7ad30061a859a8566']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-1102-4b120bba1c4b41ddaecb152712a2f763']}   >Ayuda</p>
<span custom-agua-item="icon" className={styles['icon-40-e2b88953f0104203b47cdfcd03565733']}  >navigate_next</span>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.carpoolingfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-7f718958603c4deeb249f40e6f1330a2']}   >
<section custom-agua-item="layer" className={styles['layer-13022-656ed3d2efe3433da8b279b9803172b6']}   >
<section custom-agua-item="layer" className={styles['layer-13522-40c8f5dbb2984e3caa37bef51453a743']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-0bb3d2df4c8d437d8753a0f4adce05da']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-37a71528aa4e4b21bc54102ed13974f9']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-38753bc404a14c4b9b353632828d3035']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-08692e80a08f46acab10931b313bcc50']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-1c7acbe19d8148c19e59640d76f1d70c']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-34db718fd0fc411caf42355c765ac497']}   >
<p custom-agua-item="text" className={styles['text-62022-bd8460b3639f40d39ef0086035c3d661']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-6fad828acba941eea26891030dffc9b8']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-6026f681c11441cda990ddbc20b11f41']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-9a8d170e34304c3cb43e5faa4e92e7b6']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-e9729b7eeb3d43f89e0492dce7152c3f-orientation']} ${styles['layer-13222-e9729b7eeb3d43f89e0492dce7152c3f']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-cf9781ae97fb426abe38596a51de56fc']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-1bc26b203e1447a2b29fffebe685ffa1']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-7c9d9779b523448c924dad62c44c3ebc']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-6a9caf8fc75b4041bcdb6f675540a7da']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-9624f88824264f3dadd04924cb92e80b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-0d1b1232cada40fa916e237e0aacab00']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-8378d9d587ad4c868c1d04d505f3fc06']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-868a9828b52b4cfc93f7c524f6a5cd1e']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-8a3a4b924fd64114ad3b60c9898bd654-orientation']} ${styles['layer-13422-8a3a4b924fd64114ad3b60c9898bd654']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-316bb74fef4e423182961f7b4d416157']}   >
<section custom-agua-item="layer" className={styles['layer-14222-105bc8eb563f4757840f52ba7b456c76']}   >
<p custom-agua-item="text" className={styles['text-62522-dc1d75dc40c646fb861c68bb4ee0ed64']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-94bc4e141e594a65b0de81fe792dc354']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-035fe8f9a84e4ab8a5201c92ed343f4f']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-414cde75ca304da9b9f4873875e7e067']}   >
<p custom-agua-item="text" className={styles['text-62722-2ea5faac9b234e5f9b1fae6c4548d2d8']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Carpooling;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './mobilemenu.module.css';

/**
 * Mobilemenu component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Mobilemenu component.
 */
const Mobilemenu = () =>{
    
    document.title = " Vai | Menu";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Mobilemenu}>
                
<section custom-agua-item="layer" className={styles.mobilemenuheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-46720b5c9c2345179f4ee859c6573cf9']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-f20b7f3b8e0245b29217c1e0c5ec0782']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-45c31bded2434db6bf4b578b23cbd513']}   >
<section custom-agua-item="layer" className={styles['layer-2122-6972fd2d2d99404580510bd2cdbb3e55']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-8a889e8d8da5490894007513da0ba1bd']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-198821d530bb49b5a6860c2c9e2fa457']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-0b3f8997b2d9414f90cdb909e143a82d']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-5e384e6ac80349ffb1d4a0a24ed3eb81']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-58df817d06854270b56bc3236a2f8cf6']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-1714db4ec92b47a2bf3e0748cd794b30']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-a2207ccd12ed4ea0b0e53240d50ef1c3']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-f9fe51239b6746d68925a6f0a1edbc0b']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-877661eb3f604ecb955e28b4a87350c3']}   >
<section custom-agua-item="layer" className={styles['layer-2112-17da35470cf74fac97099c5009f962ac']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-0d1007c95dfc4ef3902111d61a86a7a3']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-5edd0f665afc444289dc4b35e239f844']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-d0ca56cf678043c190e70feef26fc58d']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-aa141102ba974f549cf54d1a6c9b0096']}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-3582-18c91a89f4a249cc9b01e4c9b56ce2ca']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-3366c686284a47889d2d4dca923b0532']}  >clear</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mobilemenubody}   >
<section custom-agua-item="layer" className={styles.mobilemenusection1}   >
<section custom-agua-item="layer" className={styles['layer-6762-8aff5b9fdefb41be9141d4743af2a2d1']}   >
<section custom-agua-item="layer" className={styles['layer-6772-72988e540e73460d90756514c2c712b9']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-732-fe0770a9617743b281fcbc7ae46b87e2']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-8302-ba8939b1bf7e487da7794ede5fdf4490']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-67722-03ea9885e42a4088acf63a9999721a4d']}   >
<Link to="/Ventajas"><section custom-agua-item="layer" className={styles['layer-7322-f28ac5ea78924eda966157d42853c4db']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-83022-f863aaf3cfbb4304877f96c01958c4e4']}   >Ventajas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-677222-dd0e373466a247b1a327a46023625bf6']}   >
<Link to="/Sostenibilidad"><section custom-agua-item="layer" className={styles['layer-73222-41fb77b0e6644f1e82485517481edca9']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-830222-4b6426f8ec4d4635b7896ab9753049cc']}   >Sostenibiliad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-67725-c3cea34a524a490c8ec8779cfdb13872']}   >
<Link to="/Ayuda"><section custom-agua-item="layer" className={styles['layer-7325-f1666a76ea8a487aa50abcf1e7a11c4a']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-83025-0072c94aab0d4f7a933cf1a54dbdf550']}   >Ayuda</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mobilemenusection2}   >
<section custom-agua-item="layer" className={styles['layer-990-db70a276813a4bf8b6061ab7e4620d1d']}   >
<section custom-agua-item="layer" className={styles['layer-67622-e3da73b1680b4278817db22c467c0d87']}   >
<section custom-agua-item="layer" className={styles['layer-67723-8f9028f480e440ed800e31d150bc40c7']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-7323-286e73ae188d4bbb967cc7b84e3bf086']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-83023-8c4b33240da44f9f8c380333159daa34']}   >Empresas</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mobilemenusection3}   >
<section custom-agua-item="layer" className={styles['layer-67623-bed06a5ebca946bca12834fdbe2caba2']}   >
<section custom-agua-item="layer" className={styles['layer-67724-d0148c9221634283afb57a70c67c7f3c']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-7324-627f14763a6942188d521a9dbf7186ac']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-83024-cb4a6060205d4c569fea6419f63d104b']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={styles['layer-677224-045b7662bb4447aeae25749a964dd6de']}   >
<Link to="/Nuestrahistoria"><section custom-agua-item="layer" className={styles['layer-73224-b3c005d446b1490e917d1e318c1f0bdd']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-830224-e9f8b630d54d45928a54155be4302141']}   >Nuestra Historia</p>
</section>
<section custom-agua-item="layer" className={styles['layer-6772223-2ed16ff5d1cd45e0957649fefa8aa327']}   >
<Link to="/Medios"><section custom-agua-item="layer" className={styles['layer-732223-23600649f4be4fd391234462defa70ad']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-8302223-c3ac0be917d64267b2e8e1d5fae5c60b']}   >Medios</p>
</section>
<section custom-agua-item="layer" className={styles['layer-67726-66c06c9997484ad0b6a558cfdb280f27']}   >
<Link to="/Blog"><section custom-agua-item="layer" className={styles['layer-7326-ac9abce8376e406eac533736a05d7b80']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-83026-1f90c51300764a9bba397f07a16b047e']}   >Blog</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mobilemenufooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-f96e651732ca43d988f53507d5c3ba46']}   >
<section custom-agua-item="layer" className={styles['layer-13022-38ea40ffda824d98bb3b63b6093559fd']}   >
<section custom-agua-item="layer" className={styles['layer-13522-400f6ef9ba9d4729ad56e5a90fb2176c']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-c782db84fa5b499da01c11cd4b357854']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-dafb687e6b2a467c9ad8341a5c30acd7']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-dff79cd472db4da89553dfbbe67b38cb']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-a012861108ca44339faa6757ea7ea577']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-109b9fdc619d44a0bd5fd8b6069f8eac']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-10a0c49498d647aea3bf47aa6274ec74']}   >
<p custom-agua-item="text" className={styles['text-62022-64640ef593f74fb0bc19fde0a98614b2']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-47668f920b1f452bac615f983b698ddd-orientation']} ${styles['layer-13222-47668f920b1f452bac615f983b698ddd']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-ca5586c286484e999a405e5b47328636']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-0b960ae34037410198126a59e05c2caa']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-1703a8a81c774560948f071c2a928a61']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-cc59666193054f4fa6e9c1a31b7d2bbd']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-24a3ca87730d403a8e4c4574d0ad3524']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-506282dbd21e4bef83d6418cfda7fccf']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-255eeecd373c4200a8264b4d024e628c']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-c2ef18bffe8349eda188393cad8d90e0']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-1e4251231fe340b1af9bbe962206103d-orientation']} ${styles['layer-13422-1e4251231fe340b1af9bbe962206103d']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-ea90304429ae48fea033f3633fd4c4b1']}   >
<section custom-agua-item="layer" className={styles['layer-14222-25d666c35fbb46c187a511854caffc08']}   >
<p custom-agua-item="text" className={styles['text-62522-ead26abe6f1645ce8092d35e02209a35']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-c27de5bcff9f48d5999e0e31aac8db11']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-7bed077b88214c90b1af6559b78a4a7f']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-376ad5380fc44a4dbe85f4e54fbf0e2a']}   >
<p custom-agua-item="text" className={styles['text-62722-9cfe3a2acc144b558704421fb4cbaa7b']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Mobilemenu;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_9222 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_10222 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_11222 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './beneficios.module.css';

/**
 * Beneficios component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Beneficios component.
 */
const Beneficios = () =>{
    
    document.title = "Vai | Beneficios";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Beneficios}>
                
<section custom-agua-item="layer" className={styles.beneficiosheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-c3ce103391c2406e8ab8b9d464182361']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-a683343b99954f108a84208538b5a27a']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-ae29be32c8cb45db9d693d859946e2b2']}   >
<section custom-agua-item="layer" className={styles['layer-2122-cd6af9c3feca4b93a7784b03e0189620']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-78318493f2c5497aa4d9283491d89ef6']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-47d49a8669a84be6a2ddce98ab183a7d']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-bc9018736e2f42bb8a6a188508568f4b']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-9483d8aa424448dbab8881ec77d321d9']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-988e9700f6394f5f95be11b3a643d988']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-263a63c0c2c84b24928fbdae86b56a30']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-60b290661df8451097baebeb7ac6d4e4']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-e7c0733b7d8648a28835e078cd4c6f35']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-cb12de68a9764ec7b381d7cef9462f7d']}   >
<section custom-agua-item="layer" className={styles['layer-2112-8df4b667b4d646488282c2dbbac8e769']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-0eb2124a6600419e997df53af67ab7a6']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-ec8d619daaef476c953ce3ce0ba301e5']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-ca6c30de349b4b988c80d243b5852f40']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-4e41ecf90b294e6abbfb0a9890652592']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-9351a58bfdf94f4bb78fbabba3191fef']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-294d63a796c546de8dbb7b8799d07ffd']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.beneficiosbody}   ></section>
<section custom-agua-item="layer" className={styles.beneficiosfooter}   >
<section custom-agua-item="layer" className={styles['layer-129222-542a721632b145b592f1133dec05616f']}   >
<section custom-agua-item="layer" className={styles['layer-130222-2c879a082aee481fad7adfae64738102']}   >
<section custom-agua-item="layer" className={styles['layer-135222-11a1e2ebf8364bef9b4985294f785646']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-9222-eea5cd5b95bc4d5fa1e8d9fdc4895a0c']}  alt="imageview-9222" src={ image_imageview_9222}  />
</section>
<section custom-agua-item="layer" className={styles['layer-136222-930b40c4fe7342208bffff1f3418d07b']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-10222-a332bb8fa7d44c40a25ca90177b12138']}  alt="imageview-10222" src={ image_imageview_10222}  />
</section>
<section custom-agua-item="layer" className={styles['layer-137222-aa8ca4b0027247debab631e43f960ad8']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-11222-7691216063c74266be5342f4050c164a']}  alt="imageview-11222" src={ image_imageview_11222}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-131222-fe803359046041419767b1bee29153de']}   >
<p custom-agua-item="text" className={styles['text-620222-01a5a012eed244fbba900d296cb50feb']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-133222-9a7fc6ec08fd4abf86dca264026ba173']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-434222-0f4bc8355e3740c1a92eac5558ea7184']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-624222-eba4d70d4ec94d86a9931e169a9e9dcf']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-132222-f082732b0c574a74a82d97c072124ba2-orientation']} ${styles['layer-132222-f082732b0c574a74a82d97c072124ba2']}`}   >
<section custom-agua-item="layer" className={styles['layer-138222-6059a39da3ab431ab95dff52126cc9d1']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-435222-b825d1cdbfe141998cf59d5675f8928a']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-621222-97817cb3f4394fbf91b2f1871123a127']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-139222-f027b6c35d5c4fc3993e43e8ce8faad2']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-548222-c208fe96170f4017a6cb74571da7eb7d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-622222-8a320abb041d4364bf00c4cd2741812a']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-140222-218924e81f654a7eb1169ed052e433bf']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-623222-6aa8f68ec3b84ec9a277b7c5f5e3899b']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-134222-e8d10ce5f98c4aaab467ad3a3c765e4d-orientation']} ${styles['layer-134222-e8d10ce5f98c4aaab467ad3a3c765e4d']}`}   >
<section custom-agua-item="layer" className={styles['layer-141222-d14dd03804e94ba8b0d4308f33de122c']}   >
<section custom-agua-item="layer" className={styles['layer-142222-de352f84f0eb453aab43c288bb5657d1']}   >
<p custom-agua-item="text" className={styles['text-625222-80fd962fe47d4cf7b7f607e61609e4e1']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-143222-5398f33626b343dd9f38184d7b6748f7']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-626222-75bb8729460f42a5b271cabb65880970']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-144222-8c0be8b8d4ca44088b88e09bdea631f6']}   >
<p custom-agua-item="text" className={styles['text-627222-243414299aee452cb99a8f015b05afa6']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Beneficios;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './nosotros.module.css';

/**
 * Nosotros component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Nosotros component.
 */
const Nosotros = () =>{
    
    document.title = "Vai | Nosotros";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Nosotros}>
                
<section custom-agua-item="layer" className={styles.nosotrosheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-30d813159be64e64af162dfe0821b9a5']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-672bcceaa9fc44a086eb292f7cc9837f']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-895490de1810489dac7cbeaa80b73cd6']}   >
<section custom-agua-item="layer" className={styles['layer-2122-a3124889541e496cbdf38e71d6d405d7']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-7314abc9e7c049708364f73fc54c7b6c']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-15e9ab14a51c4b059e0ef23de9d5f4e2']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-49fff4e179a445d1b55ca6feaa76cd00']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-e2b37669e7b0441e97eda60df72eeb7b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-15860b63a7694809bb7901dc1d5b6c22']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-6d92166e9c1f400ab6642c1d7de5e3b4']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-e31ea33f2da941e09984fe245f8773df']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-b74bbd919f254f15a3754255806cad39']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-3cff510392d343ad88c98b799b48c4fc']}   >
<section custom-agua-item="layer" className={styles['layer-2112-3d16960db2b44abb9ee6f9c3bdd10207']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-5c3b0290275646b4a4377a42e9e3bbc1']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-ff6518d3b2764343b7c9df916e930acc']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-09e302f9db534b40a16dc6ee9dd2211a']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-0906e97fb59e4ca89013859ba6ed5dad']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-42676076572242b6a9b3ab4fd0844caa']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-fe282a77b42d4ef6811c9c14dd349129']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.nosotrosbody}   >
<section custom-agua-item="layer" className={styles.nosotrossection1}   >
<section custom-agua-item="layer" className={styles['layer-3109-e0fff208b4614d6fb60398e3380fd68f']}   ></section>
<section custom-agua-item="layer" className={styles.layer2760}   >
<section custom-agua-item="layer" className={styles['layer-1356232-64693128632e4191bc016c169462aced']}   >
<Link to="/Nuestrahistoria"><section custom-agua-item="layer" className={styles['layer-2852-83d1e30ac40440dc818591425cc5199b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-943232-72e20d31bd0844348dd8258023a609d7']}   >Nuestra Historia</p>
<span custom-agua-item="icon" className={styles['icon-29232-515b7978d578487aa5eda66a952c3180']}  >navigate_next</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['homesection122-orientation']} ${styles['homesection122']}`}   >
<section custom-agua-item="layer" className={styles['layer-1522-6d83111e84ac4607bd69ca4a871a69eb']}   ></section>
<section custom-agua-item="layer" className={styles['layer-655-d0be601f12674bafaab7d278f56f681c']}   >
<section custom-agua-item="layer" className={styles['layer-3259-22c8d99efac94ef9993918ac43c53319']}   >
<section custom-agua-item="layer" className={styles['layer-13562322-2f90a8862c7f4f71bb872863a1189662']}   >
<Link to="/Medios"><section custom-agua-item="layer" className={styles['layer-2940-79db5e939a214007a84779bdb7d59f31']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-9432322-a879e79f6158438182cdb59d13bfdfe1']}   >Medios</p>
<span custom-agua-item="icon" className={styles['icon-292322-218e095b4f074b6f97b231276bd2caeb']}  >navigate_next</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.nosotrosection3}   >
<section custom-agua-item="layer" className={styles['layer-3407-9a2234453e974c2f8728ade1867070b2']}   ></section>
<section custom-agua-item="layer" className={styles['layer-3260-041d4f4ab65c4aa4957b8a7accd598d9']}   >
<section custom-agua-item="layer" className={styles['layer-13562323-dff6c17963ea44729dbabe589521b45c']}   >
<Link to="/Blog"><section custom-agua-item="layer" className={styles['layer-1957-469fbcf89b31444b8d2ab90fb8cd8aa9']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-9432323-dd76bb5d6bc54699a3788543fa2226cf']}   >Blog</p>
<span custom-agua-item="icon" className={styles['icon-292323-0a0956e9231540038e336a0ee3d132c7']}  >navigate_next</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homefooter2}   >
<section custom-agua-item="layer" className={styles['layer-12922-830f1700a2e2459c96b86672592a4e58']}   >
<section custom-agua-item="layer" className={styles['layer-13022-634e35086545418e8311957d3faa4d5c']}   >
<section custom-agua-item="layer" className={styles['layer-13522-0d677213e998448a958ba3fe68640227']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-119bfeb06fa24575ae45423728c973c4']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-9bfd23e0ac274888a53cf92dedf6974d']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-f790ba60c92542b9a3b40441d440c9c7']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-e7b42bf423744f97add0b23bc6502ac6']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-6d577f6e02f84c16bf75d7ed09859580']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-44d114acf4104d57ab97a9788a0af7ab']}   >
<p custom-agua-item="text" className={styles['text-62022-acc5f01d2ec6458a815df33a46ac4c17']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-0a65c7eb2ac44d26aeb1ae9651b18cb7']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-274cf9d1c35b4c4398746eb99981ec78']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-6270dde4d0d044329ad66b63dbada1e7']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-d77bd209caca439583343e79f979310e-orientation']} ${styles['layer-13222-d77bd209caca439583343e79f979310e']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-c3ee086f2daf44c7acf92210aec21ac1']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-c796f69a62d64d0da37125c138ef0d47']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-9d664f7751fb4de7a0d84a22ef88a955']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-5a03a9224b244fcfb99b7cede16d2c66']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-013b5ea7a8c14325a8a12889cd587177']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-5567caec0979466e88c2a791c34c8b08']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-89431f392ea34bfda0eb59a988618b4a']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-def79e1baaf842b1b8414c55bea96e35']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-e254f6af51c94905bc9a0eb29c7a69ab-orientation']} ${styles['layer-13422-e254f6af51c94905bc9a0eb29c7a69ab']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-f99c4313c994458788565fec260d746e']}   >
<section custom-agua-item="layer" className={styles['layer-14222-49a818dbb47e40ae9d06f52002e1c126']}   >
<p custom-agua-item="text" className={styles['text-62522-03763f847a77469392a5f38ae14a7ce0']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-1f36e78ea0e54a179dc321dbc1ed2b7e']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-8fd414fa99b647409e7127e19c4fd5b0']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-840b9b2044c24387aedfbbb35a858ee3']}   >
<p custom-agua-item="text" className={styles['text-62722-6e81c6a8689e4765afb932b658fee4bf']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Nosotros;
            
import React from 'react';
import { Routes, Route } from 'react-router';
import Home from '../pages/home/home';import Mobilemenu from '../pages/mobilemenu/mobilemenu';import Carpooling from '../pages/carpooling/carpooling';import Ventajas from '../pages/ventajas/ventajas';import Sostenibilidad from '../pages/sostenibilidad/sostenibilidad';import Ayuda from '../pages/ayuda/ayuda';import Empresas from '../pages/empresas/empresas';import Contactanos from '../pages/contactanos/contactanos';import Beneficios from '../pages/beneficios/beneficios';import Nosotros from '../pages/nosotros/nosotros';import Nuestrahistoria from '../pages/nuestrahistoria/nuestrahistoria';import Medios from '../pages/medios/medios';import Blog from '../pages/blog/blog';import Terminosycondiciones from '../pages/terminosycondiciones/terminosycondiciones';import Politicadeprivacidad from '../pages/politicadeprivacidad/politicadeprivacidad';import Blog1 from '../pages/blog1/blog1';

function RoutingApp() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
<Route path="/Home" element={<Home />} />
<Route path="/Mobilemenu" element={<Mobilemenu />} />
<Route path="/Carpooling" element={<Carpooling />} />
<Route path="/Ventajas" element={<Ventajas />} />
<Route path="/Sostenibilidad" element={<Sostenibilidad />} />
<Route path="/Ayuda" element={<Ayuda />} />
<Route path="/Empresas" element={<Empresas />} />
<Route path="/Contactanos" element={<Contactanos />} />
<Route path="/Beneficios" element={<Beneficios />} />
<Route path="/Nosotros" element={<Nosotros />} />
<Route path="/Nuestrahistoria" element={<Nuestrahistoria />} />
<Route path="/Medios" element={<Medios />} />
<Route path="/Blog" element={<Blog />} />
<Route path="/Terminosycondiciones" element={<Terminosycondiciones />} />
<Route path="/Politicadeprivacidad" element={<Politicadeprivacidad />} />
<Route path="/Blog1" element={<Blog1 />} />

        </Routes>
    );

}

export default RoutingApp;
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './ventajas.module.css';

/**
 * Ventajas component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Ventajas component.
 */
const Ventajas = () =>{
    
    document.title = "Vai | Ventajas";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Ventajas}>
                
<section custom-agua-item="layer" className={styles.ventajasheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-e30f82c0e89a4eeba1df4601f60152dd']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-4a394dc0448c4783a868c41743d785c9']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-f7d96f2d91bd4c40bd8b40fead011485']}   >
<section custom-agua-item="layer" className={styles['layer-2122-f3fc4847f1d748a285d0d9b353af465b']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-52cff5ec7c50417fbd36c7fc2468dfb8']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-606aed362c5d40c1ade33607612f1ef4']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-62a8b97867cc4850a12104ddb0dfda70']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-f4bf2a7daffa42bb990c089408cf263d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-244c64c5c96a4831ae6dc78e0f765935']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-51f18b6a816e41a493f6384ada839b18']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-e056189546a64e108372a7f0ba4edece']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-660880176d2c43869f5b1cce7315278c']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-97df8bce2f0c46a892523d5cd4ef5d9f']}   >
<section custom-agua-item="layer" className={styles['layer-2112-397d3cab6dba4a1b9dd0f0a1d9763924']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-12f6375251384dc1a068d7ae60b48a53']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-ec6711f07c3848319f759552a7be0555']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-7c0d41a636b14b3f89c4ab05dd18f400']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-177944da6e564a6bb3033de668e4d96e']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-6e5259ff88f8479380e033a7de1f135a']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-f366c8fc930147198cb31350f3139b26']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.ventajasbody}   >
<section custom-agua-item="layer" className={styles.ventajassection1}   >
<section custom-agua-item="layer" className={styles['layer-11292-8138a381b5b245758075b7cbf0eca8e7']}   >
<p custom-agua-item="text" className={styles['text-19522-0543f70c1a7748249cbae53ba3925cbc']}   >Cada recorrido es una oportunidad para...</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['ventajassection2-orientation']} ${styles['ventajassection2']}`}   >
<section custom-agua-item="layer" className={styles['layer-1068-acb28cb97c854bf6b23bb465ca315ac0']}   >
<section custom-agua-item="layer" className={styles['layer-1475-1dd6aea0a7ab41b68456a3e9acede4d5']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1069-2d6cdb754b884cd897a7f38209980a56']}   >
<section custom-agua-item="layer" className={styles['layer-1605-81566d239a0c4e0ebd518afbd7b6cf8c']}   >
<section custom-agua-item="layer" className={styles['layer-915222-a48fcdca7b5d4250abc651119c58f525']}   >
<p custom-agua-item="text" className={styles['text-869222-816c7c59fee3472bb3ed40c3eda23088']}   >Movilizarte de manera cómoda a una fracción de lo que te costaría hacerlo por medio de otras alternativas.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['ventajassection3-orientation']} ${styles['ventajassection3']}`}   >
<section custom-agua-item="layer" className={styles['layer-1991-b6030cce3996494aad14f95890902839']}   >
<section custom-agua-item="layer" className={styles['layer-14752-baaa2e47a439421ba1e7e6c84d4e8524']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1992-64458f413efc45c7aed411bd0775e348']}   >
<section custom-agua-item="layer" className={styles['layer-1999-049b08d3c13d40a28b3f30be270108f6']}   >
<section custom-agua-item="layer" className={styles['layer-9152222-1b4c07db55664eec8ea38cfac4e28c6e']}   >
<p custom-agua-item="text" className={styles['text-8692222-c4b860beb2a0419f8b95a5d79a7c843e']}   >Compartir carro y así reducir hasta la mitad tus emisiones de huella de carbono, lo que resulta en una mejora en la calidad del aire.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['ventajassection4-orientation']} ${styles['ventajassection4']}`}   >
<section custom-agua-item="layer" className={styles['layer-1993-c6758128567b443aa36f8f6121493127']}   >
<section custom-agua-item="layer" className={styles['layer-147532-af52ffd094e2418d9afdd83d469b6810']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1994-81904eb49a174a12923fa34e706e3b4a']}   >
<section custom-agua-item="layer" className={styles['layer-1998-73ffa40fccb94d319800d40b0044173a']}   >
<section custom-agua-item="layer" className={styles['layer-9152223-0c5050637e5040b58d7709ce0841b84c']}   >
<p custom-agua-item="text" className={styles['text-8692223-69bb0c2a491045d186b5b2a7ad955ab8']}   >Maximizar la capacidad de viajeros por carro, reduciendo el número de vehículos transitando las calles. ¡Más personas en los carros, menos carros circulando!</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['ventajassection5-orientation']} ${styles['ventajassection5']}`}   >
<section custom-agua-item="layer" className={styles['layer-1995-df0b6f3a7f7a4c38b92900969759b81e']}   >
<section custom-agua-item="layer" className={styles['layer-14753-778069e9b3e44804adf292884cf49170']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1996-b088048870214a9d9f5e18003c263204']}   >
<section custom-agua-item="layer" className={styles['layer-1997-0d9bfc9c387240af988558ad0b662c14']}   >
<section custom-agua-item="layer" className={styles['layer-9152224-6ac8cc43026c4c869b1916a1f32fea2b']}   >
<p custom-agua-item="text" className={styles['text-8692224-5546b4316d4c438096f0b133ae02424a']}   >En Vai garantizamos la seguridad de nuestros usuarios por medio de filtros de seguridad.</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.ventajasfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-43f991b6c9e147ce9f45857df73732e7']}   >
<section custom-agua-item="layer" className={styles['layer-13022-524a61ebfb8047ee95151ab9562b3f34']}   >
<section custom-agua-item="layer" className={styles['layer-13522-a5e33cad519446bb889e27cc27e9ebf6']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-7d01ca1340884ecc9470a9f5261524fa']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-10caaf3144e842d29556ac92d1beb62b']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-ed32a08479d94d66b1bcb9a4d5a7052e']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-17bcf48345a34c27b0a05a942abaf674']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-818b63761ac743d4ac90bcba5e69dba6']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-51b22ac19f1f4a7781c8dfdedf91efe5']}   >
<p custom-agua-item="text" className={styles['text-62022-cee27418bb0147eea0dc12b1419e808e']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-14b6e3bee1cb4a64ba84a8cafdb3dbc2']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-9ff8234ed0684fcb97fbb7accc7a9c54']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-2f5a131bad234e1db44424f74d26fea1']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-771731edb79e4fb3b3d6f37b58f1f995-orientation']} ${styles['layer-13222-771731edb79e4fb3b3d6f37b58f1f995']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-916ae24427c84b6c9bae575fc4249242']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-abc9079ef31b4287a52ab1b96cde8add']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-640b3cf0417b441ab89a699de396ad59']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-e8bf61190f5b48e38b44405e32b78268']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-72155b43e3944a1498229cdf805f44e5']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-cbc822de80ac4096bf433763ad424fd7']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-f962da3ebfb246d993e2cde830a9f1af']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-5186602ab93544ec9e24d067cef36475']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-10660753806d494187f2a3af0783981c-orientation']} ${styles['layer-13422-10660753806d494187f2a3af0783981c']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-a78664e452b14795b8efa77346845518']}   >
<section custom-agua-item="layer" className={styles['layer-14222-c58c3985b05f40a4a5c58f58220656cd']}   >
<p custom-agua-item="text" className={styles['text-62522-73bc0088bb3a4fa8ab81caf660ae1f4f']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-f8c1dea2e7b94b239a0fc7f47b42d3ae']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-77cc1f1e103b47fe871f9b054c325baa']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-3fbcab66118d4c0ab9e30242a1953a5e']}   >
<p custom-agua-item="text" className={styles['text-62722-ca82b082a20c4a3cb92f1df1b59d9452']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Ventajas;
            
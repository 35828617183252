import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './nuestrahistoria.module.css';

/**
 * Nuestrahistoria component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Nuestrahistoria component.
 */
const Nuestrahistoria = () =>{
    
    document.title = "Vai | Nuestra Historia";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Nuestrahistoria}>
                
<section custom-agua-item="layer" className={styles.nuestrahistoriaheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-11e49a0ef313492294449729792ca2da']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-c74fb277500e40aab3cfa2afff427649']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-5f90d73f2403461c86a3d57253c62ce6']}   >
<section custom-agua-item="layer" className={styles['layer-2122-7be3dec248b14afca3d2b703d17736a2']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-2608708cb337477ea9f55dc7958e8921']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-2ec876f6bc3e4316ae23f1c393a8e709']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-4e816eddba984767b8ba99d5460d7c03']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-d357c724d2db496daced6d7b10d0d79e']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-8d376ed617d84f9b9027333e647de4b9']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-ad12dd322331424ea8fbcf1a589bc0ab']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-970ca2f61fb94d4ea89ed9add8e36054']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-12a854c4fe5b48cabf992c5b2d41de01']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-36497612fcc64e21964c388b567d7aba']}   >
<section custom-agua-item="layer" className={styles['layer-2112-27908c6efbcc4f44955c7429245ba34c']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-5c3f452c13314e3b8fd72214638fbddf']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-1e99932aec8c427c9b164a1fc9f7b70a']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-c2a57a0e8f7c45e68d4925d2978396f4']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-01806d2e72e6484a81bca728de656622']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-34120b49dd3247afa39a4e851e750751']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-8e992a10e1a947218d57a45c54ba697f']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['nuestrahistoriabody-orientation']} ${styles['nuestrahistoriabody']}`}   >
<section custom-agua-item="layer" className={styles['layer-3201-350b8534a7714d908545d6564943da12']}   >
<section custom-agua-item="layer" className={styles['layer-3203-eafd247422b54a2ba44da02d521187c4']}   >
<p custom-agua-item="text" className={styles['text-2615-4fcea9f8202c46f9970273660eb39f48']}   >Vai nació de una visión simple pero poderosa: transformar la movilidad urbana en América Latina.</p>
<p custom-agua-item="text" className={styles['text-2616-7f41a6d699004ec0917c53668bf0303b']}   > Fundada en Bogotá por dos apasionados por el transporte y la innovación, Andrés y Roberto, Vai se lanzó al mercado en 2023 con la ambición de ofrecer una solución de carpooling segura, asequible y sostenible. Nuestra aplicación conecta a conductores y pasajeros que comparten destinos similares, optimizando rutas mediante algoritmos avanzados y un enfoque único en la seguridad y la comunidad.</p>
<p custom-agua-item="text" className={styles['text-2617-bdd7f6a3c1f14d43887b15190bf22eca']}   >Desde su inicio en universidades, Vai ha crecido rápidamente, alcanzando miles de usuarios y redefiniendo el concepto de viajes compartidos. Con un compromiso firme hacia la seguridad, la inclusión y la sostenibilidad ambiental, Vai no es solo una aplicación de movilidad; es un movimiento hacia un futuro más conectado, accesible y responsable. </p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-3202-c2a221069c9248959f0acf69251d066c']}   ></section>
</section>
<section custom-agua-item="layer" className={styles.nuestrahistoriafooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-bc55eb60ad404921a00ac675d7ea11c7']}   >
<section custom-agua-item="layer" className={styles['layer-13022-b523762e78bf4ff08abcb567ddba22cb']}   >
<section custom-agua-item="layer" className={styles['layer-13522-e0f44ec82b7e44aea57eec0a12912b8a']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-49ff152c3d8a497aab7a5e18b7c957e4']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-23258e588fbb4bafacfed45cc7ef0be8']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-4523b4a32e2d4c92a7c9663cf571d804']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-a71df9000a4c42659a4137a43a1a24c5']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-a21bafd1868a4215a40dbc0c9c3f6a8b']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-560deb77f826443a97eeb5b9c0bf8210']}   >
<p custom-agua-item="text" className={styles['text-62022-3599c6c7415d415d819fdb71895aa8c2']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-20bb46a7f00145bda9fd9208cc2d64e3']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-26b9138404524bada9f48479b8dafe9f']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-01746e2a8e0145bf86e8f6c3d84a7c63']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-a2c6102c0a834093a1ea06f30a9f26d3-orientation']} ${styles['layer-13222-a2c6102c0a834093a1ea06f30a9f26d3']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-0868a4a8163047a2946fbc49d19104af']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-2f5593e3430f41cb90f3668ae7fb2c20']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-9a15f4cb0e44426e91b1582c0a6552d4']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-dc75171684ef468fb28b04f66bd0efef']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-d332514b4deb4903a61b3b4f37fec7ef']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-43b430dfa1e3461e850e10677978a63d']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-858b1dbb8b854ace8fd6eef9d9ffda42']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-e32cb3ab05c44b3aad4e1c9237dc845a']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-29eec938b55945a3a11762142974f9c5-orientation']} ${styles['layer-13422-29eec938b55945a3a11762142974f9c5']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-ef66450579584a4a83c33c507fc393d1']}   >
<section custom-agua-item="layer" className={styles['layer-14222-021addcd3dc944d5b909c8c844ce8c9b']}   >
<p custom-agua-item="text" className={styles['text-62522-2271ccdebf8f4c95a78bdb31437ac541']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-f887881e2b214fcd89183518c637d796']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-2ac21b3abc644f9e823f46bb1e4ed05a']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-d2e1c2ce8f7949818feee63f5789c413']}   >
<p custom-agua-item="text" className={styles['text-62722-f0285898d99642dd8c46a0595ceb6baf']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Nuestrahistoria;
            
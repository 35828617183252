import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './empresas.module.css';

/**
 * Empresas component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Empresas component.
 */
const Empresas = () =>{
    
    document.title = "Vai | Empresas";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Empresas}>
                
<section custom-agua-item="layer" className={styles.empresasheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-353617d0109445588f4070290b61dbb5']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-bd52b37804514451a4456a3638b40e54']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-da2875dc5f0147c6b655cb57badc2b6d']}   >
<section custom-agua-item="layer" className={styles['layer-2122-663e5fb773264d47ae5e1a68f5e89bf4']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-20c0c04741c94c9098f98c8f98df95f2']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-f5f41ab155a5405b94c2a97f4f5fc5c8']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-911e2203a4004edbb14cb095667435aa']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-6620d88d68c34fcaa11b0788e06ad981']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-29e268eeff6d40d7970afb5245ba1931']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-305b2de21e7144b49577f4a68ba26593']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-6ecd96fd7c4546648fdfb33bfc8d9190']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-0adaf0bcce4e48dc888f5ee9a30ab675']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-a56391f2cab54276aa8b74770b83d479']}   >
<section custom-agua-item="layer" className={styles['layer-2112-0bd4d82462bc42bdac6f24c45bb10cc4']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-5e76f12882034dc1b49829308737ef5b']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-7f16d9358f6b42e385e7c28545d0bb88']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-0248f82b799d41589969aadeb1b801f9']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-28b706b4ca7a48bfbcaf078c57b5c8ed']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-0d7776725eae49d3b0236d6dad31174b']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-34c952461e424f8c9cf3cebd568b26cb']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.empresasbody}   >
<section custom-agua-item="layer" className={styles.homesection12}   >
<section custom-agua-item="layer" className={styles['layer-152-4234826f84834b45a373eb9a7f0ac37a']}   >
<section custom-agua-item="layer" className={styles['layer-1778-e6eaaabaa79745b3ab2cbbf4e0135787']}   >
<section custom-agua-item="layer" className={styles['layer-1779-066292bcef7a4c6f8e56121fa5503049']}   >
<p custom-agua-item="text" className={styles['text-1198-68d7738008ff449081b142d652281d9e']}   >Revolucionamos la movilidad, potenciamos la productividad...</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-162-3d88cf2a83df4534acf0d26f513bf3db']}   ></section>
</section>
<section custom-agua-item="layer" className={`${styles['carpoolingsection12-orientation']} ${styles['carpoolingsection12']}`}   >
<section custom-agua-item="layer" className={styles['layer-12822-89269ed8d5dc4616ae4a4320e03c2f81']}   >
<section custom-agua-item="layer" className={styles['layer-17782-4def62e1427441d7b8c4a25850b1ad8c']}   >
<section custom-agua-item="layer" className={styles['layer-17792-29aab7c1eddc41f9b8c35652d2a2b174']}   >
<p custom-agua-item="text" className={styles['text-1523-ee99b821523f4ff4a22ab207ac5fdf4c']}   >¿En qué beneficia esto a mi empresa?</p>
<section custom-agua-item="layer" className={styles['layer-1921-34bb0d43a99f41cda6c3661d638140a6']}   >
<p custom-agua-item="text" className={styles['text-1244-968c8ea0026944b084a4eedef9c1b43c']}   >-Promover la presencialidad al facilitar la movilización.</p>
<p custom-agua-item="text" className={styles['text-1245-f90e56e5e092497e97190a9474ef8c10']}   >-Disminuye la inseguridad y el riesgo de los empleados y sus herramientas de trabajo.</p>
<p custom-agua-item="text" className={styles['text-1246-cd72a654ea1f4b0ab4eeff558fde788c']}   >-Cumplimiento de objetivos de sostenibilidad.</p>
<p custom-agua-item="text" className={styles['text-1247-0f542bb8c52945e9b69b8ebe84d335f1']}   >-Fomenta la cultura empresarial por fuera del horario laboral.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1502-9d1f2ff0c72c4ef6a158485e385002a1']}   >
<section custom-agua-item="layer" className={styles['layer-5562-af25fc18ba6d4266923ab74b566b04af']}   onClick={() => openInNewTab("https://wa.me/573128339854")}></section>
<p custom-agua-item="text" className={styles['text-6312-8bce366e228345ed9ffdba62e3ab8005']}   > Contáctanos</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-12832-1067accc5d934f67ab50a7ea4602ef96']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1323-58da731a20094ee598ec7bde742894e6']}   >
<section custom-agua-item="layer" className={styles['layer-1325-07c1f6dfaee44016afcece838a1bb910']}   >
<section custom-agua-item="layer" className={styles['layer-2484-cd7835083a7244de88c227136f89e913']}   >
<section custom-agua-item="layer" className={styles['layer-2372-3e5398a0ffcb477282ee8dda96b66fe3']}   >
<p custom-agua-item="text" className={styles['text-1412-573ac11e92744724afe7ae4bd58d46b0']}   >¿Cuál es el beneficio para mis empleados?</p>
<section custom-agua-item="layer" className={styles['layer-2373-fa58d8070afb42bf98209b35d98f311c']}   >
<p custom-agua-item="text" className={styles['text-1413-ee836f8cbaf34203a8bd5410d2978aca']}   >-Movilizarse de manera cómoda y segura.</p>
<p custom-agua-item="text" className={styles['text-1414-deef04e763bf450797a8102e74ac8b09']}   >-Dividir los gastos de transporte con compañeros de trabajo.</p>
<p custom-agua-item="text" className={styles['text-1415-3c52b288e87f46709a1b061484360ff8']}   >-Asegurar puesto de parqueadero.</p>
<p custom-agua-item="text" className={styles['text-1416-dbe31e7147974926b42f3dc1adba38c9']}   >-Es la oportunidad perfecta para conocer personas e interactuar con tu comunidad</p>
</section>
<section custom-agua-item="layer" className={styles.layer15022}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<section custom-agua-item="layer" className={styles['layer-5592-4858a8410d5f47b2b53367ba351219fa']}   onClick={() => openInNewTab("https://wa.me/573128339854")}></section>
<p custom-agua-item="text" className={styles['text-63122-701dc11ae2e84712af1e1b4e39e25b16']}   >Contáctanos</p>
</section>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homefooter2}   >
<section custom-agua-item="layer" className={styles['layer-12922-790367b101f74979aa34aef855ff3f13']}   >
<section custom-agua-item="layer" className={styles['layer-13022-ebaaae1111c64606925f6c4759071ed1']}   >
<section custom-agua-item="layer" className={styles['layer-13522-8fe60f96a8524d38898b1b87a0eaf7ae']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-bcecdba5182b49e18f6a38b7a925dd70']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-da8246a0058b42a4a12412f80b2efcbe']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-b9877656c2f3428d94011eb9c2ed3905']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-f3d07d41c8bb41d8ba9b975469dd935c']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-433fa10bf57049e39b6f6bac82a146a8']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-a8a1edc2b767477ba17843f0bcf2acf8']}   >
<p custom-agua-item="text" className={styles['text-62022-cb9e376c3e4e4b14887046d33f138ddb']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-8f9c27cdf7d2454693394fb6b65256af']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-b52bc4a3e0f1472da6019ae360b31b0d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-4d42a6d439dc4eaf904efd7e3b410936']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-898589019b3542e5ac421c74565e9c9d-orientation']} ${styles['layer-13222-898589019b3542e5ac421c74565e9c9d']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-b102b08289e2441e854fdb20ccc49215']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-cb5498ba598e4f8eb9e97d5952c9adc8']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-19b726998dec4d7ea5e2ce1d59722cd1']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-9ebf0d94965a4c19b79a39c5581b5920']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-c589c6b7fd864810980fc885a5cc7c04']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-05a407adab904ea4b1f0e04934badf80']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-f0fc8da0a6904acf9dc88c50e0a69223']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-ce5bab4036ed4fd5a3a3e913f6b26637']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-a1e053aa70d449bd93cc4687de9296fb-orientation']} ${styles['layer-13422-a1e053aa70d449bd93cc4687de9296fb']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-e0cbd4d81a1c4dc0aad4b124e34a3ecf']}   >
<section custom-agua-item="layer" className={styles['layer-14222-6e4eaafa8dd446d381ee9f35a911be1e']}   >
<p custom-agua-item="text" className={styles['text-62522-c1c1ed76255c4be2b094bf132004aca9']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-8d784686412b420abe9dc12a8dfc981f']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-910802ddcdb14bad8633a16b60dea61b']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-9271a9cc9b3a4067a29dae24d3c9d1e3']}   >
<p custom-agua-item="text" className={styles['text-62722-9ea46a8beb32419482b1515678ff2304']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Empresas;
            
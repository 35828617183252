import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './sostenibilidad.module.css';

/**
 * Sostenibilidad component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Sostenibilidad component.
 */
const Sostenibilidad = () =>{
    
    document.title = "Vai | Sostenibilidad";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Sostenibilidad}>
                
<section custom-agua-item="layer" className={styles.sostenibilidadheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-0dbe7366f83844119d93a4847717329e']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-5c44aa22d095424f862b60dc7027d38c']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-bb4fcf39d4d642e5b72c29b46a74507e']}   >
<section custom-agua-item="layer" className={styles['layer-2122-7be6cd6ff65946a68a9217361f075bff']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-bbe01634d2424f5f8b1f465a38762e43']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-0e3deebe5e5846bb90e70578392d6f9f']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-dd227d60d1fd476c930e80d5b58289a0']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-faba064377dc4ea69efee53e84b3dce3']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-88c4de8fb7cb479890c43bceb3021c32']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-2c1fb6c55b2d4139adacf4f36341ba78']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-afd864e67ba640a28e268a6c754e221e']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-1f95761701d04dd488a4b943f38e6b64']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-cd5126d821664e0fb1cc4a7873c9d458']}   >
<section custom-agua-item="layer" className={styles['layer-2112-c07aba61c8bb4da8a8b7b0f8b6e12cde']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-5ce78997418f4ac2a2d7399079063fe9']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-ff4cb463fc104f33b52ad702cbb519c1']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-2a5a309e037b45e5b88d4172f8ed67b3']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-d3cc6cb1977d4ca59ede88a39792daed']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-7c903e18fce444ff8ac5f59859eac0dc']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-246419fa4ab44441b8af4b58eb907c6e']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.sostenibilidadbody}   >
<section custom-agua-item="layer" className={styles.sostenibilidadsection1}   >
<section custom-agua-item="layer" className={styles['layer-112922-13d8d2d621c641899daeb73583a48498']}   >
<p custom-agua-item="text" className={styles['text-195222-587a1eeebf194fc09c002c7e2fe30408']}   >Estamos comprometidos con un presente y futuro sostenible, equitativo e incluyente...</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['sostenibilidadsection2-orientation']} ${styles['sostenibilidadsection2']}`}   >
<section custom-agua-item="layer" className={`${styles['layer-98022-39e7918a20d340349ddc74e8e5ce8ce7-orientation']} ${styles['layer-98022-39e7918a20d340349ddc74e8e5ce8ce7']}`}   >
<section custom-agua-item="layer" className={styles['layer-1124-faa298271a894bef8ec6d9f4f3e8a574']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-98122-7f6eadfa699a4fadaf32a5c9f84380a1']}   >
<section custom-agua-item="layer" className={styles['layer-98222-1ca4d83208f04fb3bc82406c35492d1d']}   >
<section custom-agua-item="layer" className={styles['layer-91522-fe708fde6601463facbb5f46b9d2a6a2']}   >
<p custom-agua-item="text" className={styles['text-86922-ce5d19361f3e49fbbe658c19a548fc50']}   >Estamos comprometidos para apoyar el desarrollo sostenible y el progreso tecnológico.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['sostenibilidadsection3-orientation']} ${styles['sostenibilidadsection3']}`}   >
<section custom-agua-item="layer" className={styles['layer-980222-81a0bf9915604379895abdd40505d7bf']}   >
<section custom-agua-item="layer" className={styles['layer-11242-5ece91b5ffe040bcaad7d3e40e305575']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-981222-feeb774ee52c47a48c4f9d29bb0e4b53']}   >
<section custom-agua-item="layer" className={styles['layer-982222-742f900f0c684e21a8eee755ffde4d4b']}   >
<section custom-agua-item="layer" className={styles['layer-9152222-937feb18769e4c78ac912e49639b9d63']}   >
<p custom-agua-item="text" className={styles['text-8692222-76fafe231037454497f37f61d2fa3651']}   >Proponemos reducir las desigualdades basadas en los ingresos, clase, discapacidad y cargo.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['sostenibilidadsection4-orientation']} ${styles['sostenibilidadsection4']}`}   >
<section custom-agua-item="layer" className={styles['layer-980322-9ac2073d2b5f447bad8f6fe6d1ea3af0']}   >
<section custom-agua-item="layer" className={styles['layer-11243-4dbdda06310c414ea49402474b901312']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-981322-04c405e27b484ca2915775a04be34d16']}   >
<section custom-agua-item="layer" className={styles['layer-982322-b572b79488c04e6990a2b5025144d321']}   >
<section custom-agua-item="layer" className={styles['layer-915222-2e04912e266840f793fd9b5bdd6e0133']}   >
<p custom-agua-item="text" className={styles['text-869222-21ceb45a01bf4ac9baa0ac5caecaf4d4']}   >Queremos lograr que los ciudadanos formen parte de la dinámica productiva sin afectar al medio ambiente.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['sostenibilidadsection5-orientation']} ${styles['sostenibilidadsection5']}`}   >
<section custom-agua-item="layer" className={styles['layer-9802222-c5f244ed5d2644e099b39f83b532db0b']}   >
<section custom-agua-item="layer" className={styles['layer-11244-9a7148f618af4cfe8181a7fb35033334']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-9812222-692cb6f3bd014b30bc37cee45b067904']}   >
<section custom-agua-item="layer" className={styles['layer-9822222-d83290e1aefa49c2bd4b5a6b7b93b9e2']}   >
<section custom-agua-item="layer" className={styles['layer-9152422-02db57d7263a408c8c01cc089746573f']}   >
<p custom-agua-item="text" className={styles['text-8692422-f4473f186b814f618124fd7f48ddb590']}   >Queremos proponer a los ciudadanos actuar de una forma consciente a la hora de movilizarse y optar por una opción sostenible siempre que sea posible.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['sostenibilidadsection6-orientation']} ${styles['sostenibilidadsection6']}`}   >
<section custom-agua-item="layer" className={styles['layer-980223-87ebd1efd34240838570bb3f9820a043']}   >
<section custom-agua-item="layer" className={styles['layer-11245-779c9dd659fd43bd895c8cfc95001287']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-981223-463b33ed26c9434d8f4abd8c4c13e8bf']}   >
<section custom-agua-item="layer" className={styles['layer-982223-9e2141b5535d44c59bd8ea7a6ab270a3']}   >
<section custom-agua-item="layer" className={styles['layer-915223-dee861e785ee4465a6245338cc09da79']}   >
<p custom-agua-item="text" className={styles['text-869223-f0ab4da7570d4bf6b12ab25a9488918e']}   >Adoptamos medidas para combatir el cambio climático y sus efectos. ¿Por qué no aprovechar tu combustible para trasnportar a otros?</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.sostenibilidadfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-0b036f6944e84ccc9d9e50600ec89876']}   >
<section custom-agua-item="layer" className={styles['layer-13022-7039b5913c384ddf8ee1aee5b532ef54']}   >
<section custom-agua-item="layer" className={styles['layer-13522-aa67d29e90fa436bac53513c9f38c302']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-5bfb9f5fb7884bf59c36584026c7fe81']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-0fb3bfd33aae46a2861501d9bf67908f']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-1beb248ee3b948c185ed87ede88baf39']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-f6bc54f7aa6c456486801ed716be9f1e']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-5441d74573cf4de485b73a47a3381cca']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-407ed3447dd34de1aca2aad40cbac723']}   >
<p custom-agua-item="text" className={styles['text-62022-626f0955675e4379975f559fcecbb89a']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-0b23894283c64577896a84ae3d808fa0']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-0c2d5dc4dcea41ecb778e7834ab62f61']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-9413f2cc919a4faba11ae1037fc1f137']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-0c2ec6d2419c4d1883925b8e4b2af2eb-orientation']} ${styles['layer-13222-0c2ec6d2419c4d1883925b8e4b2af2eb']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-412364be2bca49e2937283216987a437']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-1107110b748d41bdb67ca0dbe86d0c42']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-cd224c81319f49d19f7c79353cd424e6']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-88dafa23b344452584535d7402dedfec']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-0fae8ade06a64f22a394c03dc7dba8a3']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-6a9b3c24b88545f19d46f7831d18bbbf']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-e0141d269df84b819606987f01902c39']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-8384fc30dcf4467eb0d6ecd2b13bda5b']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-a6b71ba215b94740bb17f5d29f2e19b6-orientation']} ${styles['layer-13422-a6b71ba215b94740bb17f5d29f2e19b6']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-8bdf909e2b4a40348485367e5915db19']}   >
<section custom-agua-item="layer" className={styles['layer-14222-5ae2ee6b84174f0e8b73b22397d4bf9e']}   >
<p custom-agua-item="text" className={styles['text-62522-9b41656af63a4b768b56f9f5ec27c294']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-dfa34f373db1441b897958f3ecac31f5']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-c21125774c8c4a81a5ba35d57a79b2f9']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-a3161eb3170b455e98e63379d5e9cc4a']}   >
<p custom-agua-item="text" className={styles['text-62722-fe653cd139ba4afc9b4b2eed9492832d']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Sostenibilidad;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './blog1.module.css';

/**
 * Blog1 component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Blog1 component.
 */
const Blog1 = () =>{
    
    document.title = "Vai | Blog No. 1";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Blog1}>
                
<section custom-agua-item="layer" className={styles.blog1header}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-107e07db4906442d9f5039c38f28138e']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-81d223d80ff542c4acab4204cfc6fbab']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-b881d1b09c084143ad1cce4a14683a10']}   >
<section custom-agua-item="layer" className={styles['layer-2122-69e1dd62864f41b88f338773b3809c38']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-a57bb26982cb431092bdf99b8ebb8f35']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-66044ac0d87f4fb3878f59bcfb692382']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-f0326a4cefb8428290e9ebe2c7482054']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-06aba220fe5d401ab672f91a1a88cc4d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-f286ed3dba134f238e2b078e54e1bd87']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-5f8382d7bcf943de86212014995644bb']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-7435c93ea8af49fbb5a9629bd7c034b1']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-460ce12d536f4a8c9efdf779c859a6e3']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-d555eb3ccb8c44e6bd022c6b309ad0d9']}   >
<section custom-agua-item="layer" className={styles['layer-2112-2417bf881a50489fb44882ca3019e7e0']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-b995799a1938430c8ef115a9802c4ff8']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-69f71d7e1be74b1a835c06a81ce0e956']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-5427eaf43898400a9a26620b7d81115d']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-f2033c6bef504880b7a3ef1b132919bd']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-4a2feb93f87c4196be755c7a9176f550']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-204b97bc2c4947e1b76b57f29a005256']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2557-ac6caa5925654fe2b19e6a9d48808be6']}   >
<section custom-agua-item="layer" className={styles['layer-2556-b57cc839160a48b8857b8ed032ce9cf7']}   >
<p custom-agua-item="text" className={styles['text-2527-1e1b4a80d9eb4f62b6b6f55edb49c4f2']}   >¿Qué es el carpooling y cómo funciona en Bogotá?</p>
<p custom-agua-item="text" className={styles['text-2528-4ae2d76cf2324507920b09c9818bc8e7']}   >Actualmente existen varias alternativas para realizar esta práctica entre las cuales está Vai, la aplicación de carpooling para comunidades. Con nosotros, puedes encontrar conductores o pasajeros cerca ti tu viaje de una manera sencilla. asegura tener viajes cómodos, seguros a precios asequibles, siendo además una forma divertida y diferente de movilizarte por Bogotá.</p>
<p custom-agua-item="text" className={styles['text-2529-c30640b56d714d34988a2315e1b145d3']}   >Al reducir el número de vehículos en las calles, también ayuda a disminuir el tráfico, el tiempo de viaje en un 30% en promedio; las emisiones de gases, aproximadamente 3 toneladas de dióxido de carbono (CO2) por carro al año, como también le permite a las personas ahorrar dinero.</p>
<p custom-agua-item="text" className={styles['text-2530-f73d953e41c74b538e9b110db5eeef94']}   >Al compartir un vehículo, pues los usuarios tienen flexibilidad en términos de horarios y rutas, lo que puede hacer que el viaje sea más favorable y adecuado a sus necesidades; pues te desplazas en comunidad respaldada por tu correo institucional o empresarial con el app. Esta actividad brinda una experiencia social positiva, ya que permite conocer a otras personas de tu comunidad, lo que vuelve el recorrido agradable y cómodo.</p>
<p custom-agua-item="text" className={styles['text-2531-de77c80d42b743aba230e8f673393691']}   >Vai es entonces una excelente oportunidad para conocer gente nueva, hacer amigos y socializar mientras se viaja hacia un mismo destino. Ofrecemos una experiencia de usuario sencilla y accesible para todos, lo que te permite disfrutar y conocer de primera mano los beneficios de la movilidad urbana sostenible. Somos una alternativa práctica, económica y socialmente responsable que debe ser tenida en cuenta por cualquier persona que busque una forma más eficiente de moverse por la ciudad.</p>
<p custom-agua-item="text" className={styles['text-2532-a5634960d4614067b6b27292ba4040f7']}   >¡Llegamos para transformar la cultura de la movilidad en Colombia y Latinoamérica!</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.blog1footer2}   >
<section custom-agua-item="layer" className={styles['layer-12922-d09a506c8b51414e9af1e96aee2c5372']}   >
<section custom-agua-item="layer" className={styles['layer-13022-b596368e166c49c4ba9ae69ba2851f06']}   >
<section custom-agua-item="layer" className={styles['layer-13522-021406c7d48f42d18e99f75493afde29']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-4f40ef1260754dd290a724e9c7c0c71c']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-518bada5abeb4d7ca97ccaaeedcdb9d7']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-867fb95988104550862a90bc0bc7583e']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-229ae25d09994b1d8c0433ec23856bed']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-0a3abc114b154eee8f2796b991fd4967']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-55900de8fd7f4466b98f04adac69c5f0']}   >
<p custom-agua-item="text" className={styles['text-62022-9f5f0ff13aed4d838b0188b44a383548']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-538cccfa80c148e78396786a730e2043']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-f6a6f9583b49496f956da040db60c1a6']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-78949f65f6824c1182223291402ded66']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-0e63ec5120954cd3b5037aac4dd83cae-orientation']} ${styles['layer-13222-0e63ec5120954cd3b5037aac4dd83cae']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-d555ac82a6964479962426b376ffca75']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-7c5d3d3481454341a4d335043eb9dd04']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-eccddc57f05c4aa2aa41c0cf099a70a0']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-0f3f181723e04a80afd44bc07297cd97']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-091dd7f5cc4347b594fbc8557696c391']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-48445ae491a643ddb7afcf53261a0826']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-d9af07f65ebb43b6b43f68559a6e4c1f']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-09b7bbe63fec4321b6a24c3ec103980b']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-d370d9fb7041437cbe816527f3949607-orientation']} ${styles['layer-13422-d370d9fb7041437cbe816527f3949607']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-d191ad61481f466f87b39812fae6f3f4']}   >
<section custom-agua-item="layer" className={styles['layer-14222-dbcbc62cf16545b0ad9d3e8fcb6af907']}   >
<p custom-agua-item="text" className={styles['text-62522-7c8d58b1fad941038a71fa52d3dc2536']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-cd6debd1fdf140938a591f304e2d0824']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-bbac4075246a4a309033846b06a3990a']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-6340c4081e024fce9f7b7c6387f9e692']}   >
<p custom-agua-item="text" className={styles['text-62722-6ab69242f8f94d96b8130ed65ea97397']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Blog1;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_92 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_102 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_112 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './politicadeprivacidad.module.css';

/**
 * Politicadeprivacidad component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Politicadeprivacidad component.
 */
const Politicadeprivacidad = () =>{
    
    document.title = "Vai | Política de Privacidad";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Politicadeprivacidad}>
                
<section custom-agua-item="layer" className={styles.homeheader2}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-da4a6ca8f5214c738e241b4bcd9ad2d1']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-eec16e364e0243a88587c83ff9a22705']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-98a41f98993640aab7fab9a1aca69bd6']}   >
<section custom-agua-item="layer" className={styles['layer-2122-05b8720c2cc445c9a9e9b7d919ecd9a9']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-091a3d4420bb4f4bb8c1dae89a9c009b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-c12432f5f54544ffab2c1ebb19a01d92']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-0d5a328244c348f184a4ce7554188be2']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-6de887e6315140b69963169954aec774']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-be3e2ca162f647b385cf3c7ee47a3c2e']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-060154aec12541fda2d2320c43340bb8']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-8f45c0ac470e4c648bbb1e8eb04fe522']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-1af6ec8875594afe9d64077ac2188176']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-be57f34a61a74528a0ca8e02ee7e5a6c']}   >
<section custom-agua-item="layer" className={styles['layer-2112-542f806faa3d44b1bdc2f7cb00e868ac']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-7a4dfe4ec07b419bb5ae45d2b803db0c']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-38df932938df46179c4d708a7acf1b2d']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-0d3d535119c14a11bd9a7f4a204af710']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-20764f5b28ba49b89111d5ca5104d3ba']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-ac72124ba2aa446b8aaaf2954a0ba24a']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-12bef169195c43309e31ab430400eb21']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.politicadeprivacidadbody}   >
<p custom-agua-item="text" className={styles['text-138-7f136ffeeab541bb8f356db9d1f84bd6']}   >POLÍTICA Y PROCEDIMIENTO DE TRATAMIENTO DE DATOS PERSONALES</p>
<p custom-agua-item="text" className={styles['text-139-14cdc2b27f2a4aefa789ff6da35dd023']}   >2023</p>
<section custom-agua-item="layer" className={styles.introduccion}   >
<p custom-agua-item="text" className={styles['text-140-086a6f396ad044a49551188482c1a7fe']}   >Introducción</p>
<p custom-agua-item="text" className={styles['text-141-0bfa26de6a8f42f68bdbae96c07047e2']}   >VAI COLOMBIA S.A.S (en adelante, VAI) es una sociedad que tiene como objeto conectar personas que pertenezcan a diferentes comunidades, a modo de ejemplo, estudiantes, trabajadores, empresarios y/o de cualquier otra naturaleza, por medio de una plataforma virtual compuesta por una página web y una aplicación móvil, para que los usuarios (a) ingresen a la plataforma virtual, se informen de las posibilidades disponibles para hacer uso compartido de vehículos de propiedad y/o tenencia de los usuarios de las misma comunidad que se dirigen hacía el mismo destino y/o se movilizan en la misma ruta y puedan realizar las correspondientes transacciones de reserva de cupos y aportes económicos de manera electrónica.</p>
<p custom-agua-item="text" className={styles['text-142-e77addaf55154bd480221e7bbb663b0e']}   >En virtud de lo anterior, se crea esta política que tiene como finalidad regular el tratamiento de datos personales de los que VAI es responsable en el marco de la ejecución de su objeto social, donde se le informa de cómo nos comprometemos a cuidar sus datos personales, qué información usaremos para proporcionarle nuestro paquete de servicios, cómo usaremos su información para mantenerle seguro y las opciones y controles disponibles para usted. Además, le indica de sus derechos de privacidad y cómo funcionan las reglas de protección de datos para proteger a todos. La Política de Tratamiento de Datos se suma a las demás políticas, reglamentos y lineamientos de VAI en su interés por alinear sus operaciones y estrategias con la normatividad vigente y los principios de administración universalmente aceptados.</p>
<p custom-agua-item="text" className={styles['text-143-d8a3ca49471541a89c6e3ea46e003a9a']}   >VAI, actuando como responsable de tratamiento de información personal, salvo que por decisión de la compañía se delegue esta función, lo cual se indicará, le informa a los usuarios, conductores, trabajadores, colaboradores, contratistas y/o proveedores, terceros interesados, entre otros operadores, que hayan facilitado o que en el futuro faciliten sus datos personales, que los mismos serán incluidos en la base de datos de nuestra compañía y serán tratados conforme a lo estipulado en la presente política.</p>
<p custom-agua-item="text" className={styles['text-144-8f2e2a58bcb64cf8af2aa7ee15f476a0']}   >En virtud de lo anterior, VAI ha decidido adoptar la presente política y procedimientos de protección de datos personales, la cual establece las obligaciones de la Compañía, las obligaciones de los intervinientes en el tratamiento y uso de la información de carácter personal, el régimen de funcionamiento y los procedimientos aplicables en el tratamiento de datos personales, que en el desarrollo de su objeto social tengan que solicitar, utilizar, corregir, almacenar, ceder o suprimir.</p>
<p custom-agua-item="text" className={styles['text-145-980b9b43fc8d47a5923738f37fb62bc3']}   >Lo anterior, con el fin de dar total cumplimiento a lo dispuesto por la Constitución Política de Colombia, la Ley 1581 de 2012 y las demás normas que reglamentan, complementan o modifiquen el tratamiento para la Protección de Datos Personales en Colombia.</p>
<p custom-agua-item="text" className={styles['text-146-fc8fb7c8fdf64383a4170d5051740b10']}   >VAI en cumplimiento a lo establecido en el artículo 13 del Decreto Reglamentario 1377 de 2013, adopta y hace público a todos los interesados el presente documento que contiene todos los elementos esenciales para el cumplimiento con la legislación correspondiente al derecho de Habeas Data.</p>
<p custom-agua-item="text" className={styles['text-147-02d868617e4744569b301e3f19207695']}   >Los datos administrados o tratados por VAI son datos personales que no son de naturaleza pública; sin perjuicio de lo anterior, podrá tratar datos de naturaleza pública, con el fin de llevar a cabo el desarrollo pleno de sus funciones de carácter privado. Por lo anterior, cabe destacar que las bases de datos de VAI se encuentran sujetas a todas y cada una de las obligaciones que dispone la Ley 1581 de 2012, los Decretos reglamentarios y las demás normas que lo complementen, adicionen o deroguen, en materia de Protección de datos personales.</p>
<p custom-agua-item="text" className={styles['text-148-83dd902edfc343f0b319be0457fb6ba1']}   >Marco Legal:</p>
<p custom-agua-item="text" className={styles['text-149-fe15790adda64e77bbba7172078cf2e5']}   >Constitución Política de 1991 artículos 15 del Habeas Data, 20 y 74 sobre acceso a la información.</p>
<p custom-agua-item="text" className={styles['text-150-8e26add9e75f4623a389e17aaf7ef6e6']}   >Ley 1266 del 31 de diciembre de 2008 “Por la cual se dictan las disposiciones generales del Habeas Data y se regula el manejo de la información contenida en bases de datos personales, en especial la financiera, crediticia, comercial, de servicios y proveniente de terceros países y se dictan otras disposiciones”.</p>
<p custom-agua-item="text" className={styles['text-151-195a7c9748324847bac8070bcf7a8d62']}   >Ley 1581 del 17 de octubre 2012 “Por el cual se dictan disposiciones generales para la protección de datos personales”. Decreto Reglamentario 1377 del 27 de junio de 2013 “Por el cual se reglamenta parcialmente la Ley 1581 de 2012”</p>
<p custom-agua-item="text" className={styles['text-159-5f39207aa61b469fbeae6209ed196e9d']}   >Sentencias de la Corte Constitucional C-1011 de 2008 mediante la cual se estudia la exequibilidad de la Ley Estatutaria 1266 de 2008 y C-748 de 2011 mediante la cual se estudia la exequibilidad de la Ley Estatutaria 1581 de 2012.</p>
</section>
<section custom-agua-item="layer" className={styles.objeto}   >
<p custom-agua-item="text" className={styles['text-152-78db81a8279640fe8a9c2d807bdc07c8']}   >Objeto</p>
<p custom-agua-item="text" className={styles['text-153-4d937422815941f889a371afcddbd519']}   >El propósito de esta política es establecer los criterios y procedimientos de VAI para la recolección, almacenamiento, uso, circulación y supresión de los datos personales tratados por VAI. Así como los derechos que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de datos o archivos físicos y electrónicos y lo referente a la recolección, almacenamiento, uso, circulación y supresión de los datos personales tratados por VAI.</p>
<p custom-agua-item="text" className={styles['text-154-ad11b6156a914472bdfe211584a21bf6']}   >VAI se compromete a adherirse a los más altos estándares para el tratamiento de datos personales y a cumplir con todas las leyes locales de conformidad con los artículos 15 y 20 de la Constitución Política de Colombia, la Ley Estatutaria 1581 de 2012 y demás normatividad que regule la materia.</p>
</section>
<section custom-agua-item="layer" className={styles.politicas}   >
<p custom-agua-item="text" className={styles['text-1692-22f2ba62cdae448b80557b40954274d4']}   >Ámbito de aplicación</p>
<p custom-agua-item="text" className={styles['text-160-4b72e56d64d7467389a79d10afe8624b']}   >La Política de Tratamiento de Datos Personales rige las actuaciones que realicen las siguientes personas siempre y cuando estén cumpliendo labores y/o actividades en nombre de VAI: Asambleístas, representantes legales, directores, asesores, trabajadores, contratistas, practicantes, voluntarios, proveedores, usuarios, conductores y demás entidades y personas que participan en el desarrollo del objeto social de VAI, quienes deben seguir en todo momento lo establecido en esta, independientemente del país en dónde se encuentren.</p>
<p custom-agua-item="text" className={styles['text-161-5c36b2e084054e2f89955f4100d8d817']}   >Las presentes políticas aplican para los datos personales registrados y toda la información personal registrada en las bases de datos de VAI, quien actúa en calidad de responsable del tratamiento de los datos personales.</p>
<p custom-agua-item="text" className={styles['text-162-b15954d385f545fba3c524a94b45136e']}   >De manera que, los presentes términos aplican para cualquier registro de datos personales realizados o que se realicen de forma presencial, no presencial y/o virtual en cualquier relación o vínculo que tenga el titular con VAI.</p>
<p custom-agua-item="text" className={styles['text-163-d3a83306a9a14d9188020765fbcbcdf2']}   >VAI se encarga directamente del tratamiento de los datos personales; no obstante, se reserva el derecho a delegar a un tercero el tratamiento, exigiendo de la misma forma al encargado, la atención e implementación de los parámetros y procedimientos idóneos para la protección de los datos personales y la estricta confidencialidad de estos.</p>
<p custom-agua-item="text" className={styles['text-164-b79ae42763774d159adb867b4f73e2ac']}   >Identificación del responsable del tratamiento</p>
<section custom-agua-item="layer" className={styles.responsable}   >
<section custom-agua-item="layer" className={styles['layer-249-8665040ca9d940cbb2cde3daca1619b8']}   >
<p custom-agua-item="text" className={styles['text-165-b7d2b1756c104ce2bea659baca751f16']}   >Razón Social</p>
<p custom-agua-item="text" className={styles['text-166-70f2d0ec601947bfb9e8b4b55806aabd']}   >VAI COLOMBIA S.A.S.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-248-cc8acc45b7ff4cfa8e0f26c45937ecd4']}   >
<p custom-agua-item="text" className={styles['text-167-06bb23404aad4e1e8a24aedb610d3529']}   >NIT</p>
<p custom-agua-item="text" className={styles['text-168-beb5ba0b37434546963668587d42db46']}   >901.586.077 – 1</p>
</section>
<section custom-agua-item="layer" className={styles['layer-250-406bc69ddc4c4f6caf4cc83756c153ee']}   >
<p custom-agua-item="text" className={styles['text-169-ee7bdf1e99a34c45a196f747ca6ccd7a']}   >Domicilio</p>
<p custom-agua-item="text" className={styles['text-170-f62fc510938948279d8445b02b1c41b4']}   >Bogotá D.C.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-251-86b2d7afe29740f18e5f8d2d63d1e5f4']}   >
<p custom-agua-item="text" className={styles['text-171-d540a2a093364469a8db25ca556c66ab']}   >Dirección</p>
<p custom-agua-item="text" className={styles['text-172-ad314c2660c644f79175d3e34ac87698']}   >DG 76 NO. 1 A 70</p>
</section>
<section custom-agua-item="layer" className={styles['layer-252-51ae85fc4a94412b9464ac5b5ad16f7e']}   >
<p custom-agua-item="text" className={styles['text-173-d2c5738abe4c43cfa21c9cecc7fd1af6']}   >Correo electrónico</p>
<p custom-agua-item="text" className={styles['text-174-54f73b05794546c1b497e5e04e570497']}   >ANDRES@VAI.COM.CO</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.definiciones}   >
<p custom-agua-item="text" className={styles['text-175-642a48c30e91494eb44f052c1d61b6dd']}   >Definiciones</p>
<p custom-agua-item="text" className={styles['text-176-90d2b3d70ee84ced87db1647a8bcb603']}   >Las definiciones que a continuación se establecen, permiten dentro de la presente política desarrollar una correcta y apropiada interpretación de la Ley 1581 de 2012 y sus decretos reglamentarios, y son indispensables para la protección del habeas data, lo que contribuye a determinar las responsabilidades de los involucrados en el tratamiento de datos personales.</p>
<p custom-agua-item="text" className={styles['text-177-37f982be495e4508b91e432218f6ea68']}   >Adolescente: significa personas entre 12 y 18 años de edad.</p>
<p custom-agua-item="text" className={styles['text-178-625aa7e99db54cb6a0b6485c4d537c79']}   >Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.</p>
<p custom-agua-item="text" className={styles['text-179-424bcb4b3e1d4baca0a7266eccc24c84']}   >Aviso de privacidad: Comunicación verbal o escrita generada por el responsable, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del tratamiento que se pretende dar a los datos personales.</p>
<p custom-agua-item="text" className={styles['text-180-7bdb6f9d4f5242f191075caf7f4c7331']}   >Base de Datos: Conjunto organizado de datos personales que sea objeto de tratamiento.</p>
<p custom-agua-item="text" className={styles['text-181-06aaba14e30a4f9291f16165a2401d23']}   >Datos abiertos: Son todos aquellos datos primarios o sin procesar, que se encuentran en formatos estándar e interoperables que facilitan su acceso y reutilización, los cuales están bajo la custodia de las entidades públicas o privadas que cumplen con funciones públicas y que son puestos a disposición de cualquier ciudadano, de forma libre y sin restricciones, con el fin de que terceros puedan reutilizarlos y crear servicios derivados de los mismos.</p>
<p custom-agua-item="text" className={styles['text-182-fec3aa920f844cb6b4a7f4d8269a0e28']}   >Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. De esta forma, el dato personal se debe entender como una información relacionada con una persona natural (persona individualmente considerada).</p>
<p custom-agua-item="text" className={styles['text-183-2688e237ba894fce83fb86a9c2c901ea']}   >Dato público: Es el dato que no sea semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales, y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.</p>
<p custom-agua-item="text" className={styles['text-184-518e7403aa8841c297cbd23749b8093b']}   >Datos personales públicos: Toda información personal que es de conocimiento libre y abierto para el público en general.</p>
<p custom-agua-item="text" className={styles['text-185-adb91025875a4b6fb52d0f471ea71f7d']}   >Datos privados: Toda información personal que tiene un conocimiento restringido, y en principio privado para el público en general.</p>
<p custom-agua-item="text" className={styles['text-186-96cd64c53282445698d44e9046410ab5']}   >Dato semiprivado: Es semiprivado el dato que no tiene naturaleza reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general.</p>
<p custom-agua-item="text" className={styles['text-187-2c4fc1cf0e36427d92c3828b8e81197d']}   >Dato sensible: Aquel dato que afecta la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros, la captura de imagen fija o en movimiento, huellas digitales, fotografías, entre otros.</p>
<p custom-agua-item="text" className={styles['text-188-51f12524a4644f978b230561efc38ae1']}   >Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento.</p>
<p custom-agua-item="text" className={styles['text-189-1cf2e878e8a84809b0ab10d19712cf9c']}   >Niño o niña” significan las personas entre los 0 y 12 años de edad.</p>
<p custom-agua-item="text" className={styles['text-190-b596f48687dc48a2870515b54d9a0988']}   >Plataforma significa el sitio web para la adquisición de los servicios ofrecidos por VAI o los Usuarios, a través del sitio web, apps y/o cualquier otro canal que en el futuro implemente el responsable del tratamiento.  Los Términos y Condiciones de la Plataforma vigentes, forman parte integral de la presente Política de Privacidad</p>
<p custom-agua-item="text" className={styles['text-191-f931a59f07714af396e2297e972f4c47']}   >Responsable del Tratamiento: VAI como persona jurídica, privada, que por sí misma o en asocio con otros, decide sobre la base de datos y/o el tratamiento de los datos.</p>
<p custom-agua-item="text" className={styles['text-192-b0d0cb7c5198489e84a950d801d72312']}   >Titular de información: Persona natural o jurídica a quien se refiere la información que reposa en un banco de datos y sujeto del derecho de hábeas data y demás derechos y garantías a que se refiere la Ley</p>
<p custom-agua-item="text" className={styles['text-193-1a7beab09be64aa38da5716fc702f589']}   >Información: Se refiere a un conjunto organizado de datos contenidos en cualquier documento físico, electromagnético o cualquier formato que exista o llegase a existir, que los responsables y/o encargados del tratamiento generen, obtengan, adquieran, transformen o controlen.</p>
<p custom-agua-item="text" className={styles['text-194-364f60ab7d2f427198b7fabd378849f3']}   >Transferencia: La transferencia de datos tiene lugar cuando el responsable y/o encargado del tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.</p>
<p custom-agua-item="text" className={styles['text-195-839630735adc4deab6f9a0cf0409582e']}   >Transmisión: Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta del responsable.</p>
<p custom-agua-item="text" className={styles['text-196-dedb1cb15a8f4a539b99aa49e2b7e175']}   >Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
<p custom-agua-item="text" className={styles['text-197-6e67ac66845543d4a5516ed9f0808b98']}   >Usuario: El usuario es la persona natural o jurídica que, en los términos y circunstancias previstos en la ley, puede acceder a información personal de uno o varios titulares de la información suministrada por el operador o por la fuente, o directamente por el titular de la información. El usuario, en cuanto tiene acceso a información personal de terceros, se sujeta al cumplimiento de los deberes y responsabilidades previstos para garantizar la protección de los derechos del titular de los datos. En el caso en que el usuario a su vez entregue la información directamente a un operador, aquel tendrá la doble condición de usuario y fuente, y asumirá los deberes y responsabilidades de ambos.</p>
</section>
<section custom-agua-item="layer" className={styles.titulares}   >
<p custom-agua-item="text" className={styles['text-198-3a1d8a0c98f2403c80996a25d3f28e73']}   >Titulares de la información a quien va dirigida la política</p>
<p custom-agua-item="text" className={styles['text-199-a5efd9257d6d4df3a35b4b72e6a4ecce']}   >Se entiende por Titulares las personas naturales o jurídicas cuyos datos personales son objeto de tratamiento de datos personales en VAI. La presente política está dirigida a cualquier titular de la información, ya sea actuando en su nombre o como representante legal que, con ocasión a las actividades y/o servicios para las cuales se encuentre vinculado o relacionado con VAI, se requiera de su información personal para el desarrollo de las mismas. Entre otros, pero sin limitarse a ellos: usuarios conductores, usuarios pasajeros, empleados, colaboradores, contratistas, proveedores, entre otros terceros u operadores.</p>
</section>
<section custom-agua-item="layer" className={styles.principiosrectores}   >
<p custom-agua-item="text" className={styles['text-200-de276cdef8384b48a9f448f16aff9ac9']}   >Principios Rectores</p>
<p custom-agua-item="text" className={styles['text-201-15f19101c361458f9ee7248351aca5b1']}   >VAI aplicará los siguientes principios específicos que se establecen a continuación, los cuales constituyen las reglas a seguir en la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de datos personales:</p>
<p custom-agua-item="text" className={styles['text-202-2e516e7a850c4e36a00ac48d9b029d40']}   >Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la presente política en concordancia con la Ley y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por VAI previamente autorizado por el Titular y/o por las personas previstas en el presente documento;

</p>
<p custom-agua-item="text" className={styles['text-203-068165c1eb844cb0b85bbfe5ad41984e']}   >Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la ley. Para estos propósitos, la obligación de VAI será de medio y no de resultado.</p>
<p custom-agua-item="text" className={styles['text-204-d56bb0b1046d46e9bebb61adda51d49b']}   >Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.</p>
<p custom-agua-item="text" className={styles['text-205-33a82475c5624e8b9fc42e52305328cf']}   >Por lo tanto, VAI se compromete a conservar y no revelar a terceros, los datos personales y la información contable, técnica, comercial o de cualquier otro tipo suministrada en la ejecución de las diferentes actividades que se desarrollen con y dentro de la compañía, ni la información que llegare a conocer con ocasión de la celebración de contratos, convenios, entre otros. Esta obligación de confidencialidad se prolongará más allá de la vigencia de cualquier relación que actualmente exista o llegue a existir con los titulares. Todas las personas que trabajen actualmente o sean vinculadas a futuro para tal efecto, en la administración y manejo de bases de datos, deberán suscribir un documento adicional u otrosí a su contrato laboral o de prestación de servicios para efectos de asegurar tal compromiso. Esta obligación persiste y se mantiene inclusive después de finalizada su relación con alguna de las labores, como por ejemplo y sin limitarse a los que se enuncian a continuación: vinculación por prestación de servicios, corretaje, arrendamientos, prácticas, orden de servicios, los cuales comprende el Tratamiento.</p>
<p custom-agua-item="text" className={styles['text-206-5e0537848dcc4e4f976c4dd5b22eda13']}   >Principio de finalidad: El Tratamiento de los datos personales que VAI realiza, obedece a una finalidad legítima de acuerdo con la Constitución y las leyes que reglamentan la materia.</p>
<p custom-agua-item="text" className={styles['text-207-3eea7ee301ae4021b59c627418347092']}   >Principio de legalidad: El Tratamiento a que se refiere el presente documento es una actividad reglada que se rige por la Ley Estatutaria 1581 de 2012, el Decreto 1377 de 2013 y demás normatividad que las complementen, modifiquen o deroguen, de modo que se sujeta a lo establecido en ellas y en las demás disposiciones que la desarrollen.</p>
<p custom-agua-item="text" className={styles['text-208-7f0d896532ad4a83826a808e82910bd5']}   >Principio de libertad: VAI sólo puede tratar y ceder los datos personales con el consentimiento, previo, expreso e informado del titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento. No obstante, puede tratar y ceder los datos personales que se encuentren almacenados en sus bases de datos, sin previo consentimiento del titular, siempre y cuando, estos provengan de los registros públicos, o que, si bien no están contenidos en ellos, sean de naturaleza pública o se encuentran en bases de datos excluidas por la Ley.

</p>
<p custom-agua-item="text" className={styles['text-209-e57a17b19a2c439a9664cb2180217f7e']}   >Principio de seguridad: VAI como Responsable del Tratamiento y/o Encargado del Tratamiento de datos a que se refiere las presentes políticas, proporciona las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
<p custom-agua-item="text" className={styles['text-210-e43a7c95a756456789467484817d5d40']}   >Principio de transparencia: VAI garantiza el derecho del Titular a obtener en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan y que estén almacenados en las bases de datos de VAI.</p>
<p custom-agua-item="text" className={styles['text-211-a9a04acceaec421ba8e9ff0ded4b3b76']}   >Principio de veracidad o calidad: VAI garantiza que la información contenida en las bases de datos, que estén sujetas a tratamiento; serán veraces, completas, exactas, actualizadas, comprobables y comprensibles.</p>
<p custom-agua-item="text" className={styles['text-212-d9874a1819654b929528e6bb785bdbcb']}   >Principio de facilitación: VAI deberá facilitar el ejercicio del derecho de acceso a la información, excluyendo exigencias o requisitos que puedan obstruir o impedirlo.</p>
<p custom-agua-item="text" className={styles['text-213-beb3f947677e453db65ee431d216fb23']}   >Principio de no discriminación: De acuerdo al cual VAI como el responsable del tratamiento de datos deberá entregar información pública a todas las personas que lo soliciten, en igualdad de condiciones, sin hacer distinciones arbitrarias.</p>
<p custom-agua-item="text" className={styles['text-214-d46a736d7fd64c3b9fb2f99e6464f8ac']}   >Principio de gratuidad: VAI dará el acceso a la información de forma gratuita y no se podrá cobrar valores adicionales al costo de reproducción de la información.</p>
<p custom-agua-item="text" className={styles['text-215-8ed4e0bbdf674b569e3f121d7ba26d82']}   >Principio de celeridad: VAI pondrá a disposición sus mecanismos para responder con agilidad en el trámite y la gestión administrativa.</p>
<p custom-agua-item="text" className={styles['text-216-57099b2ced3a47ee85a078a7a9410b6a']}   >Los principios sobre protección de datos serán aplicables a todas las bases de datos, incluidas las exceptuadas por la Ley, con los límites dispuestos en la presente política y sin contravenir con los datos que tienen características de estar amparados por la reserva legal. En el evento que la normatividad especial que regule las bases de datos exceptuadas prevea principios que tengan en consideración la naturaleza especial de datos, los mismos aplicarán de manera concurrente a los previstos en la presente política.</p>
</section>
<section custom-agua-item="layer" className={styles.tratamientoyfinalidad}   >
<p custom-agua-item="text" className={styles['text-217-20d940a1070d4c989b044af2d409b485']}   >Tratamiento y finalidad</p>
<p custom-agua-item="text" className={styles['text-218-1ebc7fff1aa34175adc1190fdfe1fdb3']}   >El tratamiento que realizará VAI, con la información personal y demás datos, se hará adoptando las medidas necesarias que garanticen el cumplimiento de los principios y obligaciones contempladas en la Ley 1581 de 2012 y demás normas que regulen esta materia, modifiquen, adicionen, regulen y conforme con las siguientes estipulaciones:</p>
<p custom-agua-item="text" className={styles['text-219-9c2302fcc1134d72b8d18ad7897bfda2']}   >Efectuar las gestiones pertinentes para el desarrollo del objeto social de VAI en lo que tiene que ver con el cumplimiento del objeto de las actividades y/o servicios acordados con el Titular de la información.</p>
<p custom-agua-item="text" className={styles['text-220-dfa1b43df31f4a97ae216e387748286b']}   >Realizar invitaciones a eventos y ofrecer nuevos productos y servicios relacionados con el desarrollo del objeto social de VAI y adelantar campañas de seguimiento y verificación de calidad en el servicio, encuestas de satisfacción, actualización de datos de información, campañas de mercadeo y servicios especiales.</p>
<p custom-agua-item="text" className={styles['text-221-bfdd6b8de4c540c1bd6bcf7932678e78']}   >Acreditar la identidad y verificar la información proporcionada a través de la Plataforma.</p>
<p custom-agua-item="text" className={styles['text-222-898b9582063347f78f9449faa0dce962']}   >Gestionar trámites (solicitudes, quejas, reclamos, entre otros).</p>
<p custom-agua-item="text" className={styles['text-223-3e7f712a5bde4cbf861ceabb67ae6899']}   >Suministrar información de contacto a los contratistas, trabajadores y sin limitarse a los que se enuncian a continuación: vinculación por prestación de servicios, usuario, colaboradores, practicantes de VAI para el desarrollo de actividades relacionadas con el objeto social de VAI para la ejecución de las mismas.</p>
<p custom-agua-item="text" className={styles['text-224-e36fe513fb9a4c32b77b202899d6c498']}   >Contactar al Titular a través de medios telefónicos y/o electrónicos o aquellos que VAI considere pertinentes para realizar encuestas, estudios y/o confirmación de datos personales necesarios para la ejecución de las actividades relacionadas con el objeto social, información comercial, publicitaria o promocional sobre los servicios ofrecidos por VAI y realizar seguimiento sobre el uso de la Plataforma.</p>
<p custom-agua-item="text" className={styles['text-225-447fe6ac02394a8fae8eb47a4f5823a3']}   >Contactar al Titular a través de medios electrónicos – SMS, chat u otro medio que VAI considere pertinente para el envío de noticias conexas con actividades relacionadas con el objeto social de VAI.</p>
<p custom-agua-item="text" className={styles['text-226-d6bcf553512e46219b4d8321f69f0545']}   >Evaluar, monitorear y registrar la actividad y el uso de la Plataforma y de los servicios ofrecidos por VAI.</p>
<p custom-agua-item="text" className={styles['text-227-35c9c01ce1b84b75889b720b88495563']}   >Suministrar la información a terceros con los cuales VAI tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto social de VAI.</p>
<p custom-agua-item="text" className={styles['text-228-7ec35697d10e47899d3578c14aa40bb8']}   >Atender requerimientos de las autoridades y suministrar información a entidades administrativas, judiciales y públicas autorizadas por la Ley.</p>
<p custom-agua-item="text" className={styles['text-229-3985ea72fdda4f9798923f300ba00c37']}   >Ejecutar la relación contractual existente con sus clientes, proveedores y trabajadores, incluido el pago de obligaciones contractuales.</p>
<p custom-agua-item="text" className={styles['text-230-1cfbfcad053d40fdbf51de20af0c2bd3']}   >Proveer los servicios establecidos en la Ley 527 de 1999 y las normas que la complementen, modifiquen o adicionen.</p>
<p custom-agua-item="text" className={styles['text-231-9cd7224d1daf4b68a2eec27465ae582a']}   >Llevar a cabo el proceso administrativo, comercial, operativo y financiero establecido por VAI</p>
<p custom-agua-item="text" className={styles['text-232-b1001ed81fb44893b8b3ea1972b235cf']}   >Realizar la validación de identidad de los usuarios de las plataformas tecnológicas de VAI.</p>
<p custom-agua-item="text" className={styles['text-233-b87c1550c30f464b9fe5bd707d8bbfa1']}   >Enviar correspondencia, correos electrónicos, comunicados, boletines virtuales, invitaciones o contacto telefónico sobre sus diferentes productos, servicios, programas, actividades y convenios.</p>
<p custom-agua-item="text" className={styles['text-234-8ee1fd90aeab453b937a0f2c046ba80c']}   >Compartir, suministrar o transferir información con aliados comerciales o corporativos, además de otros potenciales interesados, para el ofrecimiento y/o contratación de servicios o de bienes.</p>
<p custom-agua-item="text" className={styles['text-235-24f31453a986405cb5d3c7cae1d5b733']}   >Transferir o transmitir mis datos a terceros dentro y fuera de Colombia con quienes realice convenios o alianzas o con quienes contrate estudios o les encargue el Tratamiento de datos.</p>
<p custom-agua-item="text" className={styles['text-236-221b7449d18d40df94755c85219c5615']}   >Reporte y consulta a centrales de riesgo crediticio legalmente constituidas en Colombia, incluyendo a Datacredito y Cifin.</p>
<p custom-agua-item="text" className={styles['text-237-2e71bad6df584e9583ee551d5caec111']}   >Realizar análisis de prospectos con fines comerciales, financieros o de mercadeo.</p>
<p custom-agua-item="text" className={styles['text-238-52dff1d710b1497394ccada6bcf3a175']}   >Realizar gestiones de cobranzas o de índole comercial.</p>
<p custom-agua-item="text" className={styles['text-239-50113666dc474e71a162b1ee846bf5d9']}   >Expedición de certificados digitales de: función pública, persona jurídica, persona natural, pertenencia a la empresa y representación de la empresa, cuando sean adquiridos por el titular.</p>
<p custom-agua-item="text" className={styles['text-240-53002efd224e4f5b8f9af9d8b2eb3149']}   >Conectar con un conductor para lo cual recopilamos y procesamos datos personales con el fin de conectar a los usuarios con los conductores para poder recogerlos y transportarlos. Los mensajes instantáneos enviados por VAI son procesados para proporcionar servicio y soporte al cliente, por motivos de seguridad así como también para mejorar nuestros productos y servicios y para análisis.</p>
<p custom-agua-item="text" className={styles['text-241-d2944ae0f590457094e69f9e1756538a']}   >Lo llevamos a donde quiera ir: Mostramos a los conductores los datos de geolocalización y el número de teléfono de los usuarios cuando piden transporte para permitir un servicio eficiente, y hacer que el servicio sea transparente. Estos datos son recopilados solamente cuando la aplicación de VAI está activada. La recopilación de datos de geolocalización termina al cerrar la aplicación de VAI.</p>
<p custom-agua-item="text" className={styles['text-242-39d9580c55564028a0146db7b4ce813f']}   >Nos aseguramos de que su servicio transcurra sin problemas, usando datos de geolocalización para asegurarnos de que llegue a su destino y resolver problemas de calidad relacionados con nuestros servicios.</p>
<p custom-agua-item="text" className={styles['text-243-0d64aa05c3e240d3966534a079668d44']}   >Nos aseguramos de que la aplicación sea óptima, por lo que usamos los datos de contacto para notificar a los usuarios de las actualizaciones de la aplicación de VAI para que pueda seguir utilizando nuestros servicios. También recopilamos datos limitados del dispositivo que utiliza para conectarlo con nuestros servicios de Internet, móviles y telefónicos, y para ayudar a mantener su cuenta segura a través de autenticación y verificación.</p>
<p custom-agua-item="text" className={styles['text-244-39bfcaa4b50d404ca9496a86e4832862']}   >Recopilamos datos sobre las rutas tomadas por nuestros conductores y usuarios para analizar la cobertura geográfica. Esto nos permite, entre otras cosas, mejorar las recomendaciones a los conductores sobre las rutas más eficientes.</p>
<p custom-agua-item="text" className={styles['text-245-4f3411a958b54efeb4feb33060dd9d11']}   >Obtenemos los detalles de pago para procesar los pagos por los servicios prestados.</p>
<p custom-agua-item="text" className={styles['text-246-bc40fe41305d40ba880dec71dadddfd8']}   >Recopilamos datos sobre el estado de las rutas, el tiempo y las calificaciones de los usuarios a partir de los comentarios de los usuarios para promover la seguridad del usuario, el cumplimiento de nuestras condiciones y asegurarnos de proporcionar a todos un servicio agradable y de calidad. Los datos de atención al cliente y la correspondencia se recopilan con el fin de obtener comentarios y resolver disputas y problemas de calidad del servicio.</p>
<p custom-agua-item="text" className={styles['text-247-2671ac28e0a4406e8699bc2cd8c8e2e8']}   >Su nombre, número de teléfono y dirección de correo electrónico se usarán para comunicarse con usted para informarle de que su servicio se ha completado, enviarle recibos y confirmaciones de servicios e informarle de actualizaciones importantes del servicio.</p>
<p custom-agua-item="text" className={styles['text-248-66b421f380e4428791db53e251bbc009']}   >Podemos enviar mensajes de marketing directo a su dirección de correo electrónico y/o número de teléfono si nos ha autorizado para hacerlo, o en virtud de una suscripción voluntaria indirecta. Podemos personalizar los mensajes de marketing directo utilizando información sobre cómo utiliza usted los servicios de VAI, es decir, la frecuencia con la que usa la aplicación de VAI y sus preferencias de transporte.</p>
<p custom-agua-item="text" className={styles['text-249-559e724ea70c41a6a04c1dec4def304a']}   >Así como para la prestación de cualquier servicio ofrecido por VAI.</p>
<p custom-agua-item="text" className={styles['text-250-14c4af09e9444a1aaafb97a322be9c17']}   >Los escenarios mencionados anteriormente son enunciativos y podrán existir otros casos en los cuales VAI tenga tratamiento de datos personales, ya sea para el efectivo desarrollo de su objeto social o por disposición legal. </p>
<p custom-agua-item="text" className={styles['text-251-2028e007aa9a403ab363e1d9e988f153']}   >Así mismo, se especifica que la finalidad de tratamiento de la información personal será:</p>
<p custom-agua-item="text" className={styles['text-252-eeeba463691f4fccbe7fbe3bc72abd0b']}   >Datos personales que procesamos</p>
<p custom-agua-item="text" className={styles['text-253-3b358f9d7c954abb9537211db7b748a1']}   >Sólo procesamos la información que necesitamos para poder brindarle el mejor servicio en nuestro mercado.</p>
<p custom-agua-item="text" className={styles['text-254-871492a02c8e45f98921ded5c957740c']}   >Información de contacto: nombre, número de teléfono y dirección de correo electrónico. En el caso de algunos de nuestros servicios, es posible que necesitemos más información, a modo de ejemplo, pero sin limitarse a la dirección de su domicilio.</p>
<p custom-agua-item="text" className={styles['text-255-fa6a58e491f34fe699c4cbb65e2984be']}   >Información de perfil: Su foto de perfil, direcciones guardadas, preferencias de idioma y comunicación. En el caso de algunos de nuestros servicios, es posible que necesitemos más información, a modo de ejemplo, pero sin limitarse a detalles de su permiso de conducir.</p>
<p custom-agua-item="text" className={styles['text-256-144c073af74544efa65da8811d70949b']}   >Información sobre los vehículos: marca, modelo, placa, año, línea, etc.</p>
<p custom-agua-item="text" className={styles['text-257-0acdda6f2e1344fcb69148395408d13b']}   >Geolocalización: por ejemplo, desde dónde necesita transporte o la ubicación de los vehículos, la hora, la progresión del servicio y su destino final.</p>
<p custom-agua-item="text" className={styles['text-258-a19f429119ee415e8b46471a48a447ac']}   >Información de pago, como la suma cargada y la tarjeta de pago usada.</p>
<p custom-agua-item="text" className={styles['text-259-7049e6ed62474cf4a508146b6e13928e']}   >Registros de comunicación y correspondencia, como cuándo usa el chat de la aplicación o habla con nuestros agentes de servicio al cliente.</p>
<p custom-agua-item="text" className={styles['text-260-a2559c74c31648c4a992c37a091933e8']}   >Datos limitados de identificación del dispositivo en el que se ha instalado la aplicación de VAI, como su dirección IP y otras cookies.</p>
<p custom-agua-item="text" className={styles['text-261-82d7e67128ac483cbe84f16fcb417b18']}   >Datos sobre el uso de los servicios: datos sobre el estado del servicio, horas y datos sobre su conducta según la evaluación de los usuarios.</p>
<p custom-agua-item="text" className={styles['text-262-12e45abc774f4892a66781081e6ad2a9']}   >Datos relacionados con la comunicación a través de mensajes instantáneos de la aplicación de VAI fecha y hora de la comunicación y su contenido.</p>
<p custom-agua-item="text" className={styles['text-263-8d9e92b256ce488c84025e33dbcee2a3']}   >Los datos personales generalmente se procesan para proporcionarle los servicios contratados a través de la aplicación de VAI. Esto significa que, para brindarle el servicio prometido y para cumplir con nuestras condiciones, procesaremos sus datos personales para cumplir con esas obligaciones.</p>
<p custom-agua-item="text" className={styles['text-264-8bb2e3c4b07d4c7e865208448d76afa3']}   >Por lo general, en otras circunstancias, procesamos sus datos personales por motivos de interés legítimo. El interés legítimo incluye nuestros intereses comerciales en proporcionar un servicio innovador, personalizado, seguro y rentable a nuestros usuarios, a menos que esos intereses sean anulados por otros intereses. Nuestro interés legítimo también incluye la investigación y detección de pagos fraudulentos y otras actividades maliciosas, mantener la seguridad de nuestra red y sistemas y responder a acciones criminales o potencialmente criminales.</p>
<p custom-agua-item="text" className={styles['text-265-5bae06d2b55840c2842b718391386846']}   >Si es necesario cumplir con una obligación legal, por ejemplo, para el procesamiento de datos cuando la ley lo requiere, incluyendo, por ejemplo, si existe una solicitud legal válida para divulgar información personal a un tercero, como un Tribunal o una autoridad reguladora.</p>
<p custom-agua-item="text" className={styles['text-266-9d639a2050d140ae8fe2f23327531fe3']}   >Para proteger sus intereses vitales o los de otros, por ejemplo, en caso de una emergencia o una amenaza inminente a la vida.</p>
</section>
<section custom-agua-item="layer" className={styles.recoleccion}   >
<p custom-agua-item="text" className={styles['text-267-253586b7cc8b4cc3b8070bb454c3e3cf']}   >Recolección</p>
<p custom-agua-item="text" className={styles['text-268-f532bc5dfc4d4c5e8b189d7e6e4cb02a']}   >VAI realiza la recolección de la información personal, a través de los siguientes medios. Sin limitarse a ellos:</p>
<p custom-agua-item="text" className={styles['text-269-ba717f60e50d4cbc9c1cb26bb9ea1981']}   >Aplicativos y formularios virtuales: Cuando ingresa a los diferentes servicios soportados por sistemas de información y aplicaciones de</p>
<p custom-agua-item="text" className={styles['text-270-8d2d605e90aa45b69fd3d5928017b633']}   >Chat: Cuando se solicita información a través de medios virtuales.</p>
<p custom-agua-item="text" className={styles['text-271-ee669f4512304d06898f2bcafdddf8b5']}   >Llamadas telefónicas: En consulta por vía telefónica se capturan datos personales, incluida la grabación de la llamada.</p>
<p custom-agua-item="text" className={styles['text-272-90a72aeddd1e427780b8f0ffa88173fd']}   >Formularios físicos: Cuando acceden a algunas de nuestras actividades por los canales presenciales, se obtienen sus datos personales a través de formularios físicos, encuestas dirigidas a la comunidad en general, entre otros.</p>
<p custom-agua-item="text" className={styles['text-273-c7b1931efa4d4064a0ea992a547586c8']}   >Cámaras de video: Cuando se encuentra en las instalaciones de VAI o en las actividades que desarrolla y son grabadas con el propósito de velar por la seguridad de los bienes y las personas.
</p>
<p custom-agua-item="text" className={styles['text-274-e7aa15377b52412381f2595b72402f78']}   >Videollamadas: Cuando se programe una reunión por videollamada y en el desarrollo de esta se recolecte información personal.
</p>
<p custom-agua-item="text" className={styles['text-275-ce59227d14b64d2cafaff069b1b9bee8']}   >VAI garantiza que, en los instrumentos de recolección de información personal, se solicita la autorización para el tratamiento de datos personales, de manera libre, voluntaria, previa, expresa y en general, cuenta con todos los requisitos establecidos en las disposiciones legales para la protección de datos personales.</p>
</section>
<section custom-agua-item="layer" className={styles.almacenamiento}   >
<p custom-agua-item="text" className={styles['text-276-ce778ef27edd41b68506a12207dc27fa']}   >Almacenamiento</p>
<p custom-agua-item="text" className={styles['text-277-f69ac2916ec34cba881b7a3aebfa3f95']}   >El almacenamiento de la información personal de bases de datos y archivos con información personal puede ser efectuado por VAI mediante las siguientes formas:</p>
<p custom-agua-item="text" className={styles['text-278-543608c077d24b6dbf52d62fc56e0d20']}   >Servidor propio</p>
<p custom-agua-item="text" className={styles['text-279-9f78b08320254246a06408e5f3c9202a']}   >Servidor externo a cargo de un tercero</p>
<p custom-agua-item="text" className={styles['text-280-a893036e01804edb960b05c647e0cf99']}   >Archivo en custodia de un tercero</p>
<p custom-agua-item="text" className={styles['text-281-9c5dd0b90a0c479495b263c669ac6c52']}   >Archivo propio interno</p>
<p custom-agua-item="text" className={styles['text-282-4e3fbacebd394d2b88e04fbdfadc0046']}   >Computador de la compañía</p>
<p custom-agua-item="text" className={styles['text-283-51e2e04b8d2c4fb5b72ea6182ca7218f']}   >Por otros medios que la empresa considere seguros.</p>
<p custom-agua-item="text" className={styles['text-284-1d84f1bfd38a416684bf68144a29faca']}   >VAI cuenta con medidas de seguridad física, técnicas y administrativas para evitar la consulta y acceso no autorizado a las bases de datos y archivos con información personal. Así mismo, exige a los encargados de la información, medidas conducentes a la seguridad y confidencialidad de la información suministrada.</p>
<p custom-agua-item="text" className={styles['text-285-468d322079b94aad96d06c36224c21b9']}   >En VAI conservamos diferentes categorías de datos durante diferentes periodos, dependiendo del tipo de datos, el servicio relacionado y los fines para los que recopilamos los datos.</p>
<p custom-agua-item="text" className={styles['text-286-81be9375fbfe4422a9c374807e679025']}   >Sus datos personales se almacenarán siempre que tenga una cuenta activa. Si se cierra su cuenta, los datos personales se eliminarán, salvo que dichos datos sigan siendo necesarios para cumplir con cualquier obligación legal o para fines de contabilidad, resolución de disputas o prevención de fraude.</p>
<p custom-agua-item="text" className={styles['text-287-6c75bb619ed140d8bf917d757ecdacb1']}   >Los datos financieros relativos a los servicios prestados se almacenarán durante cinco (05) años después del último servicio. Los datos necesarios para otros efectos contables se almacenarán durante diez (10) años (después del último servicio).</p>
<p custom-agua-item="text" className={styles['text-288-4786cb04055d4d51a0b49777a7845db7']}   >En caso de sospecha de infracción penal, actividad fraudulenta o de recibir información falsa, los datos se almacenarán durante un máximo de 10 años.</p>
<p custom-agua-item="text" className={styles['text-289-ad567272ef174a1f9fed5290639bf4bb']}   >En caso de disputas sobre pagos, los datos se conservarán hasta que se satisfagan las reclamaciones o hasta que dichas reclamaciones prescriban.</p>
<p custom-agua-item="text" className={styles['text-290-425c6caa87a44fc4a0262e1406cddaac']}   >Los datos del historial de viajes y los datos sobre el uso de los servicios se almacenarán durante cinco (05) años.</p>
<p custom-agua-item="text" className={styles['text-291-dc43fcce862447929bcc7c628ee53f4c']}   >Tenga en cuenta que la desinstalación de la aplicación de VAI en su dispositivo no implica la eliminación de sus datos personales. Si la aplicación de VAI no se ha utilizado durante cinco (05) años, nos pondremos en contacto con usted y le pediremos que confirme si desea mantener su cuenta activa para usarla en el futuro. Si no recibimos respuesta en un plazo razonable, la cuenta se cerrará y los datos personales se eliminarán, a menos que dichos datos sean necesarios para cualquier propósito mencionado anteriormente en esta política.</p>
</section>
<section custom-agua-item="layer" className={styles.datosensibles}   >
<p custom-agua-item="text" className={styles['text-293-7dcb77da23334d2a9214600a9e2137a1']}   >Tratamiento de datos sensibles</p>
<p custom-agua-item="text" className={styles['text-294-5eef61c7c463477cad44d2f08a4c5907']}   >VAI restringirá el tratamiento de datos personales sensibles a lo estrictamente indispensable y solicitará consentimiento previo y expreso a los titulares, informando sobre la finalidad exclusiva de su tratamiento.</p>
<p custom-agua-item="text" className={styles['text-295-7e4ea5634df14658bb52f722f42361d2']}   >VAI utiliza y trata datos catalogados como sensibles, cuando:</p>
<p custom-agua-item="text" className={styles['text-296-621d577243ba4648b3b85ac56b98f953']}   >1. El Titular haya dado su autorización explícita a dicho tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización;</p>
<p custom-agua-item="text" className={styles['text-297-da1f5887e13441a08ae70625684e10f5']}   >2. El tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización;</p>
<p custom-agua-item="text" className={styles['text-298-c581a1cfe8444753b769c0aa8c3ed65f']}   >3. El tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial;</p>
<p custom-agua-item="text" className={styles['text-299-68bc313caa124646a374bfe1340cf42c']}   >4. El tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.</p>
<p custom-agua-item="text" className={styles['text-300-35b57a4a8c1747f4aaf6aba8e0536de0']}   >En adición a lo anterior, VAI cumple con las siguientes obligaciones:</p>
<p custom-agua-item="text" className={styles['text-301-df7d77fd777b4414b65a0e34a7fceeff']}   >1. Informar al Titular que por tratarse de datos sensibles no está obligado a autorizar su tratamiento.</p>
<p custom-agua-item="text" className={styles['text-302-38a0b3d303c048e396ff64eca5906c48']}   >2. Informar al Titular de forma explícita y previa, además de los requisitos generales de la autorización para la recolección de cualquier tipo de dato personal, cuáles datos objeto de tratamiento son de carácter sensible y la finalidad del tratamiento, y obtener el consentimiento expreso.</p>
<p custom-agua-item="text" className={styles['text-303-3722625409464e28a863f7ef90b16a1d']}   >3. No condicionar ninguna actividad a que el Titular suministre datos personales sensibles (salvo que exista una causa legal o contractual para hacerlo).</p>
<p custom-agua-item="text" className={styles['text-304-178d35e2a2bc4de2a58690b5a5daee70']}   >Los datos sensibles recolectados serán tratados conforme a la regla general establecida en el numeral cuatro de la presente política. Sin embargo, los escenarios allí mencionados son enunciativos y podrán existir otros casos en los cuales VAI tenga tratamiento de datos personales sensibles, ya sea por disposición legal o para el efectivo desarrollo de su objeto social.</p>
</section>
<section custom-agua-item="layer" className={styles.datosdemenores}   >
<p custom-agua-item="text" className={styles['text-305-c71c0999a051411ea5ccff6e19973877']}   >Tratamiento de datos de menores</p>
<p custom-agua-item="text" className={styles['text-306-95589a1e17914bfea8c7b945c2469799']}   >En la recolección, uso y tratamiento de los datos personales, VAI asegurará el respeto a los derechos prevalentes de los niños, niñas y adolescentes. El tratamiento de datos personales de niños, niñas y adolescentes está proscrito excepto cuando se trate de datos de naturaleza pública, de conformidad con lo establecido en el artículo 7 de la ley 1581 de 2012, y cuando dicho tratamiento cumpla con los siguientes parámetros y requisitos:</p>
<p custom-agua-item="text" className={styles['text-307-63914b190ff64104ad02913dee237efa']}   >1. Que responda y respete el interés superior de los niños, niñas y adolescentes.</p>
<p custom-agua-item="text" className={styles['text-308-864d62435f32405d843d844fa59d0461']}   >2. Que se asegure el respeto de sus derechos fundamentales.</p>
<p custom-agua-item="text" className={styles['text-309-61c87a0f37fc4bb5b08bc9e94370bd9b']}   >Cumplidos los anteriores requisitos, el representante legal o tutor del niño, niña o adolescente otorgará la autorización previo ejercicio del menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto. VAI, deberá velar por el uso adecuado de los mismos. Para este fin deberán aplicarse los principios y obligaciones establecidos en la Ley 1581 de 2012 y el presente decreto.</p>
<p custom-agua-item="text" className={styles['text-310-b57cbf0e3ecc41128d6c785e8183986c']}   >De acuerdo con lo previsto en la Constitución Política de Colombia en sus artículos 44 y 45 y en concordancia con el Código de la Infancia y la Adolescencia, los derechos de los menores deben ser interpretados y aplicados de manera prevalente y, por lo tanto, deben ser observados con especial cuidado. Conforme lo señalado en la Sentencia C-748/11 de la Corte Constitucional, las opiniones de los menores deben ser tenidos en cuenta al momento de realizar algún tratamiento de sus datos.</p>
</section>
<section custom-agua-item="layer" className={styles.suministrartransferirotransmitiri}   >
<p custom-agua-item="text" className={styles['text-311-9a251da6873e42f7bdb958b32322a1e9']}   >Personas a quienes se les puede suministrar, transferir o transmitir la información</p>
<p custom-agua-item="text" className={styles['text-312-2f3eabdad9c64439b8ec8cb0d8cfb943']}   >La información y datos personales que reúnan las condiciones establecidas por la ley y las demás normas que la desarrollan, podrán suministrarse a las siguientes personas:</p>
<p custom-agua-item="text" className={styles['text-313-4fdd6acd5e484f95a2419c5db44f7b25']}   >1. A los titulares, sus causahabientes o sus representantes legales.</p>
<p custom-agua-item="text" className={styles['text-314-6d74f5d85a7846169e656d4f5a1f89e4']}   >2. A las entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial.</p>
<p custom-agua-item="text" className={styles['text-315-300204c8ef0b42bf88b199d9afee4e31']}   >3. A los terceros autorizados por el titular o por la ley, a modo de ejemplo, pero sin limitarse a los conductores, usuarios, colaboradores, entre otros.</p>
<p custom-agua-item="text" className={styles['text-316-d0eaeef71ab24dbbb60a01b33fa4dc16']}   >La información y datos personales que reúnan las condiciones establecidas por la ley y las demás normas que la desarrollan, podrán transferir o transmitir a las siguientes personas:</p>
<p custom-agua-item="text" className={styles['text-317-debdb8709f7a4f0f9c032fc88ba8d061']}   >1. Las empresas y los socios de VAI pueden acceder a los datos personales en la medida necesaria para brindar asistencia al cliente en el país respectivo.</p>
<p custom-agua-item="text" className={styles['text-318-73f8372880b042a0b2952db1f78d1516']}   >2. Las personas jurídicas que tienen la calidad de filiales, subsidiarias o vinculadas y/o aliadas estratégicas.</p>
<p custom-agua-item="text" className={styles['text-319-2de3f7be78f0436595b305bf91d86575']}   >3. Personas jurídicas que administran bases de datos para efectos de prevención y control de fraudes, la selección de riesgos, y control de requisitos para acceder al Sistema General de Seguridad Social Integral.</p>
<p custom-agua-item="text" className={styles['text-320-08a59185174344f3853713bf575036b1']}   >Los encargados del tratamiento tendrán los siguientes deberes:</p>
<p custom-agua-item="text" className={styles['text-321-fa1ee8ae0e344d67812b0c12683965bf']}   >Cumplir en el desarrollo de las actividades contratadas, con la Política de privacidad y protección de datos personales, así como con todos aquellos procedimientos, guías y/o directrices que imparta el responsable del tratamiento en materia de protección de datos personales.</p>
<p custom-agua-item="text" className={styles['text-322-6464ddb1fb1d463c99f4ef175c57d752']}   >Adoptar, según instrucciones del responsable del tratamiento, todas las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
<p custom-agua-item="text" className={styles['text-323-77ae4ba13e1b43a38814220a96609504']}   >Implementar una política de protección de datos personales que se ajuste a lo dispuesto por las normas que regulan la materia.</p>
<p custom-agua-item="text" className={styles['text-324-8f83dbe19eac42f5836413cbe6817d4e']}   >Dar tratamiento a los datos personales conforme a las instrucciones que reciba expresamente del responsable del tratamiento, absteniéndose de usarlos para fines distintos a los contratados.</p>
<p custom-agua-item="text" className={styles['text-325-10a9b59108634589a91ccba71808c049']}   >Abstenerse de suministrar, ceder o comercializar los datos personales con terceras personas naturales o jurídicas, públicas o privadas, salvo que la misma sea de naturaleza pública sin sujeción a reserva, o sea requerida por una autoridad competente en el ejercicio de sus funciones legales.</p>
<p custom-agua-item="text" className={styles['text-326-c42b7173cb5e4f69aaa044b5a0f1f07c']}   >Guardar estricta confidencialidad respecto de los datos de carácter personal a que tuvieran acceso en ejercicio de las actividades contratadas, así como a cumplir diligentemente el deber de guardia y custodia sobre los mismos durante todo el término de vigencia del contrato y aún después de producida la terminación.</p>
<p custom-agua-item="text" className={styles['text-327-2c953ce8ab6346b184605cc27eca756a']}   >Acceder o consultar la información o datos personales que reposen en las bases de datos del responsable del tratamiento únicamente cuando sea estrictamente necesario para el ejercicio de las actividades contratadas.</p>
<p custom-agua-item="text" className={styles['text-328-a1e566cd63d14566a082b46ba17e95d5']}   >Reportar al responsable del tratamiento de manera inmediata a su materialización o al momento en que llegaren a su conocimiento, por los conductos y medios establecidos por este, cualquier incidente o amenaza de incidente que afecte o pueda llegar a afectar directa o indirectamente la protección de datos personales.</p>
<p custom-agua-item="text" className={styles['text-329-e2d943645a5549f9a0be758e5bc724f6']}   >Garantizar en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data de los titulares, así como el debido proceso en caso de presentarse PQRSD en materia de protección de datos personales.</p>
<p custom-agua-item="text" className={styles['text-330-3272aed22fed4cfdaa1df9a89f8623cf']}   >Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos establecidos en las normas vigentes.</p>
<p custom-agua-item="text" className={styles['text-331-7ce70d64898f4b3b83b26070024d2eb9']}   >Actualizar la información reportada por el responsable del tratamiento, dentro de los cinco (5) días hábiles contados a partir de su recibo.</p>
<p custom-agua-item="text" className={styles['text-332-e21cbc5792994269959095e46b676827']}   >Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de las normas vigentes y, en especial, para la atención de PQRSD por parte de los titulares.</p>
<p custom-agua-item="text" className={styles['text-333-19859a123e17456084fa3e56fe69fede']}   >Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por una autoridad competente.</p>
<p custom-agua-item="text" className={styles['text-334-1392d65db6014864861c7f14034a09f8']}   >Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.</p>
<p custom-agua-item="text" className={styles['text-335-ba4c2d17e2514a129c7b06f7d424c440']}   >Cumplir las instrucciones y requerimientos que imparta una autoridad competente.</p>
<p custom-agua-item="text" className={styles['text-336-daf6c6ca64c0489480ca9bcb6b60a4fe']}   >En caso de recolectar datos por cuenta del responsable de tratamiento, requerir la autorización de los titulares, en los casos en los que se requiera, conforme a lo dispuesto por las normas vigentes.</p>
</section>
<section custom-agua-item="layer" className={styles.derechosdelostitulares}   >
<p custom-agua-item="text" className={styles['text-340-6f31b7c4ffd44ce588f3c8a252f40581']}   >Derechos de los titulares</p>
<p custom-agua-item="text" className={styles['text-338-5ff3108bc6894e099e9866431645a504']}   >VAI reconoce y garantiza a los Titulares de los datos personales los siguientes derechos: </p>
<p custom-agua-item="text" className={styles['text-339-069e9dfb0a614051a6b70759e2f3c09e']}   >Tener conocimiento y acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.</p>
<p custom-agua-item="text" className={styles['text-341-3df952112aea4f55890f6c451379905b']}   >Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.</p>
<p custom-agua-item="text" className={styles['text-342-77d99fb0725f4b8a87eac067bdf7d5c8']}   >Solicitar prueba de la autorización otorgada, salvo los casos en los que la Ley exceptúa la autorización.</p>
<p custom-agua-item="text" className={styles['text-343-4d932c57de8e4dd4b609333ea979a4d3']}   >Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.</p>
<p custom-agua-item="text" className={styles['text-344-d6bb0ae555e1408ba47d46886f5bc00c']}   >Modificar y revocar la autorización y/o solicitar la supresión de los datos, siempre que no exista un deber legal o contractual que impida eliminarlos.</p>
<p custom-agua-item="text" className={styles['text-345-52a60c1dd46a4f678e3194d7fd13d759']}   >Abstenerse de responder las preguntas sobre datos sensibles. Tendrán carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas, niños y adolescentes.</p>
</section>
<section custom-agua-item="layer" className={styles.deberesdevai}   >
<p custom-agua-item="text" className={styles['text-346-42d8cf2338604bfcafb19572aae7dbfc']}   >Deberes de VAI en relación con el tratamiento de los datos personales.</p>
<p custom-agua-item="text" className={styles['text-347-8b1d2319280f46cea4480b48ec2cbe5c']}   >VAI tiene presente que los datos personales son de propiedad de las personas a las que se refiere y solamente ellas pueden decidir sobre los mismos. Así mismo, VAI hará uso de dichos datos solamente para las finalidades para las que se encuentra debidamente facultado y respetando, en todo caso, la normatividad vigente sobre la Protección de Datos Personales.</p>
<p custom-agua-item="text" className={styles['text-348-16a845c91dce4abcaafe4e3c76fbf81b']}   >VAI atenderá los deberes previstos para los responsables y encargados del tratamiento, contenidos en el artículo 17 y 18 de la Ley 1581 de 2012, o normas que la reglamenten o modifiquen, a saber:</p>
<p custom-agua-item="text" className={styles['text-349-2ca23484f9bc4863b0dd1ee79a76ded0']}   >1. Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data y de petición.</p>
<p custom-agua-item="text" className={styles['text-350-6372e383e6c245698780634b5a8f513a']}   >2.Solicitar y conservar copia de la respectiva autorización otorgada por el Titular, para el uso y tratamiento de los datos personales.</p>
<p custom-agua-item="text" className={styles['text-351-2141ab856945496eaa0723acba456ac9']}   >3. Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten, en virtud de la autorización otorgada.</p>
<p custom-agua-item="text" className={styles['text-352-67a29b83df3b4eb3af7ea30796e27c96']}   >4. Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
<p custom-agua-item="text" className={styles['text-353-409867a34cea485b98e1ec482e382919']}   >5. Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</p>
<p custom-agua-item="text" className={styles['text-354-d553a10c25ff4da6be8b851a370935c6']}   >6. Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.</p>
<p custom-agua-item="text" className={styles['text-355-204fb307fcb6406ab8b33e858d116f48']}   >7. Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del tratamiento.</p>
<p custom-agua-item="text" className={styles['text-356-7f2bf910cc574124b9fb6a4c5e99e504']}   >8. Suministrar al encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado.</p>
<p custom-agua-item="text" className={styles['text-357-19e08727669c4f97838e8c42fc2c6a1f']}   >9. Exigir al encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del titular.</p>
<p custom-agua-item="text" className={styles['text-358-704746ee3c0c4a77aad9c144f4dede31']}   >10. Tramitar las consultas y reclamos formulados.</p>
<p custom-agua-item="text" className={styles['text-359-e732add4a1674c91b8fb17adaa9f32f9']}   >11. Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio (SIC).</p>
<p custom-agua-item="text" className={styles['text-360-ce90df52f43143048dc0ed46d01c8f1f']}   >12. Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.</p>
<p custom-agua-item="text" className={styles['text-361-f59dba7e2ef34e849d4dbf0a60fa35a3']}   >13. Informar a solicitud del titular sobre el uso dado a sus datos.</p>
<p custom-agua-item="text" className={styles['text-362-b4ab038510fc49abb8d64975fffbc234']}   >14. Informar al encargado del tratamiento cuando determinada información se encuentra en discusión por parte del titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo. Informar a la Superintendencia de Industria y Comercio (SIC) cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</p>
<p custom-agua-item="text" className={styles['text-363-7e1634bf05ea444cb190b92b3ef40a0c']}   >15. Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</p>
<p custom-agua-item="text" className={styles['text-364-80e95ea607b14e1987b57f68c5513d54']}   >Deber de Secreto y Confidencialidad.</p>
<p custom-agua-item="text" className={styles['text-365-2b822b895636472a93a34f20858a5d45']}   >VAI garantiza y exige a toda persona que intervenga en cualquier fase del tratamiento de los datos de carácter personal privado, sensible o de menores, el secreto profesional, respecto de los mismos y al deber de guardarlos, obligaciones que subsistirán aún después de finalizar sus relaciones contractuales con VAI.</p>
<p custom-agua-item="text" className={styles['text-366-7daf86e3dd6643f48c9458e8efb97037']}   >El incumplimiento del deber de secreto será sancionado de conformidad con lo previsto en la legislación vigente.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['generalidades-f422303912ab4203a069c42f686ba4a2']}   >
<p custom-agua-item="text" className={styles['text-367-6ff059afc4c243099584c67b8d39de1e']}   >Generalidades sobre la autorización</p>
<p custom-agua-item="text" className={styles['text-368-65a8cad675464d9c9e282ac357392519']}   >Cuando se trate de datos diferentes a los de naturaleza pública, definidos en el numeral 2 del artículo 3 del Decreto Reglamentario 1377 de 2013, VAI solicitará a más tardar en la recolección de la información, la autorización al Titular para el tratamiento de datos personales por cualquier medio físico o electrónico que permita ser utilizado como prueba. Según el caso, dicha autorización puede ser parte de un documento más amplio como, por ejemplo, de un contrato, o de un documento específico (formato, formulario, otrosí, entre otros.)</p>
<p custom-agua-item="text" className={styles['text-369-13c5e1f6c3f04acca2ff33cf942515c7']}   >En caso de tratarse de datos de carácter personal privados correspondientes a personas naturales, la descripción de la finalidad del tratamiento de los datos se informará mediante el mismo documento específico o adjunto. VAI informará al Titular de los datos lo siguiente:</p>
<p custom-agua-item="text" className={styles['text-370-ff376d5d3bf14aa59392ec5513512bca']}   >1. El tratamiento al que serán sometidos sus datos personales y la finalidad específica del mismo.</p>
<p custom-agua-item="text" className={styles['text-371-2494c0585d1e486391410c7c74a46734']}   >2. Los derechos que le asisten como Titular.</p>
<p custom-agua-item="text" className={styles['text-372-0f465c2fb88e4bb4927c0675bf29b4d6']}   >3. El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes.</p>
<p custom-agua-item="text" className={styles['text-373-ffb1dec9c9b54eb1ba7ee29f5f555e4a']}   >4. La página web, correo electrónico, dirección física y demás canales de comunicación por los cuales podrá formular consultas y/o reclamos ante el responsable o encargado del tratamiento.</p>
<p custom-agua-item="text" className={styles['text-374-83e77edd16644acb93bd352438d11408']}   >Medio y manifestaciones para otorgar la autorización.</p>
<p custom-agua-item="text" className={styles['text-375-540e0711d5464b0baf29158ecc2b5a85']}   >La autorización que otorgue el Titular puede constar en un documento físico, electrónico, mensaje de datos, internet, sitios web, en cualquier otro formato que permita garantizar su posterior consulta, o mediante un mecanismo técnico o tecnológico idóneo, que permita manifestar u obtener el consentimiento haciendo clic o seleccionando en algún formulario o formato la aceptación, mediante el cual se pueda concluir de manera inequívoca, que de no haberse surtido una conducta del Titular, los datos nunca hubieren sido capturados y almacenados en la base de datos. La autorización será generada por VAI y será puesto a disposición del titular con antelación y de manera previa al tratamiento de sus datos personales.</p>
<p custom-agua-item="text" className={styles['text-376-ed4564af254a41f3846e7d985c3bdb69']}   >Prueba de la autorización.</p>
<p custom-agua-item="text" className={styles['text-377-695872c595e242eab6e3bc6b54c2ea76']}   >VAI utilizará los mecanismos con que cuenta actualmente, e implementará y adoptará las acciones tendientes y necesarias para mantener registros o mecanismos técnicos o tecnológicos idóneos de cuándo y cómo obtuvo autorización por parte de los Titulares de datos personales para el tratamiento de los mismos. Para dar cumplimiento a lo anterior, se podrán establecer archivos físicos o electrónicos realizados de manera directa o a través de terceros contratados para tal fin.</p>
<section custom-agua-item="layer" className={styles.sobrelosderechosdelostitulares}   >
<p custom-agua-item="text" className={styles['text-384-626f4c1bf9dc498fa73d834001429bd7']}   >Sobre los derechos de los Titulares.</p>
<p custom-agua-item="text" className={styles['text-378-312409f747844409866d9681c99055c0']}   >Derecho de acceso.</p>
<p custom-agua-item="text" className={styles['text-379-a6d541329a66462da82db17f0f7b1ccc']}   >VAI garantiza el derecho de acceso conforme a la Ley 1581 de 2012, solamente a los Titulares de datos personales privados que correspondan a personas naturales, previa acreditación de la identidad del titular, legitimidad, o personalidad de su representante, poniendo a disposición de éste, sin costo o erogación alguna, de manera pormenorizada y detallada, los respectivos datos personales tratados, a través de cualquier medio de comunicación, incluyendo los electrónicos que permitan el acceso directo del titular.</p>
<p custom-agua-item="text" className={styles['text-380-aaea52260d0e4613adb836db4f2954ff']}   >En contexto a lo anterior, el acceso se sujeta a los límites establecidos en el artículo 21 del Decreto Reglamentario 1377 de 2013. Respecto al derecho de acceso a los datos personales contenidos en los registros públicos, este se regirá por lo establecido en el Código de Comercio y las demás normas que regulan esta materia.</p>
<p custom-agua-item="text" className={styles['text-381-8ec67c310bd84bf2b401997d04279164']}   >Derecho de consulta.</p>
<p custom-agua-item="text" className={styles['text-382-96919e6719614ee1b424fa23efad95c1']}   >Los Titulares de los datos personales podrán consultar la información de carácter personal que repose en cualquier base de datos de VAI, excepto las bases de datos de los registros públicos, cuya consulta atenderá a lo dispuesto por el Código de Comercio y las demás normas que regulen sobre esta materia.</p>
<p custom-agua-item="text" className={styles['text-383-1b32f7aaf05d4ea19ab931a6999f63f8']}   >En virtud de lo anterior, VAI garantiza el derecho de consulta conforme a lo dispuesto en la Ley 1581 de 2012 exclusivamente sobre los datos personales privados, sensibles y de menores correspondientes a personas naturales, suministrando a los titulares de estos datos personales la información contenida en cada una de las bases de datos correspondientes y que estén bajo el control de VAI.</p>
<p custom-agua-item="text" className={styles['text-385-a6e5865d5f294c7dbe785fd9cd56f888']}   >VAI establecerá las medidas de autenticación que permiten identificar de manera segura al Titular de los datos personales que realiza la consulta o petición. Esta obligación no aplica para las bases de datos de los registros públicos que pueda administrar VAI.</p>
<p custom-agua-item="text" className={styles['text-386-a66b86f0b6914743b8812f8f96953bce']}   >Con respecto a la atención de solicitudes de consulta de datos personales que no sean los contenidos en las bases de datos de registros públicos, VAI garantiza:</p>
<p custom-agua-item="text" className={styles['text-387-7bffbd62a42a46d29a60005ef4a7e9b0']}   >Habilitar medios de comunicación electrónica u otros que considere pertinentes y seguros;</p>
<p custom-agua-item="text" className={styles['text-388-03a5855d8b724f768ecbd16dd8a436d8']}   >Establecer formularios, sistemas y otros métodos que se informarán en el Aviso de Privacidad;</p>
<p custom-agua-item="text" className={styles['text-389-2aa23348ff2247cda173d9ae05d05f39']}   >Utilizar los servicios de reclamaciones que se encuentren en operación.</p>
<p custom-agua-item="text" className={styles['text-390-ec71803bc47b4afe96de50217889e61e']}   >Independientemente del mecanismo implementado para la atención de solicitudes de consulta, éstas serán tramitadas en un término máximo de diez (10) días hábiles contados a partir de la fecha de su recibo. En el evento en el que una solicitud de consulta no pueda ser atendida dentro del término antes señalado, se informará al interesado antes del vencimiento del plazo las razones por las cuales no se ha dado respuesta a su consulta, la cual en ningún caso podrá superar los cinco (05) días hábiles siguientes al vencimiento del primer término.</p>
<p custom-agua-item="text" className={styles['text-391-39d984ccbaac4e608a4134387924e068']}   >Derecho a reclamar.</p>
<p custom-agua-item="text" className={styles['text-392-66c110b29918457b9a6cd0c2b9e0a3ca']}   >El Titular de datos personales privados que correspondan a una persona natural y considere que la información contenida o almacenada en una base de datos que no corresponda a los registros de VAI, puede ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes y principios contenidos en la normatividad sobre protección de datos personales. De esta forma, podrán presentar reclamación ante el responsable o encargado del tratamiento de VAI.</p>
<p custom-agua-item="text" className={styles['text-393-dfbc8b7a775f444e960b40044697372a']}   >El reclamo lo podrá presentar el Titular, teniendo en cuenta la información señalada en el artículo 15 de la Ley 1581 de 2012. Si el reclamo estuviese incompleto, el Titular lo podrá completar dentro de los cinco (5) días hábiles siguientes a la recepción del reclamo, para que subsane las fallas o errores.</p>
<p custom-agua-item="text" className={styles['text-394-4cc4f5c14b3b4fc093184ff6a030be0f']}   >Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información solicitada, se entenderá que ha desistido del reclamo.</p>
<p custom-agua-item="text" className={styles['text-395-f34ed6365ecf4c4d914ea51eaaec2ac5']}   >En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.</p>
<p custom-agua-item="text" className={styles['text-396-034ba161763e4303a865e14021e1cc6e']}   >Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga «reclamo en trámite» y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.</p>
<p custom-agua-item="text" className={styles['text-397-a1d19b14eab34400a055d8f928a90f95']}   >El término máximo para resolver la reclamación es de quince (15) días hábiles, contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, VAI informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
<p custom-agua-item="text" className={styles['text-398-6bbdaeb43168411996a971b7554891b5']}   >Derecho a la rectificación y actualización de datos.</p>
<p custom-agua-item="text" className={styles['text-399-04574c195be74f1f8e80815fae3ad253']}   >VAI se obliga a rectificar y actualizar a solicitud del Titular, la información de carácter personal que corresponda a personas naturales, que resulte incompleta o inexacta, de conformidad con el procedimiento y los términos antes señalados. Lo anterior, siempre y cuando, no se trate de datos contenidos en los registros públicos, los cuales se regirán por la normativa especial. Al respecto, VAI tendrá en cuenta lo siguiente:</p>
<p custom-agua-item="text" className={styles['text-400-bd3c5cc8951e44489b1661b3ab53634f']}   >En las solicitudes de rectificación y actualización de datos personales, el Titular debe indicar las correcciones a realizar y aportar la documentación que avale su petición.</p>
<p custom-agua-item="text" className={styles['text-401-1e1ee35ceda64ba18c16671605e6531e']}   >VAI tiene plena libertad de habilitar mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando beneficien al Titular de los datos personales. En consecuencia, se podrán habilitar medios electrónicos u otros que VAI considere pertinentes y seguros.</p>
<p custom-agua-item="text" className={styles['text-402-8af369c943614fcdb9dc3538ebb51ac5']}   >VAI, podrá establecer formularios, formatos, sistemas y otros métodos, que serán informados en el Aviso de Privacidad y que se pondrán a disposición de los interesados en la página web u oficinas de VAI.</p>
<p custom-agua-item="text" className={styles['text-403-2c6d64f172ed4b35aafd6d18c27b31e3']}   >Derecho a la supresión de datos.</p>
<p custom-agua-item="text" className={styles['text-404-e4952279ba9f4da0b1ee6d3b71fbde2e']}   >El Titular de datos personales tiene el derecho en todo momento, a solicitar a VAI, la supresión (eliminación) de sus datos personales, siempre y cuando, no se trate de datos contenidos en los registros públicos, los cuales se regirán por la normativa especial. Para el resto de los datos, se tendrá en cuenta los siguientes supuestos:</p>
<p custom-agua-item="text" className={styles['text-405-bc2c8e47365b4a45b49cc0a03d3d40b5']}   >Que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente sobre protección de datos personales.</p>
<p custom-agua-item="text" className={styles['text-406-1976aaa8346c481abf06719fae9d2744']}   >Que hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recabados.</p>
<p custom-agua-item="text" className={styles['text-407-babb90f8fd5540b799e1b12567983cae']}   >Que se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron recogidos.</p>
<p custom-agua-item="text" className={styles['text-408-309804fdcaf14a548a0195e4cef7d41b']}   >Esta supresión implica la eliminación o borrado seguro, total o parcial, de la información personal de acuerdo con lo solicitado por el Titular en los registros, archivos, bases de datos o tratamientos realizados por VAI.</p>
<p custom-agua-item="text" className={styles['text-409-258147790dc44a2d816b43ac1fc14385']}   >El derecho de supresión no es un derecho absoluto, y VAI como responsable del tratamiento de datos personales, puede negar o limitar el ejercicio del mismo cuando:</p>
<p custom-agua-item="text" className={styles['text-410-053c445ed8264f0f90ac8df3ed1fb963']}   >El Titular de los datos tenga el deber legal o contractual de permanecer en la base de datos.</p>
<p custom-agua-item="text" className={styles['text-411-beedd15af63c4e92991a0e7499d45c93']}   >La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</p>
<p custom-agua-item="text" className={styles['text-412-f6878493974e4267b1fbc9ce41b10e3c']}   >Los datos sean necesarios para proteger los intereses jurídicamente tutelados del Titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.</p>
<p custom-agua-item="text" className={styles['text-413-e040c6d5404e44a9926456c08b9e6e98']}   >Los datos sean datos de naturaleza pública y correspondan a los registros públicos, los cuales tienen como finalidad su publicidad.</p>
<p custom-agua-item="text" className={styles['text-414-0dd76a71c00d4d1b8dd66638f7a3b7a6']}   >Derecho a revocar la autorización.</p>
<p custom-agua-item="text" className={styles['text-415-624f024ebab74f1ba991b7df87153e10']}   >Todo Titular de datos personales que correspondan a personas naturales, puede revocar en cualquier momento, el consentimiento al tratamiento de éstos, siempre y cuando, no lo impida una disposición legal o contractual. Para ello, VAI ha establecido mecanismos sencillos y gratuitos que le permiten al Titular revocar su consentimiento. Lo anterior, será posible solo cuando no se trate de datos contenidos en los registros públicos.</p>
<p custom-agua-item="text" className={styles['text-416-e0eff2bd9af242fc9453007b60124a3e']}   >En los casos que sea posible la revocatoria de la autorización, se atenderá bajo las siguientes dos modalidades:</p>
<p custom-agua-item="text" className={styles['text-417-1c406dde949d40e8b2df18fd59a1d815']}   >1. Total: Sobre la totalidad de finalidades consentidas, esto es, que VAI debe dejar de tratar por completo los datos del Titular de datos personales.</p>
<p custom-agua-item="text" className={styles['text-418-69414c85a10e46c79a5267b0270b3f1d']}   >2. Parcial: Sobre ciertas finalidades consentidas como por ejemplo para fines publicitarios, marketing o de estudios de mercado. En este caso, VAI deberá suspender parcialmente el tratamiento de los datos del Titular. Se mantienen entonces otros fines del tratamiento que el responsable, de conformidad con la autorización otorgada, puede llevar a cabo y con los que el titular está de acuerdo.</p>
<p custom-agua-item="text" className={styles['text-419-8c2d13dc3c2542268482388e3027ee0f']}   >El derecho de revocatoria no es un derecho absoluto y VAI como responsable del tratamiento de datos personales, puede negar o limitar el ejercicio del mismo cuando:</p>
<p custom-agua-item="text" className={styles['text-420-29e05884ae254beea14f94836dd9436c']}   >El Titular de los datos tenga el deber legal o contractual de permanecer en la base de datos.</p>
<p custom-agua-item="text" className={styles['text-421-7fd78a6e58a54d5ca3739a790246555b']}   >La revocatoria de la autorización del tratamiento obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</p>
<p custom-agua-item="text" className={styles['text-422-3ce5a59d8e1c467fb78b48846c5af374']}   >Los datos sean necesarios para proteger los intereses jurídicamente tutelados del Titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.</p>
<p custom-agua-item="text" className={styles['text-423-2568c9067a9a44d0bec7129496ca23a5']}   >Los datos sean datos de naturaleza pública y correspondan a los registros públicos, los cuales tienen como finalidad su publicidad.</p>
<p custom-agua-item="text" className={styles['text-424-9e186aeba9ff4a2dbb1cfbf6d79e8653']}   >Protección de datos en los contratos</p>
<p custom-agua-item="text" className={styles['text-425-6478a23777874fdea3a5ffa432b2a0c6']}   >En los contratos que celebre VAI ha incluido cláusulas con el fin de autorizar de manera previa y general el tratamiento de datos personales relacionados con la ejecución del contrato, lo que incluye la autorización de recolectar, modificar o corregir, en momentos futuros, datos personales del Titular correspondientes a personas naturales. También ha incluido la autorización para que algunos de los datos personales, en caso dado, puedan ser entregados o cedidos a terceros con los cuales VAI tenga contratos de prestación de servicios, para la realización de tareas tercerizadas.</p>
<p custom-agua-item="text" className={styles['text-426-ac5fbf3f15d340cd88c6f76157a3d31a']}   >En los contratos de prestación de servicios externos, cuando el contratista requiera de datos personales, VAI le suministrará dicha información siempre y cuando exista una autorización previa y expresa del Titular de los datos personales para esta transferencia, quedando excluida de esta autorización, los datos personales de naturaleza pública definido en el numeral 2° del artículo 3° del Decreto Reglamentario 1377 de 2013 y los contenidos en los registros públicos.</p>
<p custom-agua-item="text" className={styles['text-427-0ec8922b157c499ea9d099b3a50e48bc']}   >Dado que en estos casos, los terceros son encargados del tratamiento de datos y sus contratos incluirán cláusulas que precisan los fines y los tratamientos autorizados por VAI y delimitan de manera precisa el uso que estos terceros le pueden dar a aquellos, así como las obligaciones y deberes establecidos en la Ley 1581 de 2012 y el Decreto Reglamentario 1377 de 2013, incluyendo las medidas de seguridad necesarias que garanticen en todo momento la confidencialidad, integridad y disponibilidad de la información de carácter personal encargada para su tratamiento.</p>
<p custom-agua-item="text" className={styles['text-428-9f91bc9b8b3e4a9797e314da61ce5ea8']}   >Por su parte, VAI al momento de recibir datos de terceros y actuar como encargada del tratamiento de datos de carácter personal, verifica que la finalidad, o finalidades, de los tratamientos autorizados por el Titular o permitidos por las causas legales, contractuales o jurisprudenciales se encuentran vigentes y que el contenido de la finalidad esté relacionada con la causa por la cual se va a recibir dicha información personal de parte del tercero, pues solo de este modo estará facultado para recibir y tratar dichos datos personales.</p>
<p custom-agua-item="text" className={styles['text-429-adec8b2237ad44bc9be4d42ca26b857c']}   >Transferencia de datos personales a terceros países.</p>
<p custom-agua-item="text" className={styles['text-430-d42631cc69b04449ad3e17804696ef22']}   >En los casos en que VAI en desarrollo de su objeto social, como puede ser la de participar en programas internacionales para el desarrollo económico, financiero, cultural y social, o cualquier otra actividad que implique la transferencia de datos de carácter personal a terceros países, se regirá por las siguientes condiciones:</p>
<p custom-agua-item="text" className={styles['text-431-9d941dae63c6482fa83abaf4199312d8']}   >La transferencia de datos personales a terceros países solamente se realizará cuando exista autorización correspondiente del Titular y previa autorización de la delegatura de Datos Personales de la SIC.</p>
<p custom-agua-item="text" className={styles['text-432-90078ddcc07545ab88b9dcc8c37133d6']}   >Se considera una transferencia internacional cualquier tratamiento que suponga una transmisión de datos fuera del territorio colombiano, tanto si se realiza una cesión de datos, como si tuviera por objeto la prestación de un servicio al responsable fuera de Colombia.</p>
<p custom-agua-item="text" className={styles['text-433-7198299503dd49b5ad9dd00b3a69a97e']}   >Asimismo, se debe obtener la autorización previa del delegado de Protección de Datos Personales de la Superintendencia de Industria y Comercio SIC, cuando se tenga previsto realizar transferencias internacionales de datos a países que no proporcionan un cierto nivel de protección. Esta autorización sólo podrá ser otorgada si se obtienen garantías adecuadas, como los contratos basados en las cláusulas tipo aprobadas por la Superintendencia de Industria y Comercio (SIC), o las Reglas Corporativas Vinculantes.</p>
<p custom-agua-item="text" className={styles['text-434-ff118c62d2af44078c22768698dac8eb']}   >La transferencia internacional de datos se podrá realizar mediante solicitud de VAI, estableciendo la finalidad, los colectivos de interesados o titulares de la información de carácter personal, los datos objeto de transferencia y la documentación que incorpore las garantías exigibles para la obtención de la autorización; en la que conste una descripción de las medidas de seguridad concretas que van a ser adoptadas, tanto por VAI como por el Responsable o Encargado de los datos en su lugar de destino.</p>
<p custom-agua-item="text" className={styles['text-435-295424a281064b408c514cd48ea720e7']}   >VAI no solicitará la autorización cuando la transferencia internacional de datos se encuentre amparada en alguna de las excepciones previstas en la Ley y su Decreto Reglamentario. Un ejemplo de ello es el consentimiento del afectado a la transferencia, cuando la transferencia es necesaria para la ejecución de un contrato entre el Titular y el responsable del tratamiento, o para la ejecución de medidas precontractuales siempre y cuando se cuente con la autorización del titular.</p>
<p custom-agua-item="text" className={styles['text-436-70cec96f7a444bdabb04cb93568488c3']}   >Reglas generales aplicables.</p>
<p custom-agua-item="text" className={styles['text-437-3ac9769f31434de6b4352a8ec860cc7c']}   >VAI ha establecido las siguientes reglas generales para la protección de datos personales, sensibles y de menores, como es el cuidado de bases de datos, ficheros electrónicos e información personal.</p>
<p custom-agua-item="text" className={styles['text-438-c15d7c03414744e383794c52a5d8e6d2']}   >VAI obtendrá y utilizará los datos personales que en efecto se requieran para el desarrollo de su objeto, para cumplir con sus deberes legales y para atender en debida forma la relación que establezca con el titular del dato; de forma tal que evitará solicitar información ajena a dichos propósitos.</p>
<p custom-agua-item="text" className={styles['text-439-ec6b1fc94476428b8bf7c832991ae031']}   >VAI utilizará los datos personales conforme a las finalidades con las que tales datos sean obtenidos.</p>
<p custom-agua-item="text" className={styles['text-440-cd5e4933c5994ea3a603d22a8f525470']}   >Tales datos personales corresponderá a los de sus clientes, colaboradores, proveedores, administradores, aliados, socios y en general a los de todas aquellas personas naturales con las que VAI se relaciona para el cumplimiento pleno de su objeto.</p>
<p custom-agua-item="text" className={styles['text-441-e063bef9595d44648b6d838291837aaa']}   >VAI obtendrá y utilizará datos personales siempre que se encuentre facultada para ello, bien porque la ley así lo dispone, bien porque ello se deriva de la naturaleza de la relación que tiene con el titular de los datos o bien por que el titular del dato la autoriza expresamente para el efecto.</p>
<p custom-agua-item="text" className={styles['text-442-0801b06dbb134091bceb05c3fcf9e646']}   >Cuando quiera que se precise autorización del Titular para el tratamiento de sus datos personales:</p>
<p custom-agua-item="text" className={styles['text-443-df1ac734eaac46afa1621e424cd5fec9']}   >VAI informará al Titular sobre la finalidad de tal tratamiento y obtendrá de él su consentimiento expreso e informado.</p>
<p custom-agua-item="text" className={styles['text-444-860e7034a7c2480f8853cfcabfd05272']}   >La autorización se obtendrá en forma previa al tratamiento de los datos personales y, en todo caso, a más tardar al momento de la recolección inicial de tal información.</p>
<p custom-agua-item="text" className={styles['text-445-a7cc653b5be44aff9316ac5fc220517f']}   >La autorización podrá darse a través de cualquier medio que permita su adecuada conservación, así como su consulta posterior.</p>
<p custom-agua-item="text" className={styles['text-446-127c442cf2e741c78ea6cec1e50aaf68']}   >VAI implementará mecanismos que le permitan tener a disposición de los Titulares la información de sus datos personales, las finalidades para las que han sido tratados y el tratamiento que se ha dado a los mismos.</p>
<p custom-agua-item="text" className={styles['text-447-a11abb04b8e64defac101d56c2231e9b']}   >En los eventos en que VAI requiera utilizar datos personales para una finalidad distinta a la inicialmente informada a su Titular y autorizada por éste o diferente a la señalada en la Ley o ajena a la naturaleza de la relación que lo vincula con VAI, se deberá obtener del Titular de los datos una nueva autorización. VAI no tendrá que solicitar una nueva autorización al Titular cuando, conforme al ordenamiento jurídico, el nuevo uso sea razonablemente previsible por parte el Titular del dato al momento de consentir su utilización, en el marco de su relación con</p>
<p custom-agua-item="text" className={styles['text-448-763d802628f44bf0af7006a15a352b81']}   >VAI ha previsto que siempre que los datos personales sean suministrados por un tercero, ese tercero debe contar con la autorización del Titular que le permita compartir la información VAI o estar amparado en la ley para ello.</p>
<p custom-agua-item="text" className={styles['text-449-31bb88a5526b42d08a7903055c0f11f0']}   >En los casos en los que VAI contacte al Titular del dato a partir de los datos públicos que haya obtenido, entendidos éstos como aquellos para cuyo tratamiento no se requería la autorización previa del mismo, VAI deberá contar con autorización, bien dada por la ley o como consecuencia de la naturaleza de la relación con el Titular o bien por solicitar del Titular su autorización para obtener datos adicionales, caso en el cual le informará acerca de las finalidades para las que serán tratados los nuevos datos que le sean suministrados.</p>
<p custom-agua-item="text" className={styles['text-450-f298a768f71a4d2faec149dd37f951e4']}   >En el tratamiento de datos de menores de edad, VAI tendrá en cuenta el interés superior de los mismos, así como la prevalencia de sus derechos. Adicionalmente, en los eventos en que los derechos de tales menores sean ejercidos por un tercero, VAI verificará que ese tercero esté autorizado conforme a la ley para ello.</p>
<p custom-agua-item="text" className={styles['text-451-b55db214c6f84969b258e8fafa208c65']}   >En el evento en que la información recopilada corresponda a datos sensibles, entendidos éstos como aquellos que afectan su intimidad o cuyo uso indebido puede generar su discriminación, VAI debe informar al Titular de la misma acerca del carácter de sensible de los datos que suministra y acerca de la posibilidad que tiene de suministrar o no dicha información.</p>
<p custom-agua-item="text" className={styles['text-452-52aa594ce04241ffb2085ba9672ec77a']}   >VAI no condicionará la existencia y el mantenimiento de su relación con el Titular al suministro de datos sensibles por parte de éste, a menos que tales datos en efecto deban obtenerse por ser indispensables para la existencia y/o mantenimiento adecuado de la relación o para el cumplimiento de los deberes de VAI y/o del Titular.</p>
<p custom-agua-item="text" className={styles['text-453-1eb3ca8416c141e980da3e8f27557b3b']}   >VAI velará porque los datos personales que se encuentran en sus archivos y bases de datos sean almacenados y administrados en razonables condiciones de seguridad y confidencialidad.</p>
<p custom-agua-item="text" className={styles['text-454-fccc57c5664a48e8aa874e46adf4ea73']}   >Son confidenciales los datos personales, excepto aquellos que tienen el carácter de públicos. La información clasificada como confidencial debe ser conocida y manejada exclusivamente por los colaboradores autorizados por VAI para ello.</p>
<p custom-agua-item="text" className={styles['text-455-fbf7f21395434652a4018db6b024a232']}   >Es responsabilidad de todos los colaboradores de VAI velar por la confidencialidad y seguridad de dicha información y velar porque los terceros que acceden a la misma (proveedores o contratistas) también se responsabilicen de ella.</p>
<p custom-agua-item="text" className={styles['text-456-de6c3147671a47ceacd29d3261b2caa3']}   >El deber de reserva de los colaboradores frente a los datos personales a los que tengan acceso se extiende después de finalizada la actividad realizada por éste en relación con el tratamiento.</p>
<p custom-agua-item="text" className={styles['text-457-d20b271671a14dbb8ebccec6f42d8222']}   >VAI informará a las autoridades competentes en los términos que señala la ley las situaciones relevantes relativas a la administración de los datos personales que son objeto de tratamiento por parte de ella.</p>
<p custom-agua-item="text" className={styles['text-458-1b58abe9a6614f2ebbb3c34b07c0d342']}   >VAI conservará los archivos o bases de datos que contengan datos personales por el período que la normatividad vigente así se lo exija o lo permita y la vigencia de las bases de datos estará atada al ejercicio del objeto social de VAI.</p>
<p custom-agua-item="text" className={styles['text-459-624e09c3cef740e1b4591816b461cad4']}   >Sin perjuicio de lo anterior, el período mínimo de conservación de los datos personales del Titular corresponderá al término de duración de su relación legal o contractual con esta o a aquel que sea requerido para que VAI cumpla con sus obligaciones o aquel necesario para que se puedan ejercer los derechos por parte del Titular del dato en el marco de la naturaleza de la relación que los vincula.</p>
<p custom-agua-item="text" className={styles['text-460-31b295708fbb422cb6673661c9a10a6c']}   >VAI velará porque se registren en los términos de la normatividad vigente, las bases de datos que contengan datos personales objeto de tratamiento por su parte.</p>
<p custom-agua-item="text" className={styles['text-461-e81df0fc61cb40bd8fe6cdab31bffaa0']}   >VAI incorporará a sus procesos los mecanismos para que los Titulares de datos personales que administra puedan conocer, actualizar, rectificar y suprimir sus datos personales, así como para revocar la autorización que para su utilización tales Titulares han otorgado, siempre que tal revocación conforme al ordenamiento jurídico proceda, es decir, siempre que con la revocación de la autorización no se vulnera un deber legal o contractual.</p>
<p custom-agua-item="text" className={styles['text-462-341b27e424a0423a8fce9664a2c9af3d']}   >Igualmente, los procedimientos previstos por VAI preverán la divulgación de la presente política y de sus modificaciones en forma adecuada y oportuna.</p>
<p custom-agua-item="text" className={styles['text-463-5a80a851ee3d433385cf8adc185e0e95']}   >Los procedimientos se ajustarán de tal forma que las consultas o reclamos de los Titulares sean atendidos en forma clara, sencilla y oportuna, y en todo caso, en un término que no podrá superar el previsto en la normatividad vigente. VAI velará por la suficiencia y claridad de las respuestas a tales consultas o reclamos.</p>
<p custom-agua-item="text" className={styles['text-464-a8eed5d76a9f4c27bf3a8a70c8d11a29']}   >Adicionalmente, en los procedimientos internos VAI adoptará medidas de seguridad con el fin de: (i) evitar el daño, pérdida, alteración, hurto o destrucción de los datos personales, lo cual implica velar por la correcta operación de los procesos operativos y tecnológicos relacionados con esta materia; (ii) prevenir el uso, acceso o tratamiento no autorizado de los mismos, para lo cual se preverán niveles de acceso y circulación restringida de dicha información; (iii) incorporar los criterios de seguridad de los datos personales como parte integral de la adquisición, desarrollo y mantenimiento de los sistemas de información.</p>
<p custom-agua-item="text" className={styles['text-465-48d1bab1fc6648d3ba84b8278638b16d']}   >Es responsabilidad de VAI la implementación de estas políticas.</p>
</section>
<section custom-agua-item="layer" className={styles.peticionesconsultasyreclamos}   >
<p custom-agua-item="text" className={styles['text-466-432079e02c0248ce8130fb37f8eafd10']}   >Atención de peticiones, consultas y reclamos</p>
<p custom-agua-item="text" className={styles['text-467-b36092824ce54319aeb6d9f4ed725a39']}   >Vai tiene a cargo dar trámite a las solicitudes de los Titulares para hacer efectivos sus derechos. </p>
<p custom-agua-item="text" className={styles['text-468-66e96674d35e45ba9f07e806d0a5a533']}   >VAI recibirá y atenderá las peticiones, consultas y reclamos que el titular de la información presente a través de los siguientes canales de atención:</p>
<p custom-agua-item="text" className={styles['text-471-0aa4cb80e9a2489fb6dd45f0307359c8']}   >Correspondencia Física: Cra. 16a #75-73</p>
<section custom-agua-item="layer" className={styles['layer-267-02a0d27cb1d844619e617a91152b08a1']}   >
<p custom-agua-item="text" className={styles['text-469-45d016476ded4f649ab450d869aefa47']}   >Teléfono:</p>
<p custom-agua-item="text" className={styles['text-470-fe8f3b2ea5aa4d20bb0abe51d790a3d2']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.derechodehabeasdata}   >
<p custom-agua-item="text" className={styles['text-472-fdc450b64b394e348bb34981192efc68']}   >Procedimiento para el ejercicio del derecho de habeas data</p>
<p custom-agua-item="text" className={styles['text-473-b68364b8030346d7ae5ec51afce543be']}   >Cualquier consulta o reclamo frente a derechos inherentes de los titulares sobre datos de carácter personal se debe realizar mediante comunicación dirigida a soporte@vai.com.co, adjuntando fotocopia del documento de identidad del titular interesado o cualquier otro documento equivalente que acredite su identidad y titularidad conforme a Derecho.</p>
<p custom-agua-item="text" className={styles['text-474-5330f0ba66d443dcb7a0c78aadb2d5cb']}   >Los derechos de acceso, actualización, rectificación, supresión y revocación de la autorización de datos personales son personalísimos y podrán ser ejercidos únicamente por el titular. No obstante, el titular podrá actuar a través de representante legal o apoderado cuando aquel se encuentre en situación de incapacidad o minoría de edad hechos que le imposibiliten el ejercicio personal de los mismos, en cuyo caso será necesario que el representante legal o apoderado acredite tal condición.</p>
<p custom-agua-item="text" className={styles['text-475-221c84a97df8434da8d098feaf0518d8']}   >No se exigirá ningún valor o tasa alguna por el ejercicio de los derechos de acceso, actualización, rectificación, supresión o revocación de la autorización cuando se trate de datos personales de personas naturales que no hagan parte de los registros públicos. Además, se tendrá en cuenta lo establecido en el artículo 21 del Decreto Reglamentario 1377 de 2013.</p>
<p custom-agua-item="text" className={styles['text-476-473a893800924e38895d0109aa1ff355']}   >Una vez cumplidos y agotados los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación, por parte de VAI, podrá poner en conocimiento ante la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales.</p>
<p custom-agua-item="text" className={styles['text-477-e310ba9b2e4341a6bd0e134b2838689b']}   >En cumplimiento de las normas sobre protección de datos personales, VAI presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos:</p>
<section custom-agua-item="layer" className={styles.consultas}   >
<p custom-agua-item="text" className={styles['text-478-609a785d312049bd87fa5afd9297ad33']}   >1) Consultas</p>
<p custom-agua-item="text" className={styles['text-479-f21520962062441e84f764cbadc55eb6']}   >Requisitos para la atención de consultas.</p>
<p custom-agua-item="text" className={styles['text-480-78d3a50b94994682bc76f28c18f3ef93']}   >Se debe enviar una comunicación sea física o electrónica, esta debe contener como mínimo la siguiente información:</p>
<p custom-agua-item="text" className={styles['text-481-7c0c4353895949c3aff7c2aa4e10a1ef']}   >Fecha de solicitud;</p>
<p custom-agua-item="text" className={styles['text-482-dd076de503a9403ea0baf7dbd0ea138a']}   >Nombre(s) y apellidos completos;</p>
<p custom-agua-item="text" className={styles['text-483-f9c3510d0f104845a2acda043ddff690']}   >Fotocopia de documento de identificación;</p>
<p custom-agua-item="text" className={styles['text-484-3a4f3f806da444c38da7147151f87323']}   >Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto);</p>
<p custom-agua-item="text" className={styles['text-485-e647232a38af48569db92d606adf89ae']}   >Medios para recibir respuesta a su solicitud;</p>
<p custom-agua-item="text" className={styles['text-486-3a742da89cc14dd7915c395daf3cb712']}   >Motivo(s), hecho(s) que dan lugar a la consulta;</p>
<p custom-agua-item="text" className={styles['text-487-6a448f83d1424fd3a889290d7272c1bb']}   >Firma (si aplica) y número de identificación;</p>
<p custom-agua-item="text" className={styles['text-488-51aaf13e752f4d3a80dfbda8471f86aa']}   >Si es el representante legal, adjuntar documento que acredite la representación, si es el caso.</p>
<p custom-agua-item="text" className={styles['text-489-58bf954529d64a7b9f5c3e8e30213301']}   >Para ejercer este derecho por medio electrónico, el Titular, su causahabiente, tercero autorizado o apoderado podrá formular la consulta por medio del correo electrónico destinado a soporte@vai.com.co donde se adjunte la información anteriormente relacionada.</p>
<p custom-agua-item="text" className={styles['text-490-bd49ad99ddf649cf9460344836db83dd']}   >La persona interesada en ejercer este derecho deberá en todo caso, utilizar un medio que permita acreditar el envío y la recepción de la solicitud. Cualquiera que sea el medio utilizado para ejercer este derecho; VAI atenderá la solicitud siempre y cuando se cumpla con los requisitos señalados anteriormente.</p>
<p custom-agua-item="text" className={styles['text-491-c683eb47cc6a43fc929a1ffa1ab615e9']}   >Términos para la atención de consultas.</p>
<p custom-agua-item="text" className={styles['text-492-a51e42a25caa4da58122242a925e073b']}   >Independientemente del mecanismo que se implemente para la atención de solicitudes de consulta, éstas serán atendidas en un término máximo de diez (10) días hábiles contados a partir de la fecha de su recibo. En el evento en el que una solicitud de consulta no pueda ser atendida dentro del término antes señalado, VAI informará al interesado antes del vencimiento del plazo las razones por las cuales no se ha dado respuesta a su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
</section>
<section custom-agua-item="layer" className={styles.reclamos}   >
<p custom-agua-item="text" className={styles['text-493-f22e7c1e250147dfa22e7edfb5bfd9c6']}   >2) Reclamos</p>
<p custom-agua-item="text" className={styles['text-805-31d27d0d001742a8b5659d6afbcf24d8']}   >El Titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la normatividad sobre Protección de Datos Personales, podrán presentar un reclamo ante el responsable del tratamiento.</p>
<p custom-agua-item="text" className={styles['text-806-3ef6c46ddfc6427da5b9537b57b89954']}   >El reclamo lo podrá presentar el Titular teniendo en cuenta la información señalada en el artículo 15 de la Ley 1581 de 2012.</p>
<p custom-agua-item="text" className={styles['text-807-26290aa542ec439182c09ba419e8fe0e']}   >Así mismo el Titular de la información puede ejercer en cualquier momento estos derechos, previo cumplimiento de los requisitos establecidos para ello por VAI. Al respecto, VAI tendrá en cuenta lo siguiente:</p>
<p custom-agua-item="text" className={styles['text-808-9ac20f84845e4c84896fa551edb62077']}   >1. En las solicitudes de rectificación y actualización de datos personales, el Titular debe indicar las correcciones a realizar y aportar la documentación que avale su petición.</p>
<p custom-agua-item="text" className={styles['text-809-d35a3fc458064477aec2e1c818ea7cdb']}   >2. VAI, tiene plena libertad de habilitar mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando beneficien al titular de los datos. En consecuencia, se podrán habilitar medios electrónicos u otros que VAI considere pertinentes.
</p>
<p custom-agua-item="text" className={styles['text-810-c4db49c0867e4f57b4e70005faf2475a']}   >3. VAI, podrá establecer formularios, sistemas y otros métodos, que serán informados en el Aviso de Privacidad y que se pondrán a disposición de los interesados en la página web.</p>
<p custom-agua-item="text" className={styles['text-811-febc206609214e478a501048244dcf2e']}   >4. Cuando la solicitud sea formulada por persona distinta al Titular y no se acredite que la misma actúa en representación legítima, se tomar como no presentada.</p>
<p custom-agua-item="text" className={styles['text-812-953eb50c2ad740799493b05b80756fcd']}   >El Titular de datos personales tiene el derecho, en todo momento, a solicitar a VAI, la supresión (eliminación) de sus datos personales cuando:</p>
<p custom-agua-item="text" className={styles['text-813-46f262c6b7184f8da1374c09c8597c4e']}   >1. Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente.</p>
<p custom-agua-item="text" className={styles['text-814-6003e5cda5ce44bf86b957a633edb262']}   >2. Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron suministrados.</p>
<p custom-agua-item="text" className={styles['text-815-165eaf2652bb4f7b8a452152dfb7d7f1']}   >3. Se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron entregados.</p>
<p custom-agua-item="text" className={styles['text-816-3a44f5e6c27948c1a3dfa0bc319e8dd6']}   >Esta supresión implica la eliminación total o parcial de la información personal de acuerdo con lo solicitado por el Titular en los registros, archivos, bases de datos o tratamientos realizados, conforme a lo establecido en las normas de protección de datos personales y a esta política.</p>
<p custom-agua-item="text" className={styles['text-817-67a3b7530441451c9359a83045e68302']}   >Requisitos para la atención de Reclamos, Rectificaciones, Actualizaciones o Supresión de datos.</p>
<p custom-agua-item="text" className={styles['text-818-352d5a8fa79e4ec8b1f4bc0b4c14a1eb']}   >Se debe enviar una comunicación sea física o electrónica, esta debe contener como mínimo la siguiente información:</p>
<p custom-agua-item="text" className={styles['text-819-c33503715a6e42d99a85dc019797b12d']}   >Fecha de solicitud;</p>
<p custom-agua-item="text" className={styles['text-820-210ebce678c44a3f883d64548f2eea78']}   >Nombre(s) y apellidos completos;</p>
<p custom-agua-item="text" className={styles['text-821-faa60989f8984bd2bbf7b24240b4e289']}   >Fotocopia de documento de identificación;</p>
<p custom-agua-item="text" className={styles['text-822-f5d12772596c4e40ada6d8f3a899f716']}   >Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto);</p>
<p custom-agua-item="text" className={styles['text-823-f2dfb4e867b840acb2610a26e1d45aa3']}   >Medios para recibir respuesta a su solicitud;</p>
<p custom-agua-item="text" className={styles['text-824-1b5fde9aa43c48cab524a3f1802e0570']}   >Motivo(s), hecho(s) que dan lugar a la solicitud con una descripción clara y detallada del reclamo, rectificación actualización o supresión de dato;</p>
<p custom-agua-item="text" className={styles['text-825-aa4c60c836a84ded8c697315efdd6d55']}   >Firma (si aplica) y número de identificación;</p>
<p custom-agua-item="text" className={styles['text-826-77d67554c4524fb6a3b80d1f8e31f63e']}   >Si es el representante legal, adjuntar documento que acredite la representación, si es el caso.</p>
<p custom-agua-item="text" className={styles['text-827-b6dd89ebf104482ba8128a7fdcd8f167']}   >Para ejercer este derecho por medio electrónico, el Titular, su causahabiente, tercero autorizado o apoderado podrá formular la consulta por medio del correo electrónico destinado a soporte@vai.com.co donde se adjunte la información anteriormente relacionada.</p>
<p custom-agua-item="text" className={styles['text-828-eb08a9fadf14492dabc455334e581856']}   >La persona interesada en ejercer este derecho deberá en todo caso, utilizar un medio que permita acreditar el envío y la recepción de la solicitud. Cualquiera que sea el medio utilizado para ejercer este derecho; VAI atenderá la solicitud siempre y cuando se cumpla con los requisitos señalados anteriormente.</p>
<p custom-agua-item="text" className={styles['text-829-7744d1dc601149179c872c6669dbf927']}   >Términos para la atención de Reclamos.</p>
<p custom-agua-item="text" className={styles['text-830-6509611c254043db9c984a590d9c8773']}   >El término máximo para atenderlo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, VAI informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
</section>
<section custom-agua-item="layer" className={styles.revocatoriaautorizacion}   >
<p custom-agua-item="text" className={styles['text-831-93737fc551384e70afe9a54d895de179']}   >3) Revocar autorización</p>
<p custom-agua-item="text" className={styles['text-832-8dfad1416b27438dab79b980ba64b898']}   >Todo Titular de datos personales puede revocar, en cualquier momento, el consentimiento al tratamiento de éstos siempre y cuando no lo impida una disposición legal o contractual. Para ello, VAI deberá establecer mecanismos sencillos y gratuitos que le permitan al titular revocar su consentimiento, al menos por el mismo medio por el que lo otorgó.</p>
<p custom-agua-item="text" className={styles['text-833-613ebc0f7b8b43e897b0cc39d3b87174']}   >Se deberá tener en cuenta que existen dos modalidades en las que la revocación del consentimiento puede darse. En primer lugar, puede ser sobre la totalidad de las finalidades consentidas, esto es, que VAI deba dejar de tratar por completo los datos del titular; la segunda, puede ocurrir sobre tipos de tratamiento determinados, como por ejemplo para fines publicitarios, campañas, cursos entre otros. Con la segunda modalidad, esto es, la revocación parcial del consentimiento, se mantienen a salvo otros fines del tratamiento que el responsable, de conformidad con la autorización otorgada puede llevar a cabo y con los que el titular está de acuerdo.</p>
<p custom-agua-item="text" className={styles['text-834-dfccd6b90e984c2691d952b1044dbc4f']}   >Requisitos para la atención de revocatoria de autorizaciones.</p>
<p custom-agua-item="text" className={styles['text-835-71d8a3fa63a74870963b9bc0b232e9ae']}   >Se debe enviar una comunicación sea física o electrónica, esta debe contener como mínimo la siguiente información:</p>
<p custom-agua-item="text" className={styles['text-836-2275b2da64ec469da71cddcb289be60c']}   >Fecha de solicitud;</p>
<p custom-agua-item="text" className={styles['text-837-3f81c0709ff7471687e430da04157981']}   >Nombre(s) y apellidos completos;</p>
<p custom-agua-item="text" className={styles['text-838-d128f0e824ac4416a64c919360f10d42']}   >Fotocopia de documento de identificación;</p>
<p custom-agua-item="text" className={styles['text-839-47e15db973ce4812acda795e4a3c1475']}   >Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto);</p>
<p custom-agua-item="text" className={styles['text-840-8299f7e8965a453cb9b186b656926164']}   >Medios para recibir respuesta a su solicitud;</p>
<p custom-agua-item="text" className={styles['text-841-8a722d23494d43c6ada618f77cb3c312']}   >Firma (si aplica) y número de identificación;</p>
<p custom-agua-item="text" className={styles['text-842-3786a44887054f0b9c010e1af2e56334']}   >Si es el representante legal, adjuntar documento que acredite la representación, si es el caso.</p>
<p custom-agua-item="text" className={styles['text-843-18020e939cc14230b85af405a504ceb8']}   >Para ejercer este derecho por medio electrónico, el titular, su causahabiente, tercero autorizado o apoderado podrá formular la consulta por medio del correo electrónico destinado a soporte@vai.com.co donde se adjunte la información anteriormente relacionada.</p>
<p custom-agua-item="text" className={styles['text-844-60befa75ab9e47488937afe442322b9c']}   >La persona interesada en ejercer este derecho deberá en todo caso, utilizar un medio que permita acreditar el envío y la recepción de la solicitud. Cualquiera que sea el medio utilizado para ejercer este derecho; VAI atenderá la solicitud siempre y cuando se cumpla con los requisitos señalados anteriormente.</p>
<p custom-agua-item="text" className={styles['text-845-0fcceb433bbb444b8ddef7303e25f612']}   >Términos para la atención de Revocatorias.</p>
<p custom-agua-item="text" className={styles['text-846-12276109223b40b287c6860eb94a2050']}   >El término máximo para atenderlo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender la solicitud dentro de dicho término, VAI informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.vigencia}   >
<p custom-agua-item="text" className={styles['text-155-5af276f7c99142e6ba871a0f08d03480']}   >Vigencia</p>
<p custom-agua-item="text" className={styles['text-156-ec80aef363c443bda174fb1fbf66cdf7']}   >La presente Política para el Tratamiento de Datos Personales fue aprobada y expedida VAI y rige a partir del 29 de mayo de 2023, la cual reemplaza cualquier declaración, manifestación o comunicación anterior con referencia al tratamiento y procedimiento de datos personales que haya hecho VAI.</p>
<p custom-agua-item="text" className={styles['text-157-fad727ceb90041a2b3fdcee1199957fb']}   >Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política. Una vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos.</p>
<p custom-agua-item="text" className={styles['text-158-6c8e19228dd84824a6f4e07be6615309']}   >Actualización de la política: VAI es la responsable de la aprobación de la presente Política, así como, de las modificaciones posteriores. En los casos que esto ocurra se publicará el nuevo documento de políticas en la página web: https://www.vai.com.co/</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.homefooter2}   >
<section custom-agua-item="layer" className={styles['layer-1292-1fa5c747ebda4cf1b46a1d5783f21287']}   >
<section custom-agua-item="layer" className={styles['layer-1302-82417c657e5b43ddba62ea81578d5dd4']}   >
<section custom-agua-item="layer" className={styles['layer-1352-c9ac1ffab054434d831e3d91f8d44377']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-92-d9ac6056b97649d389cd5942f0cbf521']}  alt="imageview-92" src={ image_imageview_92}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1362-f93e5c7db7e94eee839e8a6fee54ca32']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-102-7471f2b6122b4de3b6a13e1d74578671']}  alt="imageview-102" src={ image_imageview_102}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1372-a858a45486534f929020391f9ee7fdd9']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-112-04fa3ef827d745a5b1065e94736d52a8']}  alt="imageview-112" src={ image_imageview_112}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1312-0e104864cb1740519ef745bfe8f17c1d']}   >
<p custom-agua-item="text" className={styles['text-6202-2da555f342e5469396c6dee03c7a556d']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1332-128c3c59b5d04598a632d585edbce89d']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-4342-a7dbc67ddd89433098509a4a34d37284']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6242-8f9882c1a01745249c7a033683ca8b6f']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1322-25fcace24f9b403094f30c2520cce009-orientation']} ${styles['layer-1322-25fcace24f9b403094f30c2520cce009']}`}   >
<section custom-agua-item="layer" className={styles['layer-1382-0938fb247f234e55b28fc3042c21ffe0']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-4352-f4ab820c4ba249e0a0ad6747d1ad2ae0']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6212-eb0634260c354720a42edf593a620f6c']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1392-be2d9fecb6c24a0dac12340b3d853fe6']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-5482-6ec6eff41a1f44f1a13407dd88dce399']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6222-45fa2778526e4c3ea648a461e89c1872']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1402-d219626b542a4131807b7612c0e112dd']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-6232-3ce3cc2f605c424a8bb09a3057f5c492']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1342-36b3d118cf474a13ba7156a5da469c1c-orientation']} ${styles['layer-1342-36b3d118cf474a13ba7156a5da469c1c']}`}   >
<section custom-agua-item="layer" className={styles['layer-1412-e90c2c3e8df94385999e0d7380c8adcb']}   >
<section custom-agua-item="layer" className={styles['layer-1422-f720648a553046cfb0c1ccf58251ea44']}   >
<p custom-agua-item="text" className={styles['text-6252-12fe4a4b9a124362893b1ad98aa2ff5a']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1432-3ac4c904aa2445fb92ca93f346483001']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-6262-48b096a5fb0d4455b47755321771bd91']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1442-1ffe768427a2410cac324c78852f4611']}   >
<p custom-agua-item="text" className={styles['text-6272-96e8d0419e264dcf9f61de27187ade21']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Politicadeprivacidad;
            
import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_4 from '../../../Assets/Home/vai-logo.png';
import image_imageview_622 from '../../../Assets/Home/App_Store_Badge.png';
import image_imageview_522 from '../../../Assets/Home/Play_Store_Badge.png';
import image_imageview_1 from '../../../Assets/Home/Home2.png';
import image_imageview_28 from '../../../Assets/Home/Home4.png';
import image_imageview_62 from '../../../Assets/Home/App_Store_Badge.png';
import image_imageview_52 from '../../../Assets/Home/Play_Store_Badge.png';
import image_imageview_92 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_102 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_112 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './home.module.css';

/**
 * Home component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Home component.
 */
const Home = () =>{
    
    document.title = "Vai";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Home}>
                
<section custom-agua-item="layer" className={styles.homeheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-208-95df532e35a240dfb8ffaa2627259959']}   >
<img custom-agua-item="imageView" className={styles['imageview-4-a6e28c61a71b4eae8f6dff09a0f89b49']}  alt="imageview-4" src={ image_imageview_4}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-209-06fd67a8fdd34d00a3b67a740e195964']}   >
<section custom-agua-item="layer" className={styles['layer-212-8e2112e3d79c4524980ff5bf2cdac21a']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-208-0208adbbda3b413fba46fcffac9880b9']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-1420-55101fe7ab374570867ffd69fb701363']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-213-1d855f1e0a68496b9290575b6fc9bc15']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-209-c8f2490a3c46433f8293308fd7d22272']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-1421-065355fbc7fe461dbdfe5fad501f77a0']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-214-a0f921d0d8f04350ad11c70f5b32ef88']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-283-47361a5a0aab4d5ba06da033de361a42']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-1422-d70da76abdb4470fbeeb9febe4e17066']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-210-3dd92e84d8a64361be4ff043f1dd0013']}   >
<section custom-agua-item="layer" className={styles['layer-211-050d15ecc216425d9fefd0c79c94ff98']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-1424-c63bd390880949eb9a9fc3c12bbf4da5']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-215-3c7df890f20d4fa1ba759a697700ec7b']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-1423-35ed973fbde848c3bfba413f9614e96f']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-216-c8b26a2a46cb464f9f83b1ea1c54cc6e']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-358-b7d62762527449e68c1661b933d21eb1']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-1-25485e57bdd04d43b407cbbe4701750f']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homebody}   >
<section custom-agua-item="layer" className={styles.homesection1}   >
<section custom-agua-item="layer" className={styles['layer-15-1d11b4a5f68147099a51bc759df5ad8d']}   >
<section custom-agua-item="layer" className={styles['layer-218-51a03f64dc544e81b16cdcb7d93815cc']}   >
<section custom-agua-item="layer" className={styles['layer-219-ad56ed2714784a7cafba5fdea7461e42']}   >
<section custom-agua-item="layer" className={styles['layer-221-cce37bcedec14833a270f81794d33a23']}   >
<p custom-agua-item="text" className={styles['text-1425-55bf61de2e9243bcbc394e6f29caf018']}   >El app de carpooling para comunidades.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-220-2a9d792671024ec78fed4d2b69c644c2']}   >
<p custom-agua-item="text" className={styles['text-1426-1d988493a89a4c53a903d367cb70a610']}   >#VamosEnVai</p>
</section>
<section custom-agua-item="layer" className={styles['layer-22222-5734d2b31c5f4c12aea8f1be67e04c01']}   >
<section custom-agua-item="layer" className={styles['layer-22322-8579d0fd96aa4fad86ec368c2b4be66e']}   onClick={() => openInNewTab("https://apps.apple.com/us/app/vai/id1661664609")}>
<img custom-agua-item="imageView" className={styles['imageview-622-6a18beaaa4c84f6ca17d80a196239318']}  alt="imageview-622" src={ image_imageview_622}  />
</section>
<section custom-agua-item="layer" className={styles['layer-22422-a8afc40b9419459b86502fd1d6ff7f13']}   onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=co.com.vai.app")}>
<img custom-agua-item="imageView" className={styles['imageview-522-df075f7cd0bd4edf84c7e375340a3474']}  alt="imageview-522" src={ image_imageview_522}  />
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-16-d3e82ba74035456bac8e4760125148b2']}   ></section>
</section>
<section custom-agua-item="layer" className={`${styles['homesection2-orientation']} ${styles['homesection2']}`}   >
<section custom-agua-item="layer" className={styles['layer-35-c6e7b565542548448f71f07338a8b0d5']}   >
<section custom-agua-item="layer" className={styles['layer-145-5bcd9310afe44bed8a9a138e4a540a52']}   >
<section custom-agua-item="layer" className={styles['layer-146-3f4c6fbc29fd44dea18e27871bc11a1f']}   >
<p custom-agua-item="text" className={styles['text-628-4cd584c3fa024dee95e3967c2ac8a402']}   >¿Qué es Vai?</p>
</section>
<section custom-agua-item="layer" className={styles['layer-147-358edc9871f6444c850121b53450358d']}   >
<p custom-agua-item="text" className={styles['text-629-3b0f38fbd31849fcaa4e1a6be64f193f']}   >Vai, en donde cada recorrido es una oportunidad, es una aplicación móvil de carpooling para comunidades que permite a sus usuarios movilizarse desde y hacia sus destinos de estudio o trabajo.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-148-730164d203bf4b32859be845c2b352bd']}   >
<p custom-agua-item="text" className={styles['text-630-1db4994dc2984ad8a804026a8372c525']}   >Facilitamos la movilidad por medio de una solución tecnológica innovadora, práctica, segura y accesible.</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-149-2613c424f6254dfc93cb574d6d54c2d1-orientation']} ${styles['layer-149-2613c424f6254dfc93cb574d6d54c2d1']}`}   >
<section custom-agua-item="layer" className={styles['layer-150-b079531565d64222b5840e4d02e6594e']}   >
<Link to="/Ventajas"><section custom-agua-item="layer" className={styles['layer-556-e552e712556540c89908f05850a38c63']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-631-470bbeae657e46b29aa0378227743e65']}   >Ventajas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-151-f3611b5299f24754870496c3cc1ef140']}   >
<Link to="/Sostenibilidad"><section custom-agua-item="layer" className={styles['layer-557-5accc0c2ba324fcbb290ba9d357da95b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-632-7117d748bd6a4a92b5694879e3d8db54']}   >Sostenibilidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-152-76a3f316360d4ed6b4d0c5304b25c3f1']}   >
<Link to="/Ayuda"><section custom-agua-item="layer" className={styles['layer-558-1d80f80a091d465ca105fae3eb9db869']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-633-856e2ae2a14e47fd95a99d8b8b3f1434']}   >Ayuda</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-36-ec2cda592c43417e9d552d1e25583e31']}   >
<img custom-agua-item="imageView" className={styles['imageview-1-6b3bc8b933c2433a91da74537fb3a94c']}  alt="imageview-1" src={ image_imageview_1}  />
</section>
</section>
<section custom-agua-item="layer" className={styles.homesection3}   >
<section custom-agua-item="layer" className={styles['layer-37-dd24710040094cf3a451b93c4b539b46']}   ></section>
<section custom-agua-item="layer" className={styles['layer-38-7b72faaacce547d8a201de779ddb332d']}   >
<section custom-agua-item="layer" className={styles['layer-1452-04358feccfa2468f9adfad05f4e14014']}   >
<section custom-agua-item="layer" className={styles['layer-1462-38a62e445cb34ff6a7375c890a999048']}   >
<p custom-agua-item="text" className={styles['text-6282-12256c96886e41edb71a60b96c8cd0e9']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1472-aebe075a472f428394c4edc2f8528c85']}   >
<p custom-agua-item="text" className={styles['text-6292-829852072e674eb9bc9ad3c4c51f13b2']}   >Somos lo que tu empresa necesita para gestionar su movilidad de forma cómoda y segura.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1482-e7cec7574c2a4807b558376d911023db']}   >
<p custom-agua-item="text" className={styles['text-6302-5857ffdffd4b4b18a47fc61121b5add8']}   >Nuestra plataforma te brinda la capacidad de administrar y optimizar la movilidad de tus empleados de manera eficiente. Con Vai, podrás hacer seguimiento en tiempo real de los trayectos, definir horarios y precios.</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1492-1ea1a55ef24e4f689d5f2d192334963e-orientation']} ${styles['layer-1492-1ea1a55ef24e4f689d5f2d192334963e']}`}   >
<section custom-agua-item="layer" className={styles.layer1502}   >
<section custom-agua-item="layer" className={styles['layer-559-a23fde1b320149798bbf3c8ca986148f']}   onClick={() => openInNewTab("https://wa.me/573128339854")}></section>
<p custom-agua-item="text" className={styles['text-6312-bacc62e36a0e4bd080d3ef9f1d0ee4d8']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1512-89c6a82ee8b9427dadd872473b6365d0']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-560-14e4c5910c4946ba9e4ce55870910cb6']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6322-600bd966aca840a4b3baaac0f10419fc']}   >Beneficios</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homesection4}   >
<section custom-agua-item="layer" className={styles['layer-217-5ce07fc6e4824fd4a19b0c92c2849814']}   >
<section custom-agua-item="layer" className={styles['layer-217-5f8d11f80616423883b8df04eed1de8d']}   >
<section custom-agua-item="layer" className={styles['layer-218-f6bf77fae70b4b8d9953eda6cf14f88c']}   >
<p custom-agua-item="text" className={styles['text-690-aad55985c7c04538a216f0e472c43521']}   >Con Vai transformamos la movilidad a precios competitivos
con el transporte público mientras garantizamos la seguridad de nuestros usuarios.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-219-ec67d12af4df41ad904d32babe09bffa']}   >
<img custom-agua-item="imageView" className={styles['imageview-28-b2d817eaa505456d85dbda2e44b5fb15']}  alt="imageview-28" src={ image_imageview_28}  />
</section>
<section custom-agua-item="layer" className={styles['layer-220-09a03e6a60c74706a4e8be99adc7c7ee']}   >
<p custom-agua-item="text" className={styles['text-688-04eb9a2563aa43a39a766c1523876a75']}   >¡Ya está todo listo para comenzar tu viaje!</p>
</section>
<section custom-agua-item="layer" className={styles['layer-221-703c062448b747eb8160c92d2a6cf1b8']}   >
<p custom-agua-item="text" className={styles['text-687-d26e399e8a6746618b441d8126f43d14']}   >¿A dónde vamos hoy?</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2222-cf765cf2687846979b0589a74bd60004']}   >
<section custom-agua-item="layer" className={styles['layer-2232-3285552ab67b4baf9cff2e2e56c068f2']}   onClick={() => openInNewTab("https://apps.apple.com/us/app/vai/id1661664609")}>
<img custom-agua-item="imageView" className={styles['imageview-62-4ad9610cd0ce464fad9ca8b477441885']}  alt="imageview-62" src={ image_imageview_62}  />
</section>
<section custom-agua-item="layer" className={styles['layer-2242-b90836475d9f4f90a727892f0509ebf7']}   onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=co.com.vai.app")}>
<img custom-agua-item="imageView" className={styles['imageview-52-06425936979c491d8b6aab907c5fc176']}  alt="imageview-52" src={ image_imageview_52}  />
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-39-dc18d0557db64831895f1d3a475f844f']}   ></section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homefooter}   >
<section custom-agua-item="layer" className={styles['layer-1292-7b0325d8f2be412f908bb67e8c2738c8']}   >
<section custom-agua-item="layer" className={styles['layer-1302-70f32d10ffaf45e3af7348acb98500b4']}   >
<section custom-agua-item="layer" className={styles['layer-1352-b17d9cf8849a4acda6faa4313bed13b8']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-92-110948eaee8a4a9eb42df70f20ed68b6']}  alt="imageview-92" src={ image_imageview_92}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1362-8dfb44f11ac442b6a5a2615a435afac0']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-102-b7f73a73bb9144ba89206129420fd617']}  alt="imageview-102" src={ image_imageview_102}  />
</section>
<section custom-agua-item="layer" className={styles['layer-1372-e91653c61f2048cea8b6b5462e4743c3']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-112-ca6151810e484c7eb9f7a0da8c20b62c']}  alt="imageview-112" src={ image_imageview_112}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1312-787d93288fc14f3e9c3769689a6c2445']}   >
<p custom-agua-item="text" className={styles['text-6202-3709f03a10f14951a121a2f7fd390d75']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1332-9065d12b21294462af7066b204bad4f0']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-4342-494a1fdd33094acc8ca1efb60e4b0894']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6242-14633acadd4543d19a8b0a41b5ce8936']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1322-9ca5d88d894b4ec2b17f464f27e13a5c-orientation']} ${styles['layer-1322-9ca5d88d894b4ec2b17f464f27e13a5c']}`}   >
<section custom-agua-item="layer" className={styles['layer-1382-e0250d6cc11d4867b9a005b4fde5cad9']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-4352-38acc72e30ab4f12965ce11cbf8b761d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6212-27bc5db6ca734385af7ebdbcccab00ba']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1392-18b31f6ec66c4f1aab442fb233138f55']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-5482-e1f5a8396f494522ae5367af459ecf98']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-6222-19bbb3ea589a44c5a96a8fe68f7ee4af']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1402-da61af120a7f4cc8a6a4bdaf58ecd342']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-6232-42722ec0440748b99764bab695ab6064']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-1342-8e7fabffe1914bafaeec600ee35ca90e-orientation']} ${styles['layer-1342-8e7fabffe1914bafaeec600ee35ca90e']}`}   >
<section custom-agua-item="layer" className={styles['layer-1412-00a8e0cd197f442db9a8cb283779c27c']}   >
<section custom-agua-item="layer" className={styles['layer-1422-6a6406232ac64b30b39b0f939ce5d2b4']}   >
<p custom-agua-item="text" className={styles['text-6252-b28fa17b9f44431ab9b60d90cbe65135']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-1432-3ddcc57239d6425182b95d55a8591ab2']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-6262-344b6b80239647a59f3a1d126fbf4d29']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1442-4924a4ac263a4b93b162d695be53d1d6']}   >
<p custom-agua-item="text" className={styles['text-6272-007c77479d4448fda92e53165cecb783']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Home;
            
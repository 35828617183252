import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_42 from '../../../Assets/Home/vai-logo.png';
import image_imageview_922 from '../../../Assets/Social Media/facebook-Black.png';
import image_imageview_1022 from '../../../Assets/Social Media/instagram-Black.png';
import image_imageview_1122 from '../../../Assets/Social Media/linkedin-Black.png';
import styles from './medios.module.css';

/**
 * Medios component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Medios component.
 */
const Medios = () =>{
    
    document.title = "Vai | Medios";

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Medios}>
                
<section custom-agua-item="layer" className={styles.mediosheader}   >
<Link to="/Home"><section custom-agua-item="layer" className={styles['layer-2082-75d1e6eed7ae4a1ea2526f109faa69b1']}   >
<img custom-agua-item="imageView" className={styles['imageview-42-af8c38822b2544a19404db87365491a3']}  alt="imageview-42" src={ image_imageview_42}  />
</section></Link>
<section custom-agua-item="layer" className={styles['layer-2092-8b800025af1e441daea8f0fcac51726d']}   >
<section custom-agua-item="layer" className={styles['layer-2122-3e972c74bc0543318eaa38d804cddeab']}   >
<Link to="/Carpooling"><section custom-agua-item="layer" className={styles['layer-2082-9216090c7b4446419b17ab1ebcb296e4']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14202-11c226bb66bb405682557f61f0ebb50f']}   >Carpooling</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2132-bf4a8bed8361491c88bb8d3c106a89cc']}   >
<Link to="/Empresas"><section custom-agua-item="layer" className={styles['layer-2092-79ebc40b5289495488b4c750e563086e']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14212-6efb2a197fcc450ca2fcc9166fa87854']}   >Empresas</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2142-9ee22cd597cc4a6fa908cb68c32414cf']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-2832-f13d04064d2340d6bbbb0896feff234d']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-14222-da4db9c7ec4040dca341f6c01f07935a']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-2102-c5d3eb603bb04f0f9a0b14a67eafd54e']}   >
<section custom-agua-item="layer" className={styles['layer-2112-2ac67385140c45f2bf1d68f450c87a6a']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-14242-37909955a5394432a4c1c12dcf4292f8']}   >Contáctanos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2152-7c60dd6828834069b6ec387d0048a8f3']}   onClick={() => openInNewTab("https://linktr.ee/vai.community")}>
<p custom-agua-item="text" className={styles['text-14232-6032f63df51a45bebf60acb92852e16f']}   >Descargar</p>
</section>
<section custom-agua-item="layer" className={styles['layer-2162-43cf4b81c92b4cb98afba8553cbe8fa8']}   >
<Link to="/Mobilemenu"><section custom-agua-item="layer" className={styles['layer-3582-7c149b5567f6467aae8bd55e5e1cd573']}   ></section></Link>
<span custom-agua-item="icon" className={styles['icon-12-db8b495da74b48ef8d1b81963ff2de5d']}  >menu</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mediosbody}   >
<section custom-agua-item="layer" className={styles.bodysection1}   >
<section custom-agua-item="layer" className={styles['layer-4051-bc23c1c08ea243bd83765988965628d6']}   >
<p custom-agua-item="text" className={styles['text-3713-6560120c43ff4025ae3bb945a7893419']}   >Esto es lo que dicen de nosotros...</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.bodysection2}   >
<section custom-agua-item="layer" className={`${styles['layer-4310-a7ce1ba6b0dd4a01a35216e2d9c38acc-orientation']} ${styles['layer-4310-a7ce1ba6b0dd4a01a35216e2d9c38acc']}`}   >
<section custom-agua-item="layer" className={styles['layer-431022-f9c9bf368eb5426aa0779c263214d264']}   onClick={() => openInNewTab("https://www.valoraanalitik.com/2022/12/06/transporte-para-estudiantes-universitarios-en-colombia-sera-mas-facil-con-esta-opcion/")}>
<section custom-agua-item="layer" className={styles['layer-431122-65d13cd484c94c9282bcb5e3ff54eea1']}   ></section>
<p custom-agua-item="text" className={styles['text-381422-e06d1fe64d364129b02810dbe2bffae1']}   >Valor AnalitiK</p>
<p custom-agua-item="text" className={styles['text-381522-d4649f846aae4a46a9fee98cf753a7e0']}   >Estudiantes universitarios en Colombia contarán con nueva opción de movilidad.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-43102-6a8a0854c72c4fdab2cccd71ec00d16d']}   onClick={() => openInNewTab("https://www.bloomberglinea.com/2023/02/07/el-uber-para-universitarios-colombianos-cierra-inversion-y-ya-vale-us32-millones/?utm_source=twitter&utm_medium=organic&utm_campaign=post&utm_id=CTA")}>
<section custom-agua-item="layer" className={styles['layer-43112-163db1633dc04bc594bde5f2dc78f422']}   ></section>
<p custom-agua-item="text" className={styles['text-38142-d20a972997cf44608f66635d44514cb2']}   >Bloomberg</p>
<p custom-agua-item="text" className={styles['text-38152-74f5808810314c1d893f8f5713a50c73']}   >El “Uber” para universitarios colombianos cierra inversión y ya vale US$3,2 millones.</p>
</section>
<section custom-agua-item="layer" className={styles['layer-431023-43a5fe93c6b740dc88f15079d743a5d6']}   onClick={() => openInNewTab("https://forbes.co/2023/02/07/emprendedores/esta-aplicacion-de-carros-compartidos-para-universitarios-inicia-con-us530-00")}>
<section custom-agua-item="layer" className={styles['layer-431123-b4eaf1bfdc3e4c1ea3864e53476c40ae']}   ></section>
<p custom-agua-item="text" className={styles['text-381423-6e7b8e1fc23442e69cc230bbd276e026']}   >Forbes</p>
<p custom-agua-item="text" className={styles['text-381523-4ec5c9d9d34d47dbaed17ee83455ecc8']}   >Esta aplicación de carros compartidos para universitarios inicia con US$530.000.</p>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mediosfooter}   >
<section custom-agua-item="layer" className={styles['layer-12922-c90af49cf39f4ae7a307c07376fb6013']}   >
<section custom-agua-item="layer" className={styles['layer-13022-00df1be129e24dc990cbb5629e9e76e4']}   >
<section custom-agua-item="layer" className={styles['layer-13522-7c089d374cf74afbb68c6636813c770b']}   onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100081354344733")}>
<img custom-agua-item="imageView" className={styles['imageview-922-2918c688deb54e3fb7acfbb926739b82']}  alt="imageview-922" src={ image_imageview_922}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13622-5ab3a807706848a3906d3bd65951a0bc']}   onClick={() => openInNewTab("https://www.instagram.com/vaicommunity/")}>
<img custom-agua-item="imageView" className={styles['imageview-1022-10d7ae4e7c5c433aaab0aacde563a9d7']}  alt="imageview-1022" src={ image_imageview_1022}  />
</section>
<section custom-agua-item="layer" className={styles['layer-13722-416a715e0fd940c7a89339eca99947d2']}   onClick={() => openInNewTab("https://www.linkedin.com/company/vai-community/%20")}>
<img custom-agua-item="imageView" className={styles['imageview-1122-cd92d00bdfb5491a91038c36b4782fdf']}  alt="imageview-1122" src={ image_imageview_1122}  />
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-13122-9cc04e038f4d4624bbce5756364a1d78']}   >
<p custom-agua-item="text" className={styles['text-62022-baffe8ee237349a1b0470db4e8cb1a2b']}   >Vai App © Copyright 2023. All Rights Reserved</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13322-009bad3bfba740898501a2b6d0c2b1dc']}   >
<Link to="/Nosotros"><section custom-agua-item="layer" className={styles['layer-43422-636e8490dfcd409db3029aa54d5f9542']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62422-abc7b38dfcb74646a028d77b812616bb']}   >Nosotros</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13222-01c0de8094b94005bafe2dc4129b6c7f-orientation']} ${styles['layer-13222-01c0de8094b94005bafe2dc4129b6c7f']}`}   >
<section custom-agua-item="layer" className={styles['layer-13822-442a6363a4054056ad8342d0dae31f71']}   >
<Link to="/Terminosycondiciones"><section custom-agua-item="layer" className={styles['layer-43522-bd1442a9b3de4fbc877ba2159202a41b']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62122-438334645a8b425a95301b93faa72958']}   >Términos y condiciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13922-0918c5bf24114a0592b4bdd0e3ad1e59']}   >
<Link to="/Politicadeprivacidad"><section custom-agua-item="layer" className={styles['layer-54822-e2a812d5a5f540adb728270814c10866']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-62222-33c9a20b1a464e6da82052244311a4a9']}   >Política de privacidad</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14022-c4c56c835f1946c3a915af50fe348c32']}   onClick={() => openInNewTab("https://www.sic.gov.co/")}>
<p custom-agua-item="text" className={styles['text-62322-b023aa1093da4c7b84c1b625ea5f41c2']}   >Superintendencia de Industria y Comercio</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['layer-13422-9c3871cb88554391aea3402f6ccce1ad-orientation']} ${styles['layer-13422-9c3871cb88554391aea3402f6ccce1ad']}`}   >
<section custom-agua-item="layer" className={styles['layer-14122-42df44664e1944d28431a5d624b7f609']}   >
<section custom-agua-item="layer" className={styles['layer-14222-51042d2f2b5946549ee64157563ec053']}   >
<p custom-agua-item="text" className={styles['text-62522-d00c811ef7234981bbea1ecc3ba02f83']}   >Teléfono:</p>
</section>
<section custom-agua-item="layer" className={styles['layer-14322-c881579878ab49b4b06eeb1d24be82cb']}   onClick={() => openInNewTab("https://wa.me/573128339854")}>
<p custom-agua-item="text" className={styles['text-62622-876adb38da3044ce87400e87d9a141f2']}   >+57 312 8339854</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-14422-d2fdd9e4137b41ec97506db9c2d79be7']}   >
<p custom-agua-item="text" className={styles['text-62722-608242da61554a68ba5078469f31d21f']}   >VAI COLOMBIA S.A.S. – NIT: 901586077-1</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Medios;
            